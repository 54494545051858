import React from 'react';
import { Link } from 'react-router-dom';

function PasswordResetConfirm() {
    return (
        <div className="form-content my-3 p-3">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        {/* {% if validlink %} */}
                        <div className="card shadow-lg border-0 rounded-lg mt-0 mb-3">
                            <div className="card-header justify-content-center">
                                <h3 className="font-weight-light my-4 text-center">Reset Your Password</h3>
                            </div>
                            {/* {% if form.errors %} */}
                            <div className="alert alert-danger alert-dismissible" role="alert">
                                <div id="form_errors">
                                    {/* {% for key, value in form.errors.items %} */}
                                    {/* <strong>{{ value }}</strong> */}
                                    {/* {% endfor %} */}
                                </div>
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {/* {% endif %} */}

                            <div className="card-body">
                                <form method="POST">
                                    {/* {% csrf_token %} */}
                                    <div className="form-row">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="form-group">
                                                <label className="small mb-1" htmlFor="id_new_password1">Nuova Password</label>
                                                <input type="password" name="new_password1"
                                                    className="form-control"
                                                    placeholder="Enter password" />
                                                <span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="form-group">
                                                <label className="small mb-1" htmlFor="id_new_password2">Conferma Password</label>
                                                <input type="password" name="new_password2"  className="form-control"
                                                    placeholder="Confirm password" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="form-group mt-0 mb-1">
                                                <button type="submit" className="col-md-12 btn btn-dark" id="reset">Cambia Password</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* {% else %} */}
                        <div className="alert alert-warning">
                            Il link per il cambio password non è valido, probabilmente poiché è già stato usato.
                            Per favore richiedi un nuovo cambio password.
                        </div>
                        {/* {% endif %} */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordResetConfirm