// import React, { useState } from 'react'

// function PermissionAdvertisment(props) {
//     const {
//         item
//     } = props;
//     const [checkedDraft, setCheckedDraft] = useState({});
//     const [checkedPublished, setCheckedPublished] = useState({});
//     const [checkedSuspended, setCheckedSuspended] = useState({});
//     const [checkedClosed, setCheckedClosed] = useState({});


//     const handleBozzaChange = (itemId) => {
//         setCheckedDraft((prevCheckedItems) => ({
//             ...prevCheckedItems,
//             [itemId]: !prevCheckedItems[itemId],
//         }));
//     };

//     const handlePubblicatoChange = (itemId) => {
//         setCheckedPublished((prevCheckedItems) => ({
//             ...prevCheckedItems,
//             [itemId]: !prevCheckedItems[itemId],
//         }));
//     }
//     const handleSospesoChange = (itemId) => {
//         setCheckedSuspended((prevCheckedItems) => ({
//             ...prevCheckedItems,
//             [itemId]: !prevCheckedItems[itemId],
//         }));
//     }

//     const handleChiusoChange = (itemId) => {
//         setCheckedClosed((prevCheckedItems) => ({
//             ...prevCheckedItems,
//             [itemId]: !prevCheckedItems[itemId],
//         }));
//     }


//     return (
//         <>
//             <td>
//                 <div className="advertisement_status">
//                     <form className="advertisement_status_form">

//                         {item.draft_id !== null ? (
//                             <div className="form-group">
//                                 <p>Bozza</p>
//                                 <input
//                                     type="checkbox"
//                                     id={item.draft_id !== null ? `${item.id}_draft` : `draft_${item.id}`}
//                                     checked={!!checkedDraft[item.id]}
//                                     onChange={() => handleBozzaChange(item.id)}
//                                 />
//                                 <label
//                                     htmlFor={item.draft_id !== null ? `${item.id}_draft` : `draft_${item.id}`}
//                                 ></label>
//                             </div>
//                         ) : (
//                             <div className="form-group">
//                                 <p>Bozza</p>
//                                 <input
//                                     type="checkbox"
//                                     id={item.draft_id !== null ? `${item.id}_draft` : `draft_${item.id}`}
//                                     checked={!!checkedDraft[item.id]}
//                                     onChange={() => handleBozzaChange(item.id)}
//                                 />
//                                 <label
//                                     htmlFor={item.draft_id !== null ? `${item.id}_draft` : `draft_${item.id}`}
//                                 ></label>
//                             </div>
//                         )}

//                         {item.published === true ? (
//                             <div className="form-group">
//                                 <p>Pubblicato</p>
//                                 <input
//                                     type="checkbox"
//                                     id={item.published === true ? `${item.id}_published` : `published_${item.id}`}
//                                     checked={!!checkedPublished[item.id]}
//                                     onChange={() => handlePubblicatoChange(item.id)}
//                                 />
//                                 <label
//                                     htmlFor={item.published === true ? `${item.id}_published` : `published_${item.id}`}
//                                 ></label>
//                             </div>
//                         ) : (
//                             <div className="form-group">
//                                 <p>Pubblicato</p>
//                                 <input
//                                     type="checkbox"
//                                     id={item.published === true ? `${item.id}_published` : `published_${item.id}`}
//                                     checked={!!checkedPublished[item.id]}
//                                     onChange={() => handlePubblicatoChange(item.id)}
//                                 />
//                                 <label
//                                     htmlFor={item.published === true ? `${item.id}_published` : `published_${item.id}`}
//                                 ></label>
//                             </div>
//                         )}

//                         {item.suspended === true && item.published === false ? (
//                             <div className="form-group">
//                                 <p>Sospeso</p>
//                                 <div className="form-group">
//                                     <input
//                                         type="checkbox"
//                                         id={item.published === true ? `${item.id}_suspended` : `suspended_${item.id}`}
//                                         checked={!!checkedSuspended[item.id]}
//                                         onChange={() => handleSospesoChange(item.id)}
//                                     />
//                                     <label
//                                         htmlFor={item.suspended === true && item.published === false ? `${item.id}_suspended` : `suspended_${item.id}`}
//                                     ></label>
//                                 </div>
//                             </div>
//                         ) : (
//                             <div className="form-group">
//                                 <p>Sospeso</p>
//                                 <div className="form-group">
//                                     <input
//                                         type="checkbox"
//                                         id={item.published === true ? `${item.id}_suspended` : `suspended_${item.id}`}
//                                         checked={!!checkedSuspended[item.id]}
//                                         onChange={() => handleSospesoChange(item.id)}
//                                     />
//                                     <label
//                                         htmlFor={item.suspended === true && item.published === false ? `${item.id}_suspended` : `suspended_${item.id}`}
//                                     ></label>
//                                 </div>
//                             </div>
//                         )}
//                         {item.closed === true && item.published === false ? (
//                             <div className="form-group">
//                                 <p>Chiuso</p>

//                                 <input
//                                     type="checkbox"
//                                     id={item.closed === true && item.published === false ? `${item.id}_closed` : `closed_${item.id}`}
//                                     checked={!!checkedClosed[item.id]}
//                                     onChange={() => handleChiusoChange(item.id)}
//                                 />
//                                 <label
//                                     htmlFor={item.closed === true && item.published === false ? `${item.id}_closed` : `closed_${item.id}`}
//                                 ></label>
//                             </div>
//                         ) : (
//                             <div className="form-group">
//                                 <p>Chiuso</p>

//                                 <input
//                                     type="checkbox"
//                                     id={item.closed === true && item.published === false ? `${item.id}_closed` : `closed_${item.id}`}
//                                     checked={!!checkedClosed[item.id]}
//                                     onChange={() => handleChiusoChange(item.id)}
//                                 />
//                                 <label
//                                     htmlFor={item.closed === true && item.published === false ? `${item.id}_closed` : `closed_${item.id}`}
//                                 ></label>
//                             </div>
//                         )}
//                     </form>
//                 </div>
//             </td></>
//     )
// }

// export default PermissionAdvertisment



import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PermissionAdvertisment(props) {
    const {
        item,
    } = props;
    const navigate = useNavigate();
    const [checkedDraft, setCheckedDraft] = useState({});
    const [checkedPublished, setCheckedPublished] = useState({});
    const [checkedSuspended, setCheckedSuspended] = useState({});
    const [checkedClosed, setCheckedClosed] = useState({});

    const handleDraftChange = (itemId) => {
        setCheckedDraft((prevCheckedItems) => ({
            ...prevCheckedItems,
            [itemId]: !prevCheckedItems[itemId],
        }));
    };

    const handlePublishedChange = (itemId) => {
        if (!item.have_been_sponsored) {
            console.log("Open CreaAnnuncio7");
        } else {
            navigate("/company/CreaAnnuncio7_bis")
        }
        setCheckedPublished((prevCheckedItems) => ({
            ...prevCheckedItems,
            [itemId]: !prevCheckedItems[itemId],
        }));
    };

    const handleSuspendedChange = (itemId) => {
        setCheckedSuspended((prevCheckedItems) => ({
            ...prevCheckedItems,
            [itemId]: !prevCheckedItems[itemId],
        }));
    };


    const isDraftChecked = item.draft_id && item.draft_id !== null ? false : true;

    // const isPublishedChecked = (item.published === null || item.published === false || item.have_been_sponsored === null || item.have_been_sponsored === false ) ? false : true;
    const isPublishedChecked = (item.published || item.have_been_sponsored) ? true : false;

    const isSuspendedChecked = item.suspended === true && item.published === false ? false : true;

    const isClosedChecked = item.closed === true && item.published === false ? false : true;

    const handleClosedChange = (itemId) => {
        setCheckedClosed((prevCheckedItems) => ({
            ...prevCheckedItems,
            [itemId]: !prevCheckedItems[itemId],
        }));

        if (isClosedChecked) {
            navigate("/company/advertisement-details/" + itemId);
        }
    };

    return (
        <td>
            <div className="advertisement_status">
                <form className="advertisement_status_form">
                    <div className="form-group">
                        <p>Bozza</p>
                        <input
                            type="checkbox"
                            id={`${item.id}_draft`}
                            checked={!!checkedDraft[item.id] || isDraftChecked}
                            onChange={() => handleDraftChange(item.id)}
                        />
                        <label htmlFor={`${item.id}_draft`}></label>
                    </div>

                    <div className="form-group">
                        <p>Pubblicato</p>

                        <input
                            type="checkbox"
                            id={`${item.id}_published`}
                            checked={!!checkedPublished[item.id] || isPublishedChecked}
                            onChange={() => handlePublishedChange(item.id)}
                        />
                        <label htmlFor={`${item.id}_published`}></label>
                    </div>

                    <div className="form-group">
                        <p>Sospeso</p>
                        <input
                            type="checkbox"
                            id={`${item.id}_suspended`}
                            checked={!!checkedSuspended[item.id] || isSuspendedChecked}
                            onChange={() => handleSuspendedChange(item.id)}
                        />
                        <label htmlFor={`${item.id}_suspended`}></label>
                    </div>

                    <div className="form-group">
                        <p>Chiuso</p>
                        <input
                            type="checkbox"
                            id={`${item.id}_closed`}
                            checked={!!checkedClosed[item.id] || isClosedChecked}
                            onChange={() => handleClosedChange(item.id)}
                        // disabled
                        />
                        <label htmlFor={`${item.id}_closed`}></label>
                    </div>
                </form>
            </div>
        </td>
    );
}

export default PermissionAdvertisment;