// import React, { useState, useEffect } from 'react';
// import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
// import Header from '../../Components/Header';
// import Footer from '../../Components/Footer';
// import { useNavigate, useLocation } from "react-router-dom";

// const PayPalPayment = (props) => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [product, setProduct] = useState({})
//     const [paidFor, setPaidFor] = useState(false);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const data = location?.state?.item;
//         if (!data) {
//             navigate('/company/PackageToken');
//         } else {
//             setProduct(data);
//         }
//     }, [location, navigate]);

//     const handleApprove = (orderID) => {
//         console.log(orderID, "orderID")
//         setPaidFor(true);
//         navigate('/company/Creditmanagement');
//     }

//     if (paidFor) {
//         alert("Thank You for purchasing")
//     }

//     if (error) {
//         alert(error);
//         navigate('/company/PackageToken')
//     }

//     console.log(product, "location");

//     return (
//         <>
//             <Header />
//             <div className='paypal'>
//                 <div className="paypal-button-container">
//                     <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
//                         <PayPalButtons
//                             onClick={(data, actions) => {
//                                 const hasAlreadyBoughtCourse = false;
//                                 if (hasAlreadyBoughtCourse) {
//                                     setError("You Already bough this course");
//                                     return actions.reject();
//                                 } else {
//                                     return actions.resolve();
//                                 }
//                             }}
//                             createOrder={(data, actions) => {
//                                 return actions.order.create({
//                                     purchase_units: [{
//                                         description: product.package_name,
//                                         amount: {
//                                             currency: product.price_currency,
//                                             value: product?.price
//                                         }
//                                     }]
//                                 });
//                             }}
//                             onApprove={async (data, action) => {
//                                 const order = await action.order.capture();
//                                 console.log("order", order);
//                                 handleApprove(data.orderID);
//                                 // return actions.order.capture().then(function (details) {
//                                 //     alert('Transaction completed by ' + details.payer.name.given_name);
//                                 // });
//                             }}

//                             onCancel={() => { }}
//                             onError={(err) => {
//                                 setError(err);
//                                 console.log("PayPal Checkout onError", err);
//                             }}
//                         />
//                     </PayPalScriptProvider>
//                 </div>
//             </div>
//             <Footer />
//         </>
//     );
// };

// export default PayPalPayment;


import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    requestPurchageTokenPayment,
    userLogout,
} from "../../Redux/actions";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const PayPalPayment = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [product, setProduct] = useState({});
    const [paidFor, setPaidFor] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const data = location?.state?.item;
        if (!data) {
            navigate('/company/PackageToken');
        } else {
            setProduct(data);
        }
    }, [location, navigate]);

    const handleApprove = (orderID) => {
        const token = localStorage.getItem("token");
        console.log(orderID, "orderID");
        setPaidFor(true);

        const requestData = {
            bought: product.token_amount,
            in: product.package_name,
        }
        props.requestPurchageTokenPayment({
            data: requestData,
            token: token,
        });

        toast.success("Thank You for purchasing the product!", { theme: "colored", autoClose: 1000 });
        localStorage.removeItem('__paypal_storage__');
        navigate('/company/Creditmanagement');
    }

    const handleError = (err) => {
        setError(err);
        console.log("PayPal Checkout onError", err);
        toast.error(err, { theme: "colored", autoClose: 1000 });

        localStorage.removeItem('__paypal_storage__');
        navigate('/company/PackageToken');
    }

    const handleCancel = () => {
        localStorage.removeItem('__paypal_storage__');
        toast.info("Payment process was canceled.", { theme: "colored", autoClose: 1000 });
        navigate('/company/PackageToken');
    }

    // if (paidFor) {
    //     alert("Thank You for purchasing");
    // }

    useEffect(() => {
        if (paidFor) {
            toast.success("Thank You for purchasing the product!", { theme: "colored", autoClose: 2000 });
        }
    }, [paidFor]);

    if (error) {
        alert(error);
    }

    return (
        <>
            <Header />
            <div className='paypal'>
                <div className="paypal-button-container">
                    <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
                        <PayPalButtons
                            onClick={(data, actions) => {
                                const hasAlreadyBoughtCourse = false;
                                if (hasAlreadyBoughtCourse) {
                                    setError("You Already bought this course");
                                    return actions.reject();
                                } else {
                                    return actions.resolve();
                                }
                            }}
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [{
                                        description: product.package_name,
                                        amount: {
                                            currency: product.price_currency,
                                            value: product?.price
                                        }
                                    }]
                                });
                            }}
                            onApprove={async (data, action) => {
                                const order = await action.order.capture();
                                console.log("order", order);
                                handleApprove(data.orderID);
                            }}
                            onCancel={handleCancel}
                            onError={handleError}
                        />
                    </PayPalScriptProvider>
                </div>
            </div>
            <Footer />
        </>
    );
};

// export default PayPalPayment;

const mapStateToProps = (state) => {
    return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        requestPurchageTokenPayment,
        userLogout,
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayPalPayment);
