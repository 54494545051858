import React from "react";

//================ Basic Register, Candidate Register, Profile ===================// 

function TermsAndService({
  privacyAccepted,
  privacyContentAccepted,
  handleCheckboxChange1,
  handleCheckboxChange2,
  validatePrivacy,
  validatePrivacyContent,
  errorprivacyAccepted,
  errorPrivacyContentAccepted,
  eula_consentRef,
  privacy_consentRef,
  mystyle,
  t,
}) {
  return (
    <div className="selection_checkboxs row">
      <div className="selection_checkboxs_col col-md-6 col-12">
        <div className="selection_checkbox">
          <input
            type="checkbox"
            id="eula_consent"
            name="eula_consent"
            checked={privacyAccepted}
            onChange={handleCheckboxChange1}
            ref={eula_consentRef}
            onBlur={validatePrivacy}
          />
          <label>
            <span>{t("register.basic.check_4")}&nbsp;
              <a href="https://www.iubenda.com/privacy-policy/51986058" target="_blank" rel="noopener noreferrer">
                termini di servizio
              </a>
            </span>

            {errorprivacyAccepted && (
              <div className="checkbox_error" style={mystyle}>
                {errorprivacyAccepted}
              </div>
            )}
          </label>
        </div>
        <div className="selection_checkbox">
          <input
            type="checkbox"
            id="privacy_consent"
            name="privacy_consent"
            checked={privacyContentAccepted}
            onChange={handleCheckboxChange2}
            ref={privacy_consentRef}
            onBlur={validatePrivacyContent}
          />
          <label>
            <span>{t("register.basic.check_5")}
              <a href="https://www.iubenda.com/privacy-policy/51986058/cookie-policy" target="_blank" rel="noopener noreferrer">
                informativa privacy
              </a>
            </span>
            {errorPrivacyContentAccepted && (
              <div className="checkbox_error" style={mystyle}>
                {errorPrivacyContentAccepted}
              </div>
            )}
          </label>
        </div>
      </div>
    </div>
  );
}

export default TermsAndService;