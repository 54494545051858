import React, { useState, useEffect } from 'react';
import PdfIcon from "../../../images/file-pdf.png";
import DownloadIcon from "../../../images/download.png";
import TrashIcon from "../../../images/trash_icon.png";

function ResumeFileToolbar(props) {
    const {
        handleChangeResume,
        validateResume_upload,
        errorResume_upload,
        resumeUploadRef,
        pdfIconRef,
        resume,
        onDeleteResume
    } = props;

    const [fileName, setFileName] = useState('');
    const isDownloadDisabled = !(typeof resume === 'string') || !fileName;
    const isDeleteDisabled = !(typeof resume === 'string') || !fileName;

    const mystyle_resume_file = {
        color: "#D10000",
        padding: "5px 0px",
        width: "100%",
    }

    useEffect(() => {
        if (resume) {
            if (resume instanceof File) {
                setFileName(resume.name);
            } else if (typeof resume === 'string') {
                const extractedFileName = resume.split('/').pop();
                setFileName(decodeURIComponent(extractedFileName));
            }
        } else {
            setFileName('');
        }
    }, [resume]);

    const handlePatchResume = (e) => {
        e.preventDefault();

        if (fileName) {
            const confirmUploadFile = window.confirm(
                "Sei sicuro di voler caricare un nuovo CV? Il processo sovrascriverà il file già esistente nel tuo profilo e non sarà possibile recuperarlo se prima non l'hai scaricato. Puoi scaricarlo con il pulsante della nuvola arancione a destra."
            );

            if (confirmUploadFile) {
                if (resumeUploadRef.current) {
                    resumeUploadRef.current.click();
                }
            }
        } else {
            if (resumeUploadRef.current) {
                resumeUploadRef.current.click();
            }
        }
    };

    const handleFileChange = (e) => {
        if (validateResume_upload()) {
            handleChangeResume(e);
        }
    };

    const handleDownload = (e) => {
        e.preventDefault();

        if (resume) {
            let url = '';
            let fileName = '';

            if (typeof resume === 'string') {
                fileName = resume.split('/').pop();
                const apiHost = 'http://85.235.151.227';
                url = `${apiHost}${resume}`;
            } else {
                console.error('Invalid resume data type.');
                return;
            }

            if (url) {
                const aTag = document.createElement("a");
                aTag.href = url;
                aTag.setAttribute("download", fileName);
                document.body.appendChild(aTag);
                aTag.click();
                aTag.remove();
            } else {
                console.error('Failed to create a download link.');
            }
        } else {
            console.error('No resume data available.');
        }
    };

    const handleDelete = (e) => {
        e.preventDefault();
        const confirmDelete = window.confirm("Sei sicuro di voler cancellare il tuo CV?");
        if (confirmDelete) {
            if (resume === null) {
                setFileName('');
            }
            onDeleteResume();
        }
    };

    const truncateFileName = (fileName, maxLength) => {
        if (!fileName) return "Nome file";
        if (fileName.length <= maxLength) return fileName;

        const separator = '...';
        const sepLen = separator.length;
        const charsToShow = maxLength - sepLen;
        const frontChars = Math.ceil(charsToShow / 2);
        const backChars = Math.floor(charsToShow / 2);

        return fileName.substr(0, frontChars) + separator + fileName.substr(fileName.length - backChars);
    };

    return (
        <div className="download_title_option">
            <div className="download_title_option_inner">
                <div className="download_title">
                    {fileName ? <h3 title={fileName}>{truncateFileName(fileName, 20)}</h3> : <h3>CV in PDF</h3>}
                </div>
                <div className="download_options">
                    <div className="download_option">
                        <div ref={pdfIconRef} tabIndex={-1} onClick={handlePatchResume} >
                            <img src={PdfIcon} alt="Upload Resume" />
                        </div>
                        <input
                            type="file"
                            accept=".pdf,.doc,.docx"
                            ref={resumeUploadRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </div>

                    <div className="download_option">
                        <div
                            onClick={!isDownloadDisabled ? handleDownload : undefined}
                            style={{
                                cursor: isDownloadDisabled ? 'not-allowed' : 'pointer',
                                opacity: isDownloadDisabled ? 0.5 : 1
                            }}
                            title={isDownloadDisabled ? 'Please upload the file to the server first' : 'Download Resume'}
                        >
                            <img src={DownloadIcon} alt="Download Resume" />
                        </div>
                    </div>

                    <div className="download_option">
                        <div
                            onClick={!isDeleteDisabled ? handleDelete : undefined}
                            style={{
                                cursor: isDeleteDisabled ? 'not-allowed' : 'pointer',
                                opacity: isDeleteDisabled ? 0.5 : 1
                            }}
                            title={isDeleteDisabled ? 'No file available to delete. Please upload the file to the server first.' : 'Click to delete the resume'}
                        >
                            <img src={TrashIcon} alt="Delete Resume" />
                        </div>
                    </div>
                </div>
                <div className='error_upload_file'>
                    {errorResume_upload && (
                        <div style={mystyle_resume_file}>{errorResume_upload}</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ResumeFileToolbar;
