import React from 'react'

function AddressControl(props) {
    const {
        CreatAd_One,
        setCreatAd_One,
        errorCreatAd_One,
        setErrorCreatAd_One,
        handleOnCreatAd_One,
        handleOnGetAddress,
        validateStreetNumber,
        validateZipCode,
        streetRef,
        zipCodeRef,
        mystyle,
        t
    } = props;


    return (
        <>
            <div className="col-12">
                <div className="switch-btn-wrap">
                    <div className="switch-btn">
                        <input
                            type="checkbox"
                            readOnly
                            checked={CreatAd_One.isAddressComplete}
                            onClick={() => {
                                setCreatAd_One((prevstate) => ({
                                    ...prevstate,
                                    isAddressComplete:
                                        !CreatAd_One?.isAddressComplete,
                                }));
                                CreatAd_One?.isAddressComplete === true &&
                                    setErrorCreatAd_One((preverr) => ({
                                        ...preverr,
                                        isAddressComplete: "",
                                    }));
                                // if (CreatAd_One?.isAddressComplete === false) {
                                //     setCreatAd_One((prevstate) => ({
                                //         ...prevstate,
                                //         street: "",
                                //         zip_code: "",
                                //         city: "",
                                //         province: ""
                                //     }));
                                // }
                            }}
                        />
                        <span></span>
                    </div>
                    <label>Indirizzo completo</label>
                </div>
                {CreatAd_One?.isAddressComplete === false &&
                    errorCreatAd_One?.isAddressComplete && (
                        <div style={mystyle}>
                            {errorCreatAd_One?.isAddressComplete}
                        </div>
                    )}
            </div>
            <div className="col-12" style={{
                visibility: `${CreatAd_One.isAddressComplete === true
                    ? "visible"
                    : "hidden"
                    }`,
            }}>

                <label className="form-label">
                    Indirizzo (via, numero civico)
                </label>
                <input
                    type="text"
                    className={`form-control  ${errorCreatAd_One.street ? "error" : ""
                        }`}
                    placeholder={t("register.street_placeholder")}
                    id="street"
                    name="street"
                    ref={streetRef}
                    value={CreatAd_One.street || ""}
                    onChange={handleOnCreatAd_One}
                    onBlur={validateStreetNumber}
                />
                {errorCreatAd_One.street && (
                    <div style={mystyle}>{errorCreatAd_One.street}</div>
                )}
            </div>
            <div className="col-12" style={{
                visibility: `${CreatAd_One.isAddressComplete === true
                    ? "visible"
                    : "hidden"
                    }`,
            }}>

                <label className="form-label">
                    Codice Avviamento Postale
                </label>
                <div className="d-flex justify-content-between">
                    <div>
                        <input
                            type="number"
                            className={`form-control zip_input ${errorCreatAd_One.zip_code ? "error" : ""
                                }`}
                            placeholder={t("register.placeHolder_zip_code")}
                            id="zip_code"
                            name="zip_code"
                            value={CreatAd_One.zip_code || ""}
                            onChange={handleOnCreatAd_One}
                            ref={zipCodeRef}
                            onBlur={validateZipCode}
                        />
                        {errorCreatAd_One.zip_code && (
                            <div style={mystyle}>{errorCreatAd_One.zip_code}</div>
                        )}
                    </div>
                    <div className="btn btn_default btn_green_outline" onClick={handleOnGetAddress}
                        style={{
                            transition: `${CreatAd_One.isAddressComplete === true
                                ? "all 0.5s ease-in-out"
                                : "none"
                                }`,
                        }}>
                        salva indirizzo
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddressControl