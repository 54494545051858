import React from "react";
import { useTranslation } from 'react-i18next';
// import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer text-end">
      <div className="container">
        <div className="footer_inner">
          <div className="copyright">
            <p>Copyright © 2024 Icedata S.r.l. - Tutti i diritti riservati - P.IVA 00665041208 -</p>
          </div>
          <div className="footer-nav">
            <ul>
              <li>
                <a>{t('footer.tab_1')}</a>
              </li>
              <li>
                <a href="https://www.iubenda.com/privacy-policy/51986058" target="_blank" rel="noopener noreferrer">
                  {t('footer.tab_2')}
                </a>
              </li>
              <li>
                <a>{t('footer.tab_3')}</a>
              </li>
              <li>
                <a>{t('footer.tab_4')}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

