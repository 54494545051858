import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    requestGetEducationLevelList
} from "../../../Redux/actions";
import CreatableSelect from 'react-select/creatable';

function QualificationLevel(props) {

    const [educationLevels, setEducationLevels] = useState([]);
    const [subSubmenuOptions, setSubSubmenuOptions] = useState([]);

    const {
        selectedOptionsEducation,
        handleEducationLevelChange,
        validateQualification,
        qualificationRef,
        errorqualification,
        handleSubsubmenuChange,
        validateSubMenu,
        // validateSubMenuInput,
        subqualificationRef,
        // submenuOptions,
        errorSubMenu,
        mystyle,
        t,
        isLoading
    } = props;

    const isClearable = true;

    useEffect(() => {
        props.requestGetEducationLevelList();
    }, []);


    useEffect(() => {
        const educationlevelData = props.candidate.EducationlevelData;
        if (educationlevelData?.data?.Status === "Success") {
            const uniqueLevels = {};

            educationlevelData.data.Message.forEach(level => {
                if (!uniqueLevels[level.levelType]) {
                    uniqueLevels[level.levelType] = { value: level.id, label: level.levelType };
                }
            });
            const transformedEducationlevelData = Object.values(uniqueLevels);
            setEducationLevels(transformedEducationlevelData);

            const filteredSubMenuOptions = educationlevelData.data.Message
                .filter(level => level.specialisation)
                .map(level => ({ levelType: level.levelType, specialisation: level.specialisation }));

            setSubSubmenuOptions(filteredSubMenuOptions);
        }
    }, [props.candidate.EducationlevelData]);

    const selectedSubmenuOption = subSubmenuOptions.filter(
        option => option.levelType === selectedOptionsEducation?.submenu
    );

    const hasSubSubmenuOptions = selectedSubmenuOption && selectedSubmenuOption?.length > 0;

    return (
        <>
            {selectedOptionsEducation && (
                <div className="registration_col form-group col-md-6 col-12">
                    <label htmlFor="qualification" className="label">
                        {t("register.qualification")}
                    </label>
                    <CreatableSelect
                        className={`form-control set_max_width select_dropdown  ${errorqualification ? "error" : ""} `}
                        options={educationLevels}
                        onChange={handleEducationLevelChange}
                        value={selectedOptionsEducation.submenu ? { label: selectedOptionsEducation.submenu, value: selectedOptionsEducation.submenu } : null}
                        ref={qualificationRef}
                        placeholder="Seleziona o inserisci il tuo titolo di studio"
                        onBlur={validateQualification}
                        isClearable={isClearable}
                        isLoading={isLoading}
                    />
                    {errorqualification && <div style={mystyle}>{errorqualification}</div>}
                </div>
            )}

            {selectedOptionsEducation?.submenu && hasSubSubmenuOptions && (
                <>
                    <div className="registration_col form-group col-md-6 col-12">
                        <label>{t("register.sub_qualification")}</label>
                        <select
                            name="qualification"
                            value={selectedOptionsEducation?.subSubmenu || ""}
                            onChange={handleSubsubmenuChange}
                            onBlur={validateSubMenu}
                            ref={subqualificationRef}
                            className={`form-control set_max_width mt-2 ${errorSubMenu ? "error" : ""}`}>
                            <option value="">{t("register.sub_qualification")}</option>
                            {selectedSubmenuOption?.map((subOption, index) => (
                                <option key={index} value={subOption.specialisation}>
                                    {subOption.specialisation}
                                </option>
                            ))}
                        </select>
                        {errorSubMenu && (
                            <div style={mystyle}>{errorSubMenu}</div>
                        )}
                    </div>
                </>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ requestGetEducationLevelList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QualificationLevel);

