import React from 'react'

function SDICode(props) {
    const {
        data,
        handleChangeSDICode,
        sdiCodeRef,
        validateSDIcode,
        errorSDIcode,
        mystyle,
        t
    } = props
    return (
        <div className="registration_col form-group col-md-6 col-12">
            <label htmlFor="sdi_code" className="label">
                Codice univoco SDI
            </label>
            <input
                type="text"
                className={`form-control set_max_width ${errorSDIcode ? "error" : ""
                    }`}
                placeholder="Codice univoco SDI"
                id="sdi_code"
                name="sdi_code"
                ref={sdiCodeRef}
                value={data.sdi_code || ""}
                onChange={handleChangeSDICode}
                onBlur={validateSDIcode}
            />
            {errorSDIcode && <div style={mystyle}>{errorSDIcode}</div>}
        </div>
    )
}

export default SDICode