import React from 'react'

function ResumeFile(props) {
    const {
        errorResume_upload,
        resumeUploadRef,
        validateResume_upload,
        handleChangeResume,
        mystyle
    } = props
    return (
        <>
            <label className="label">
                Carica il tuo CV in formato elettronico <br /> (formati accettati: .pdf, .doc e .docx)
            </label>
            <div className="position-relative">
                <div className="input-group custom-file-button">
                    <label className={`input-group-text file-button ${errorResume_upload ? 'error' : ''}`} htmlFor="resume_upload">Scegli file</label>
                    <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        className={`form-control ${errorResume_upload ? 'error' : ''}`}
                        id="resume_upload"
                        name="resume_upload"
                        ref={resumeUploadRef}
                        onBlur={validateResume_upload}
                        onChange={handleChangeResume}
                        placeholder="Nessun file selezionato" />
                    {errorResume_upload && (
                        <div style={mystyle}>{errorResume_upload}</div>
                    )}
                </div>
            </div>
        </>

    )
}

export default ResumeFile