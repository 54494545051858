import React, { useEffect } from "react";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import Select, { createFilter } from "react-select";
import PhoneInput from "react-phone-input-2";
import it from "react-phone-input-2/lang/it.json";
import ToggleButtonControl from "./ToggleButtonControl";
import AddressControl from "./AddressControl";
import SelectLocation from "./SelectLocation";
import NextButton from "../common/NextButton";
import PreviousAdvertisment from "./PreviousAdvertisment";
import loaderLogo from "../../../images/Iphone-spinner-2.gif";
import CreatableSelect from 'react-select/creatable';

const CreaAnnuncioOne = (props) => {
    const {
        nextStep,
        CreatAd_One,
        errorCreatAd_One,
        setCreatAd_One,
        setErrorCreatAd_One,
        sortedCountryData,
        municipalities,
        recruitmentTimeline,
        countryRef,
        provinceRef,
        emailRef,
        addemailRef,
        cityRef,
        phoneRef,
        streetRef,
        handleOnCreatAd_One,
        handleOnCreatAdJobTitle,
        handlenContactPerson,
        handleChangeCityCreatAd_One,
        handleOnCreatNumberOfRoles,
        handleOnDadeLineDate,
        handlePhoneChange,
        handleOnGetAddress,
        isLoading,
        validateEmail,
        validateCountry,
        validateCity,
        validateProvince,
        validateZipCode,
        validatePhone,
        validateAddEmail,
        validateJobTitle,
        validateNumberOfRoles,
        validateStreetNumber,
        validateAdExpDate,
        validateTimelinePosition,
        handleOnPreviousAdvertisement,
        // proviousaddvalidate,
        contactpersonValidate,
        error,
        setError,
        mystyle,
        isClearable,
        isSearchable,
        filterConfig,
        t,
        jobList,
        previousAdd,
        representative,
        previousAddRef,
        jobTitleRef,
        rolesSoughtRef,
        zipCodeRef,
        contactPersonRef,
        applicationSendingDeadlineRef,
        recruitmentTimeRef,
        CreatAd_One_Loader
    } = props;


    useEffect(() => {
        const focusField = () => {
            if (errorCreatAd_One.job_title && jobTitleRef?.current) {
                jobTitleRef.current.focus();
            } else if (errorCreatAd_One.country && countryRef?.current) {
                countryRef.current.focus();
            } else if (errorCreatAd_One.city && cityRef?.current) {
                cityRef.current.focus();
            } else if (errorCreatAd_One.province && provinceRef?.current) {
                provinceRef.current.focus();
            } else if (errorCreatAd_One.contact_person && contactPersonRef?.current) {
                contactPersonRef.current.focus();
            } else if (errorCreatAd_One?.n_roles_sought && rolesSoughtRef?.current) {
                rolesSoughtRef?.current.focus();
            } else if (errorCreatAd_One.street && streetRef?.current) {
                streetRef.current.focus();
            } else if (errorCreatAd_One.zip_code && zipCodeRef?.current) {
                zipCodeRef.current.focus();
            } else if (errorCreatAd_One.application_sending_deadline && applicationSendingDeadlineRef?.current) {
                applicationSendingDeadlineRef.current.focus();
            } else if (errorCreatAd_One.recruitment_time && recruitmentTimeRef?.current) {
                recruitmentTimeRef.current.focus();
            }
        };

        if (error) {
            focusField();
            setError(false);
        }
    }, [
        error,
        errorCreatAd_One,
        jobTitleRef,
        countryRef,
        cityRef,
        provinceRef,
        contactPersonRef,
        rolesSoughtRef,
        streetRef,
        zipCodeRef,
        applicationSendingDeadlineRef,
        recruitmentTimeRef,
        addemailRef,
        phoneRef,
    ]);

    return (
        <>
            <Header />
            <div className="seekng_job_sec create-an-ad">
                <div className="container position-relative" style={{ minHeight: "calc(100vh - 210px)" }}>
                    {CreatAd_One_Loader ?
                        <div className="loader">
                            <div className='loader_inner'>
                                <img src={loaderLogo} />
                            </div>
                        </div> : (
                            <form>
                                <PreviousAdvertisment
                                    CreatAd_One={CreatAd_One}
                                    // errorCreatAd_One={errorCreatAd_One}
                                    // setCreatAd_One={setCreatAd_One}
                                    // proviousaddvalidate={proviousaddvalidate}
                                    handleOnPreviousAdvertisement={handleOnPreviousAdvertisement}
                                    previousAdd={previousAdd}
                                    isClearable={isClearable}
                                    isSearchable={isSearchable}
                                    previousAddRef={previousAddRef}
                                    filterConfig={filterConfig}
                                    mystyle={mystyle}
                                />
                                <div className="seekng_job_title_note">
                                    <div className="seekng_job_title">
                                        <h2>
                                            Inserisci un nuovo annuncio per la ricerca di dipendenti
                                        </h2>
                                        <h6>Informazioni generali</h6>
                                    </div>
                                </div>
                                <div className="seeking_job_inner">
                                    <div className="seeking_job_form">
                                        <div className="seeking_form_left">
                                            <div className="row g-3">
                                                <div className="col-12">
                                                    <label className="form-label">
                                                        Posizione ricercata (Titolo dell’annuncio)
                                                    </label>
                                                    <Select
                                                        className={`form-control select_dropdown  ${CreatAd_One?.job_title?.length === 0 && errorCreatAd_One.job_title
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                        placeholder="Seleziona una posizione o creane una nuova"
                                                        value={CreatAd_One.job_title ? { label: `${CreatAd_One.job_title}`, value: `${CreatAd_One.job_title}` } : ""}
                                                        onChange={handleOnCreatAdJobTitle}
                                                        onBlur={validateJobTitle}
                                                        options={jobList}
                                                        ref={jobTitleRef}
                                                        isClearable={isClearable}
                                                        isSearchable={isSearchable}
                                                        filterOption={createFilter(filterConfig)}
                                                    />
                                                    {CreatAd_One?.job_title?.length === 0 &&
                                                        errorCreatAd_One?.job_title && (
                                                            <div style={mystyle}>
                                                                {errorCreatAd_One.job_title}
                                                            </div>
                                                        )}
                                                </div>

                                                <div className="col-12">
                                                    <label className="form-label">Indirizzo</label>
                                                    <Select
                                                        className="form-control select_dropdown"
                                                        // className={`form-control select_dropdown  ${errorCreatAd_One.street
                                                        //     ? "error"
                                                        //     : ""
                                                        //     }`}
                                                        placeholder="Seleziona un indirizzo"
                                                        // value={Advertisement_titles.find(
                                                        //     (option) => option.value === CreatAd_One.street
                                                        // )}
                                                        // onChange={(value) => {
                                                        //     setCreatAd_One((prev) => ({
                                                        //         ...prev,
                                                        //         street: value?.value,
                                                        //     }));
                                                        // }}
                                                        onBlur={validateStreetNumber}
                                                        // options={Advertisement_titles}
                                                        isClearable={isClearable}
                                                        isSearchable={isSearchable}
                                                        filterOption={createFilter(filterConfig)}
                                                    />
                                                    {/* {
                         errorCreatAd_One?.street && (
                             <div style={mystyle}>{errorCreatAd_One.street}</div>
                         )} */}
                                                </div>

                                                <SelectLocation
                                                    CreatAd_One={CreatAd_One}
                                                    errorCreatAd_One={errorCreatAd_One}
                                                    sortedCountryData={sortedCountryData}
                                                    municipalities={municipalities}
                                                    handleOnCreatAd_One={handleOnCreatAd_One}
                                                    handleChangeCityCreatAd_One={handleChangeCityCreatAd_One}

                                                    countryRef={countryRef}
                                                    cityRef={cityRef}
                                                    provinceRef={provinceRef}
                                                    validateCountry={validateCountry}
                                                    validateCity={validateCity}
                                                    validateProvince={validateProvince}
                                                    isLoading={isLoading}
                                                    isClearable={isClearable}
                                                    isSearchable={isSearchable}
                                                    filterConfig={filterConfig}
                                                    mystyle={mystyle}
                                                    t={t}
                                                />

                                                <div className="col-12">
                                                    <label className="form-label">Referente interno</label>
                                                    {/* <input
                         type="text"
                         name="contact_person"
                         className={`form-control ${CreatAd_One?.contact_person?.length === 0 &&
                             errorCreatAd_One?.contact_person
                             ? "error"
                             : ""
                             }`}

                         placeholder="Seleziona utente"
                         value={CreatAd_One?.contact_person || ""}
                         onChange={handleOnCreatAd_One}
                         onBlur={contactpersonValidate}
                     />
                     {CreatAd_One?.contact_person?.length === 0 &&
                         errorCreatAd_One?.contact_person && (
                             <div style={mystyle}>
                                 {errorCreatAd_One?.contact_person}
                             </div>
                         )} */}

                                                    <Select
                                                        className={`form-control select_dropdown  ${CreatAd_One?.contact_person?.length === 0 &&
                                                            errorCreatAd_One.contact_person
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                        placeholder="Seleziona utente"

                                                        value={representative.find(
                                                            (option) =>
                                                                option.value === CreatAd_One.contact_person
                                                        )}

                                                        // onChange={(value) => {
                                                        //     setCreatAd_One((prev) => ({
                                                        //         ...prev,
                                                        //         contact_person: value?.value ? value?.value : "",
                                                        //     }));
                                                        // }}
                                                        ref={contactPersonRef}
                                                        onChange={handlenContactPerson}
                                                        onBlur={contactpersonValidate}
                                                        options={representative}
                                                        isClearable={isClearable}
                                                        isSearchable={isSearchable}
                                                    />
                                                    {CreatAd_One?.contact_person?.length === 0 &&
                                                        errorCreatAd_One?.contact_person && (
                                                            <div style={mystyle}>
                                                                {errorCreatAd_One.contact_person}
                                                            </div>
                                                        )}
                                                </div>
                                                <ToggleButtonControl
                                                    CreatAd_One={CreatAd_One}
                                                    setCreatAd_One={setCreatAd_One}
                                                    errorCreatAd_One={errorCreatAd_One}
                                                    setErrorCreatAd_One={setErrorCreatAd_One}
                                                    mystyle={mystyle}
                                                />
                                            </div>
                                        </div>
                                        <div className="seeking_form_right">
                                            <div className="row g-3">
                                                <div className="col-12">
                                                    <label className="form-label">
                                                        N° di ruoli ricercati
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className={`form-control zip_input ${errorCreatAd_One.n_roles_sought ? "error" : ""
                                                            }`}
                                                        id="n_roles_sought"
                                                        name="n_roles_sought"
                                                        value={CreatAd_One.n_roles_sought || ""}
                                                        ref={rolesSoughtRef}
                                                        onChange={(e) => handleOnCreatNumberOfRoles(e)}
                                                        onBlur={validateNumberOfRoles}
                                                    />
                                                    {errorCreatAd_One.n_roles_sought && (
                                                        <div style={mystyle}>
                                                            {errorCreatAd_One.n_roles_sought}
                                                        </div>
                                                    )}
                                                </div>

                                                <AddressControl
                                                    CreatAd_One={CreatAd_One}
                                                    setCreatAd_One={setCreatAd_One}
                                                    errorCreatAd_One={errorCreatAd_One}
                                                    setErrorCreatAd_One={setErrorCreatAd_One}
                                                    handleOnCreatAd_One={handleOnCreatAd_One}
                                                    handleOnGetAddress={handleOnGetAddress}
                                                    validateStreetNumber={validateStreetNumber}
                                                    validateZipCode={validateZipCode}
                                                    streetRef={streetRef}
                                                    zipCodeRef={zipCodeRef}
                                                    mystyle={mystyle}
                                                    t={t}
                                                />

                                                <div className="col-12">
                                                    <label className="form-label">
                                                        Invia gli aggiornamenti per questo annuncio
                                                        all’indirizzo
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        // className={`form-control  ${emailRef?.current?.value?.length === 0 &&
                                                        //     errorCreatAd_One.email_for_updates
                                                        //     ? "error"
                                                        //     : ""
                                                        //     }`}
                                                        placeholder={t("register.email")}
                                                        id="email_for_updates"
                                                        name="email_for_updates"
                                                        ref={emailRef}
                                                        value={CreatAd_One.email_for_updates || ""}
                                                        readOnly
                                                    // onChange={handleOnCreatAd_One}
                                                    // onBlur={validateEmail}
                                                    />
                                                    {/* {emailRef?.current?.value?.length === 0 &&
                         errorCreatAd_One.email_for_updates && (
                             <div style={mystyle}>
                                 {errorCreatAd_One.email_for_updates}
                             </div>
                         )} */}
                                                </div>
                                                <div className="col-12">
                                                    <div className="selection_checkboxs row">
                                                        <div className="selection_checkboxs_col col-12">
                                                            <div className="selection_checkbox m-0" onClick={() => {
                                                                setCreatAd_One((prevstate) => ({
                                                                    ...prevstate,
                                                                    updates_by_email:
                                                                        !CreatAd_One?.updates_by_email,
                                                                }));
                                                                CreatAd_One?.updates_by_email === true &&
                                                                    setErrorCreatAd_One((preverr) => ({
                                                                        ...preverr,
                                                                        updates_by_email: "",
                                                                    }));
                                                            }}>
                                                                <input
                                                                    type="checkbox"
                                                                    readOnly
                                                                    checked={CreatAd_One?.updates_by_email}
                                                                />
                                                                <label>
                                                                    Invia un aggiornamento per e-mail anche all’arrivo
                                                                    di ogni candidatura
                                                                </label>
                                                            </div>
                                                            {CreatAd_One?.updates_by_email === false &&
                                                                errorCreatAd_One.updates_by_email && (
                                                                    <div style={mystyle}>
                                                                        {errorCreatAd_One.updates_by_email}
                                                                    </div>
                                                                )}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                    className="col-12"
                                                    style={{
                                                        visibility: `${CreatAd_One.allow_phone_contact === true
                                                            ? "visible"
                                                            : "hidden"
                                                            }`,
                                                    }}
                                                >
                                                    <label className="form-label">
                                                        Numero tel. del referente
                                                    </label>
                                                    <PhoneInput
                                                        // className={`form-control p-0 numero_cellulare ${errorCreatAd_One.cellphone ? "error" : ""
                                                        //     }`}
                                                        className="form-control p-0 numero_cellulare"
                                                        name="cellphone"
                                                        country={"it"}
                                                        localization={it}
                                                        value={CreatAd_One?.cellphone || ""}
                                                        ref={phoneRef}
                                                        placeholder={t("register.cellphone")}
                                                        // onChange={(e) => handlePhoneChange(e)}
                                                        // onBlur={validatePhone}
                                                        enableLongNumbers={18}
                                                        disabled={true}
                                                    />
                                                    {/* {errorCreatAd_One.cellphone && (
                         <div style={mystyle}>{errorCreatAd_One.cellphone}</div>
                     )} */}
                                                </div>

                                                <div
                                                    className="col-12"
                                                    style={{
                                                        visibility: `${CreatAd_One.allow_e_mail_contact === true
                                                            ? "visible"
                                                            : "hidden"
                                                            }`,
                                                    }}
                                                >
                                                    <label className="form-label">E-mail referente</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        // className={`form-control  ${errorCreatAd_One.e_mail ? "error" : ""
                                                        //     }`}
                                                        placeholder={t("register.email")}
                                                        id="e_mail"
                                                        name="e_mail"
                                                        ref={addemailRef}
                                                        value={CreatAd_One.e_mail || ""}
                                                        // onChange={handleOnCreatAd_One}
                                                        // onBlur={validateAddEmail}
                                                        readOnly
                                                    />
                                                    {/* {errorCreatAd_One.e_mail && (
                         <div style={mystyle}>{errorCreatAd_One.e_mail}</div>
                     )} */}
                                                </div>
                                                <div
                                                    className="col-12"
                                                    style={{
                                                        visibility: `${CreatAd_One.is_exp_date === true
                                                            ? "visible"
                                                            : "hidden"
                                                            }`,
                                                    }}
                                                >
                                                    <div className="col-12">
                                                        <label className="form-label">
                                                            Data termine ultimo per invio
                                                        </label>
                                                        <input
                                                            className={`form-control w-auto ${errorCreatAd_One.application_sending_deadline ? "error" : ""
                                                                }`}
                                                            type="date"
                                                            name="application_sending_deadline"
                                                            id={"application_sending_deadline"}
                                                            value={CreatAd_One.application_sending_deadline || ""}
                                                            onBlur={validateAdExpDate}
                                                            ref={applicationSendingDeadlineRef}
                                                            onChange={handleOnDadeLineDate}
                                                            placeholder={t("register.birth_date")}
                                                        />
                                                        {errorCreatAd_One.application_sending_deadline && (
                                                            <div style={mystyle}>
                                                                {errorCreatAd_One.application_sending_deadline}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <label className="form-label">
                                                        Tempistiche di reclutamento per questa posizione
                                                    </label>
                                                    <CreatableSelect
                                                        className={`form-control select_dropdown  ${CreatAd_One?.recruitment_time?.length === 0 &&
                                                            errorCreatAd_One.recruitment_time
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                        value={recruitmentTimeline.find(
                                                            (option) =>
                                                                option.label === CreatAd_One.recruitment_time
                                                        )}

                                                        onChange={(value) => {
                                                            setCreatAd_One((prev) => ({
                                                                ...prev,
                                                                recruitment_time: value?.label ? value?.label : "",
                                                            }));
                                                        }}
                                                        ref={recruitmentTimeRef}
                                                        onBlur={validateTimelinePosition}
                                                        options={recruitmentTimeline}
                                                        isClearable={isClearable}
                                                        isSearchable={isSearchable}
                                                    />
                                                    {CreatAd_One?.recruitment_time?.length === 0 &&
                                                        errorCreatAd_One?.recruitment_time && (
                                                            <div style={mystyle}>
                                                                {errorCreatAd_One.recruitment_time}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="seeking_form_btn">
                                    <div className="btn-prev ">
                                    </div>
                                    <NextButton
                                        nextStep={nextStep}
                                    />
                                </div>
                            </form>
                        )}

                </div>
            </div>
            <Footer />
        </>
    );
};

export default CreaAnnuncioOne;
