import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Breadcrumbs() {
  const location = useLocation();
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { href: "/", label: "Home" },
  ]);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/" || path === "/home") {
      setBreadcrumbItems([{ href: "/", label: "Home" }]);
    } else {
      const paths = path.split("/").filter((x) => x);
      const newBreadcrumbs = [{ href: "/", label: "Home" }];

      paths.forEach((path, index) => {
        const href = `/${paths.slice(0, index + 1).join("/")}`;
        // const label = path === 'job-finder' ? 'Cerchi un lavoro' : path;
        let label;

        if (path === "job-finder") {
          label = "Cerchi un lavoro";
        } else if (path === "candidate-finder") {
          label = "Stai cercando lavoro";
        } else {
          label = path;
        }

        newBreadcrumbs.push({ href, label });
      });
      setBreadcrumbItems(newBreadcrumbs);
    }
  }, [location.pathname]);

  return (
    <div className="breadcrumb_sec">
      <div className="container display-none">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {breadcrumbItems.map(({ href, label }, index) => (
              <li
                key={href}
                className={`breadcrumb-items ${
                  index === breadcrumbItems.length - 1 ? "active" : ""
                }`}
                aria-current={
                  index === breadcrumbItems.length - 1 ? "page" : undefined
                }
              >
                {/* <Link  to={href} state={{ previousPath: location.pathname }}>
                                    {console.log(label, "label")}
                                    {label}
                                </Link> */}
                {label}
                {index < breadcrumbItems.length - 1 && " > "}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}

export default Breadcrumbs;
