import React from 'react';

function TitleField(props) {
    const {
        data,
        titleData,
        onChangeData,
        validateTitle,
        titleRef,
        errortitle,
        mystyle,
        t,
    } = props;
    return (
        <div className="form-group col-md-2 col-12">
            <label htmlFor="title" className="label">
                {/* Titolo */}
                {t("register.title")}
            </label>
            <select
                className={`form-control ${errortitle ? "error" : ""}`}
                name="title"
                id="title"
                value={data.title}
                ref={titleRef}
                onChange={onChangeData}
                onBlur={validateTitle}>
                <option value="">{t("register.title")}</option>
                {titleData.map((option, index) => (
                    <option key={index} value={option.title}>
                        {option.title}
                    </option>
                ))}
            </select>
            {errortitle && <div style={mystyle}>{errortitle}</div>}
        </div>
    )
}

export default TitleField