import React from 'react';
import Select, { createFilter } from "react-select";

function PreviousAdvertisment(props) {
  const {
    CreatAd_One,
    // setCreatAd_One,
    // proviousaddvalidate,
    handleOnPreviousAdvertisement,
    previousAdd,
    isClearable,
    isSearchable,
    previousAddRef,
    filterConfig,
  } = props;


  return (
    <div className="copy-from-prev-ad">
      <div className="seeking_job_form">
        <div className="seeking_form_left">
          <div className="row g-3">
            <div className="col-12">
              <label className="form-label">
                Copia da annuncio precedente
              </label>
              {/* <input
                type="text"
                name="privious_ad"
                className="form-control"
                placeholder="Seleziona utente"
                value={CreatAd_One?.privious_ad || ""}
                readOnly
              /> */}

              <Select
                className='form-control select_dropdown'
                placeholder="Seleziona un annuncio"
                value={CreatAd_One.privious_ad ? { label: `${CreatAd_One.privious_ad}`, value: `${CreatAd_One.privious_ad}` } : ""}

                // onChange={(value) => {
                //   setCreatAd_One((prev) => ({
                //     ...prev,
                //     privious_ad: value?.label ? value?.label : "",
                //   }));
                // }}
                onChange={handleOnPreviousAdvertisement}
                ref={previousAddRef}
                // onBlur={proviousaddvalidate}
                options={previousAdd}
                isClearable={isClearable}
                isSearchable={isSearchable}
                filterOption={createFilter(filterConfig)}
              />
            </div>
          </div>
        </div>
        <div className="seeking_form_right"></div>
      </div>
    </div>
  )
}

export default PreviousAdvertisment