import React from 'react';
import { generateYears, months } from '../../common/YearMonthResume'

function MonthYearsEdu(props) {
    const years = generateYears();
    const {
        i,
        x,
        erroreducation,
        handleInputEducation,
        validateEducationYearFrom,
        validateEducationYearTo,
        focusRefs,
        mystyle,
        t
    } = props

    return (
        <>
            <div className='col-lg-6 col-md-6 col-12'>
                <div className="form-group">
                    <label htmlFor="monthFrom">{t('time.From_year')}</label>
                    <div className='row'>
                        <div className="col-lg-4 col-md-6" style={{ padding: "0px 5px" }}>
                            <select
                                className="form-control"
                                name="monthFrom"
                                id={"monthFromedu" + i}
                                value={x.monthFrom}
                                onChange={(e) => handleInputEducation(e, i)}
                            >
                                <option value="">{t('time.select_month')}</option>
                                {months.map((month, index) => (
                                    <option key={index} value={(index + 1).toString().padStart(2, '0')}>
                                        {month}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-8 col-md-6" style={{ padding: "0px 5px" }}>

                            <select
                                className={`form-control ${erroreducation[i].yearFrom ? 'error' : ''}`}
                                name="yearFrom"
                                id={"yearFromedu" + i}
                                value={x.yearFrom}
                                ref={(el) => {
                                    focusRefs.education.current[i] = focusRefs.education.current[i] || {};
                                    focusRefs.education.current[i].yearFromedu = el;
                                }}
                                onBlur={() => validateEducationYearFrom(i)}
                                onChange={(e) => handleInputEducation(e, i)}
                            >
                                <option value="">{t('time.select_year')}</option>
                                {years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                            {erroreducation[i].yearFrom && (
                                <div style={mystyle}>{erroreducation[i].yearFrom}</div>
                            )}
                        </div>
                    </div>
                </div>

            </div>

            <div className='col-lg-6 col-md-6 col-12'>
                <div className="form-group">
                    <label htmlFor="monthTo">{t('time.To_year')}</label>
                    <div className='row'>
                        <div className="col-lg-4 col-md-6" style={{ padding: "0px 5px" }}>
                            <select
                                className="form-control"
                                name="monthTo"
                                id={"monthToedu" + i}
                                value={x.monthTo}
                                onChange={(e) => handleInputEducation(e, i)}
                            >
                                <option value="">{t('time.select_month')}</option>
                                {months.map((month, index) => (
                                    <option key={index} value={(index + 1).toString().padStart(2, '0')}>
                                        {month}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-8 col-md-6" style={{ padding: "0px 5px" }}>
                            <select
                                className={`form-control ${erroreducation[i].yearTo ? 'error' : ''}`}
                                name="yearTo"
                                id={"yearToedu" + i}
                                value={x.yearTo}
                                ref={(el) => {
                                    focusRefs.education.current[i] = focusRefs.education.current[i] || {};
                                    focusRefs.education.current[i].yearToedu = el;
                                }}
                                onBlur={() => validateEducationYearTo(i)}
                                onChange={(e) => handleInputEducation(e, i)}
                            >
                                <option value="">{t('time.select_year')}</option>
                                <option value="presente">Presente</option>
                                {years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                            {erroreducation[i].yearTo && (
                                <div style={mystyle}>{erroreducation[i].yearTo}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MonthYearsEdu