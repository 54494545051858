import React, { useState, useRef } from 'react';

const VatValidator = (props) => {
  const {
    data,
    handleOnVatnumber,
    validateVatNumber,
    errorVatNumber,
    vatNumberRef,
    mystyle
  }
    = props;

  return (
    <div className="registration_col form-group col-md-6 col-12">
      <label htmlFor="vat_number" className="label">
        Partita Iva
      </label>
      <input
        type="number"
        className={`form-control set_max_width ${errorVatNumber ? "error" : ""}`}
        placeholder="Partita Iva"
        id="vat_number"
        name="vat_number"
        ref={vatNumberRef}
        value={data.vat_number || ""}
        onChange={handleOnVatnumber}
        onBlur={validateVatNumber}
      />
      {errorVatNumber && <div style={mystyle}>{errorVatNumber}</div>}
    </div>
  );
};

export default VatValidator;
