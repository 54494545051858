import React from 'react';
import { Link } from 'react-router-dom';

function PasswordResetComplete() {
    return (
        <div className="container my-3 p-3">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <div className="card shadow-lg border-0 rounded-lg mt-0 mb-3">
                        <div className="alert alert-info">
                            La tua password è stata cambiata. Puoi procedere e <a href="{% url 'login' %}">loggarti qui</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordResetComplete