// import React, { useEffect, useState } from "react";
// import Header from "../../Components/Header";
// import Footer from "../../Components/Footer";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { requestGetJobList } from "../../Redux/actions";
// import { useNavigate } from "react-router-dom";
// import PermissionAdvertisment from "./PermissionAdvertisment";

// function AdvertisementList(props) {
//     const [jobList, setJobList] = useState([]);
//     const [searchQuery, setSearchQuery] = useState('');
//     const navigate = useNavigate();

//     useEffect(() => {
//         const token = localStorage.getItem("token");
//         if (token) {
//             const updatedRequestData = {
//                 token: token
//             };
//             props.requestGetJobList({ data: updatedRequestData });
//         }
//     }, []);

//     useEffect(() => {
//         let jobFinddata = props.candidate.GetJobListData;
//         if (jobFinddata !== undefined) {
//             if (jobFinddata?.data?.Status === "Success") {
//                 if (Array.isArray(jobFinddata.data.Message) && jobFinddata.data.Message.length === 0) {
//                 } else {
//                     setJobList(jobFinddata?.data?.Message);
//                 }
//                 props.candidate.GetJobListData = undefined;
//             } else {
//                 props.candidate.GetJobListData = undefined;
//             }
//         }
//     }, [props.candidate.GetJobListData]);

//     const formatDate = (dateString) => {
//         if (!dateString) return '';
//         const date = new Date(dateString);
//         const day = date.getDate().toString().padStart(2, '0');
//         const month = (date.getMonth() + 1).toString().padStart(2, '0');
//         const year = date.getFullYear();
//         return `${day}/${month}/${year}`;
//     };

//     const formatDate1 = (dateString) => {
//         const date = new Date(dateString);
//         if (isNaN(date.getTime())) return '';
//         const day = date.getDate().toString().padStart(2, '0');
//         const month = (date.getMonth() + 1).toString().padStart(2, '0');
//         const year = date.getFullYear();
//         return `${day}/${month}/${year}`;
//     };

//     const redirectOnDetails = (id) => {
//         navigate("/company/advertisement-details/" + id);
//     }

//     const redirectToCreaAnnuncio7_bis = () => {
//         console.log("step 7 redirect")
//     }

//     const redictToCreaAnnuncio7_bis = () => (
//         console.log("but this button show step 7 redirect")
//     )


//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };


//     const filteredJobList = jobList.filter(job => {
//         const jobTitle = job.job_title.toLowerCase();
//         const creationDate = formatDate(job.ad_creation_date).toLowerCase();
//         const expirationDate = formatDate1(job.ad_exp_date).toLowerCase();
//         const query = searchQuery.toLowerCase();

//         return (
//             jobTitle.includes(query) ||
//             creationDate.includes(query) ||
//             expirationDate.includes(query)
//         );
//     });


//     return (
//         <>
//             <Header />
//             <div className="advertisement_listing">
//                 <div className="container">
//                     <div className="advertisement_listing_inner">
//                         <div className="advertisement_title">
//                             <h3>Lista annunci inseriti</h3>
//                         </div>
//                         <div className="search_ads">
//                             <form>
//                                 <div className="row g-4">
//                                     <div className="form-group col-md-6 col-12">
//                                         <input
//                                             type="text"
//                                             className="form-control"
//                                             placeholder="Ricerca annunci"
//                                             id="search"
//                                             name="search"
//                                             value={searchQuery}
//                                             onChange={handleSearchChange}
//                                         />
//                                     </div>
//                                 </div>
//                             </form>
//                         </div>
//                         <div className="advertisement_table table-responsive">
//                             <table className="table">
//                                 <thead>
//                                     <tr>
//                                         <th scope="col">Data di pubblicazione</th>
//                                         <th scope="col">Titolo</th>
//                                         <th scope="col">Candidature ricevute</th>
//                                         <th scope="col">Scadenza annuncio</th>
//                                         <th scope="col">Stato annuncio</th>
//                                         <th scope="col">Sponsorizzato</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {filteredJobList.length > 0 && filteredJobList.map((item, index) => (
//                                         <tr key={index}>
//                                             <td>
//                                                 <p className="publication_date">{formatDate(item.ad_creation_date)}</p>
//                                             </td>
//                                             <td>
//                                                 {item.draft_id !== null ?
//                                                     (
//                                                         <a className="title">
//                                                             {item.job_title}
//                                                         </a>
//                                                     ) : (
//                                                         <a className="title" onClick={() => redirectOnDetails(item.id)}>
//                                                             {item.job_title}
//                                                         </a>
//                                                     )}

//                                             </td>
//                                             <td>
//                                                 <p className="application_count">{item.contract_duration_n}</p>
//                                             </td>
//                                             <td>
//                                                 <p className="announcement_deadline">{formatDate1(item.ad_exp_date)}</p>
//                                             </td>
//                                             {/* <td>
//                                                 <div className="advertisement_status">
//                                                     <form className="advertisement_status_form">
//                                                         <div className="form-group">
//                                                             <p>{item.status}</p>
//                                                             <input type="checkbox" checked={item.status === "Published"} readOnly />
//                                                             <label htmlFor="status"></label>
//                                                         </div>
//                                                     </form>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="sponsored">
//                                                     <div className="yes_no">
//                                                         <p>{item.sponsored ? "Sì" : "No"}</p>
//                                                     </div>
//                                                     {item.sponsored && (
//                                                         <>
//                                                             <div className="renew">
//                                                                 <p>Rinnova</p>
//                                                             </div>
//                                                             <div className="token">
//                                                                 <p>{item.tokens_spent} Token spesi</p>
//                                                             </div>
//                                                         </>
//                                                     )}
//                                                 </div>
//                                             </td> */}
//                                             {/* <td>
//                                                 <div className="advertisement_status">
//                                                     <form className="advertisement_status_form">

//                                                         {item.draft_id !== null ? (
//                                                             <div className="form-group">
//                                                                 <p>Bozza</p>
//                                                                 <input
//                                                                     type="checkbox"
//                                                                     id={item.draft_id !== null ? `${item.id}_draft` : `draft_${item.id}`}
//                                                                     checked={!!checkedDraft[item.id]}
//                                                                     onChange={() => handleBozzaChange(item.id)}
//                                                                 />
//                                                                 <label
//                                                                     htmlFor={item.draft_id !== null ? `${item.id}_draft` : `draft_${item.id}`}
//                                                                 ></label>
//                                                             </div>
//                                                         ) : (
//                                                             <div className="form-group">
//                                                                 <p>Bozza</p>
//                                                                 <input
//                                                                     type="checkbox"
//                                                                     id={item.draft_id !== null ? `${item.id}_draft` : `draft_${item.id}`}
//                                                                     checked={!!checkedDraft[item.id]}
//                                                                     onChange={() => handleBozzaChange(item.id)}
//                                                                 />
//                                                                 <label
//                                                                     htmlFor={item.draft_id !== null ? `${item.id}_draft` : `draft_${item.id}`}
//                                                                 ></label>
//                                                             </div>
//                                                         )}

//                                                         {item.published === true ? (
//                                                             <div className="form-group">
//                                                                 <p>Pubblicato</p>
//                                                                 <input
//                                                                     type="checkbox"
//                                                                     id={item.published === true ? `${item.id}_published` : `published_${item.id}`}
//                                                                     checked={!!checkedPublished[item.id]}
//                                                                     onChange={() => handlePubblicatoChange(item.id)}
//                                                                 />
//                                                                 <label
//                                                                     htmlFor={item.published === true ? `${item.id}_published` : `published_${item.id}`}
//                                                                 ></label>
//                                                             </div>
//                                                         ) : (
//                                                             <div className="form-group">
//                                                                 <p>Pubblicato</p>
//                                                                 <input
//                                                                     type="checkbox"
//                                                                     id={item.published === true ? `${item.id}_published` : `published_${item.id}`}
//                                                                     checked={!!checkedPublished[item.id]}
//                                                                     onChange={() => handlePubblicatoChange(item.id)}
//                                                                 />
//                                                                 <label
//                                                                     htmlFor={item.published === true ? `${item.id}_published` : `published_${item.id}`}
//                                                                 ></label>
//                                                             </div>
//                                                         )}

//                                                         {item.suspended === true && item.published === false ? (
//                                                             <div className="form-group">
//                                                                 <p>Sospeso</p>
//                                                                 <div className="form-group">
//                                                                     <input
//                                                                         type="checkbox"
//                                                                         id={item.published === true ? `${item.id}_suspended` : `suspended_${item.id}`}
//                                                                         checked={!!checkedSuspended[item.id]}
//                                                                         onChange={() => handleSospesoChange(item.id)}
//                                                                     />
//                                                                     <label
//                                                                         htmlFor={item.suspended === true && item.published === false ? `${item.id}_suspended` : `suspended_${item.id}`}
//                                                                     ></label>
//                                                                 </div>
//                                                             </div>
//                                                         ) : (
//                                                             <div className="form-group">
//                                                                 <p>Sospeso</p>
//                                                                 <div className="form-group">
//                                                                     <input
//                                                                         type="checkbox"
//                                                                         id={item.published === true ? `${item.id}_suspended` : `suspended_${item.id}`}
//                                                                         checked={!!checkedSuspended[item.id]}
//                                                                         onChange={() => handleSospesoChange(item.id)}
//                                                                     />
//                                                                     <label
//                                                                         htmlFor={item.suspended === true && item.published === false ? `${item.id}_suspended` : `suspended_${item.id}`}
//                                                                     ></label>
//                                                                 </div>
//                                                             </div>
//                                                         )}
//                                                         {item.closed === true && item.published === false ? (
//                                                             <div className="form-group">
//                                                                 <p>Chiuso</p>

//                                                                 <input
//                                                                     type="checkbox"
//                                                                     id={item.closed === true && item.published === false ? `${item.id}_closed` : `closed_${item.id}`}
//                                                                     checked={!!checkedClosed[item.id]}
//                                                                     onChange={() => handleChiusoChange(item.id)}
//                                                                 />
//                                                                 <label
//                                                                     htmlFor={item.closed === true && item.published === false ? `${item.id}_closed` : `closed_${item.id}`}
//                                                                 ></label>
//                                                             </div>
//                                                         ) : (
//                                                             <div className="form-group">
//                                                                 <p>Chiuso</p>

//                                                                 <input
//                                                                     type="checkbox"
//                                                                     id={item.closed === true && item.published === false ? `${item.id}_closed` : `closed_${item.id}`}
//                                                                     checked={!!checkedClosed[item.id]}
//                                                                     onChange={() => handleChiusoChange(item.id)}
//                                                                 />
//                                                                 <label
//                                                                     htmlFor={item.closed === true && item.published === false ? `${item.id}_closed` : `closed_${item.id}`}
//                                                                 ></label>
//                                                             </div>
//                                                         )}

//                                                     </form>
//                                                 </div>
//                                             </td> */}
//                                             <PermissionAdvertisment
//                                                 item={item}
//                                             />

//                                             <td>
//                                                 <div className="sponsored">
//                                                     {/* <div className="yes_no">
//                                                         <p>Sì - conclusa</p>
//                                                     </div> */}

//                                                     {
//                                                         item.is_sponsored == true ?
//                                                             (
//                                                                 <div className="sponsored">
//                                                                     <p>Sì - in corso</p>
//                                                                 </div>
//                                                             ) : item.is_sponsored === false && item.have_been_sponsored === false ? (
//                                                                 <div className="sponsored" onClick={redictToCreaAnnuncio7_bis}>
//                                                                     <p>Sponsorizza</p>
//                                                                 </div>
//                                                             ) : item.is_sponsored === false && item.have_been_sponsored === true ? (
//                                                                 <div className="yes_no">
//                                                                     <p>Sì - conclusa</p>
//                                                                 </div>
//                                                             ) :
//                                                                 (
//                                                                     ""
//                                                                 )
//                                                     }

//                                                     <div className="renew" onClick={redirectToCreaAnnuncio7_bis}>
//                                                         <p>Rinnova</p>
//                                                     </div>

//                                                     {
//                                                         item.tokens_per_day !== null ?
//                                                             (
//                                                                 <div className="token">
//                                                                     <p>{item.tokens_per_day} Token spesi</p>
//                                                                 </div>
//                                                             ) : (
//                                                                 ""
//                                                             )
//                                                     }
//                                                     {/* <div className="token">
//                                                         <p>28 Token spesi</p>
//                                                     </div> */}
//                                                 </div>
//                                             </td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </>
//     );
// }

// const mapStateToProps = (state) => ({
//     candidate: state.candidate,
// });

// const mapDispatchToProps = (dispatch) => bindActionCreators({
//     requestGetJobList,
// }, dispatch);

// export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementList);


import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    requestCompanyDetails,
    requestGetJobListUserId
} from "../../Redux/actions";
import { useNavigate } from "react-router-dom";
import PermissionAdvertisment from "./PermissionAdvertisment";
import loaderLogo from "../../images/Iphone-spinner-2.gif";
function AdvertisementList(props) {
    const [jobList, setJobList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [jobListLoader, setJobListLoader] = useState(true);
    const [companyId, setCompanyId] = useState(null);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            props.requestCompanyDetails({ data: { token } })
        }
    }, []);

    // useEffect(() => {
    //     let jobFinddata = props.candidate.GetJobListData;
    //     if (jobFinddata !== undefined) {
    //         setJobListLoader(true);
    //         if (jobFinddata?.data?.Status === "Success") {
    //             if (Array.isArray(jobFinddata.data.Message) && jobFinddata.data.Message.length === 0) {
    //                 setJobList([]);
    //             } else {
    //                 setJobList(jobFinddata?.data?.Message);
    //             }
    //             setJobListLoader(false);
    //             props.candidate.GetJobListData = undefined;
    //         } else {
    //             setJobListLoader(false);
    //             props.candidate.GetJobListData = undefined;
    //         }
    //     }
    // }, [props.candidate.GetJobListData]);


    useEffect(() => {
        const getCompanyData = props.company.getCompanyData;
        if (getCompanyData !== undefined) {
            if (getCompanyData.data?.Status === "Success") {
                const CompanyDetails = getCompanyData?.data?.Message || {};

                if (CompanyDetails.flag_company) {
                    setCompanyId(CompanyDetails?.id);
                } else {
                    setUserId(CompanyDetails?.user);
                }
            } else {
                props.company.getCompanyData = undefined;
            }
        } else {
            props.company.getCompanyData = undefined;
        }

    }, [props.company.getCompanyData]);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (companyId) {
            const requestData = {
                company_id: companyId,
            };

            if (token) {
                props.requestGetJobListUserId({ data: requestData, token });
            }
        } else if (userId) {
            const requestData = {
                spokesman_id: userId,
            };

            if (token) {
                props.requestGetJobListUserId({ data: requestData, token });
            }
        }
    }, [companyId]);

    useEffect(() => {
        const jobListUserIdData = props?.candidate?.GetJobListUserId;
       
        if (jobListUserIdData !== undefined) {
            setJobListLoader(true);
            if (jobListUserIdData.data.Status === "Success") {
                const jobListUserIdDetails = jobListUserIdData?.data?.Message || [];
                if (Array.isArray(jobListUserIdDetails) && jobListUserIdDetails.length === 0) {
                    setJobList([]);
                } else {
                    setJobList(jobListUserIdDetails);
                }
                setJobListLoader(false);
                props.candidate.GetJobListUserId = undefined;
            } else {
                setJobListLoader(false);
                props.candidate.GetJobListUserId = undefined;
            }
        }
    }, [props.candidate.GetJobListUserId]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatDate1 = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '';
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredJobList = jobList.filter(job => {
        const jobTitle = job?.job_title ? job.job_title.toLowerCase() : '';
        const creationDate = job?.ad_creation_date ? formatDate(job.ad_creation_date).toLowerCase() : '';
        const expirationDate = job?.ad_exp_date ? formatDate1(job.ad_exp_date).toLowerCase() : '';
        const query = searchQuery.toLowerCase();

        return (
            jobTitle.includes(query) ||
            creationDate.includes(query) ||
            expirationDate.includes(query)
        );
    });

    const redirectOnDetails = (id) => {
        navigate("/company/advertisement-details/" + id);
    }

    const redirectOnAdvertisementCreate = () => {
        navigate("/company/create-adv");
    }

    const redirectToCreaAnnuncio7_bis = () => {
        navigate("/company/CreaAnnuncio7_bis")
    }

    return (
        <>
            <Header />
            <div className="advertisement_listing">
                <div className="container">
                    <div className="advertisement_title">
                        <h3>Lista annunci inseriti</h3>
                    </div>
                    <div className="advertisement_listing_inner position-relative" style={{ minHeight: "calc(100vh - 280px)" }}>
                        {jobListLoader ? <div className="loader">
                            <div className='loader_inner'>
                                <img src={loaderLogo} />
                            </div>
                        </div> : (
                            <>
                                <div className="search_ads">
                                    <form>
                                        <div className="row g-4">
                                            <div className="form-group col-md-6 col-12">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Ricerca annunci"
                                                    id="search"
                                                    name="search"
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="advertisement_table table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Data di pubblicazione</th>
                                                <th scope="col">Titolo</th>
                                                <th scope="col">Candidature ricevute</th>
                                                <th scope="col">Scadenza annuncio</th>
                                                <th scope="col">Stato annuncio</th>
                                                <th scope="col">Sponsorizzato</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredJobList.length > 0 && filteredJobList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <p className="publication_date">{formatDate(item.ad_creation_date)}</p>
                                                    </td>
                                                    <td>
                                                        {item.draft_id !== null ?
                                                            (
                                                                <a className="title" onClick={() => redirectOnAdvertisementCreate()}>
                                                                    {item.job_title}
                                                                </a>
                                                            ) : (
                                                                <a className="title" onClick={() => redirectOnDetails(item.id)}>
                                                                    {item.job_title}
                                                                </a>
                                                            )}
                                                    </td>
                                                    <td> {item.candidates.length > 0 ? (
                                                        <p className="application_count">{item.candidates}</p>
                                                    )
                                                        : ""
                                                    }

                                                    </td>
                                                    <td>
                                                        <p className="announcement_deadline">{formatDate1(item.ad_exp_date)}</p>
                                                    </td>
                                                    <PermissionAdvertisment
                                                        item={item}
                                                    />
                                                    <td>
                                                        <div className="sponsored">
                                                            {item.is_sponsored === true ? (
                                                                <div className="sponsored">
                                                                    <p>Sì - in corso</p>
                                                                </div>
                                                            ) : item.is_sponsored === false && item.have_been_sponsored === false ? (
                                                                <div className="sponsored" onClick={redirectToCreaAnnuncio7_bis}>
                                                                    <p>Sponsorizza</p>
                                                                </div>
                                                            ) : item.is_sponsored === false && item.have_been_sponsored === true ? (
                                                                <div className="yes_no">
                                                                    <p>Sì - conclusa</p>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div className="renew" onClick={redirectToCreaAnnuncio7_bis}>
                                                                <p>Rinnova</p>
                                                            </div>
                                                            {item.tokens_spent !== null ? (
                                                                <div className="token">
                                                                    <p>{item.tokens_spent} Token spesi</p>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>

                        )}

                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

const mapStateToProps = (state) => ({
    company: state.company, candidate: state.candidate,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    requestCompanyDetails,
    requestGetJobListUserId
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementList);

