import React from 'react';
import Select from "react-select";

function Driverlicense(props) {
    const {
        driverlicenseData,
        selectedOptions,
        handleChangeDriver,
        validateDriverlicense,
        driverLicenseRef,
        errorDriverlicense,
        mystyle,
        t,
    } = props;

    const isClearable = true;

    return (
        <div className="registration_col form-group col-md-6 col-12">
            <label htmlFor="driver_license" className="label">
                {t("register.driver_liences")}
            </label>
            <Select
                className={`form-control set_max_width select_dropdown ${errorDriverlicense ? "error" : ""
                    }`}
                options={driverlicenseData}
                value={selectedOptions || ""}
                onChange={handleChangeDriver}
                onBlur={validateDriverlicense}
                ref={driverLicenseRef}
                placeholder={t("register.select_driver_liences")}
                isMulti
                isClearable={isClearable}
            />
            {errorDriverlicense && (
                <div style={mystyle}>{errorDriverlicense}</div>
            )}
        </div>
    )
}

export default Driverlicense