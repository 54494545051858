import React from 'react';
import PhoneInput from "react-phone-input-2";
import it from "react-phone-input-2/lang/it.json";

function Cellphone(props) {
    const {
        data,
        phonRef,
        handlePhoneChange,
        validatePhone,
        errorphone,
        mystyle,
        t,
    } = props;
    return (
        <div className="registration_col form-group col-md-6 col-12">
            <label htmlFor="phone" className="label">
                {/* Numero cellulare */}
                {t("register.cellphone")}
            </label>
            <PhoneInput
                className={`form-control set_max_width numero_cellulare ${errorphone ? "error" : ""
                    }`}
                country={"it"}
                localization={it}
                value={data?.phone || ""}
                ref={phonRef}
                placeholder={t("register.cellphone")}
                onChange={(e) => handlePhoneChange(e)}
                onBlur={validatePhone}
                enableLongNumbers={18}
            />
            {errorphone && <div style={mystyle}>{errorphone}</div>}
        </div>
    )
}

export default Cellphone