const languageOptions = [
    { value: 1, label: 'Inglese' },
    { value: 2, label: 'Francese' },
    { value: 3, label: 'Spagnolo' },
    { value: 4, label: 'Portoghese' },
    { value: 5, label: 'Tedesco' },
    { value: 6, label: 'Russo' },
    { value: 7, label: 'Arabo' },
    { value: 8, label: 'Cinese' }
];
export default languageOptions;