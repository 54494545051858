import React from 'react';
import CreatableSelect from 'react-select/creatable';


function EducationLevel(props) {
    const {
        i,
        x,
        validateEducationLevel,
        // validateSubEducationLevel,
        selectedOptionsEducation,
        focusRefs,
        erroreducation,

        //============sub memu===================

        handleSubsubmenuChange,
        mystyle,
        t,
        handleChangeEducationLevel,
        educationLevels,
        subSubmenuOptions,

    } = props

    const isClearable = true;

    const selectedSubmenuOption = subSubmenuOptions.filter(
        option => option.levelType === selectedOptionsEducation[i]?.submenu
    );

    const hasSubSubmenuOptions = selectedSubmenuOption && selectedSubmenuOption?.length > 0;

    return (
        <>
            <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                <div className="form-group">
                    <label htmlFor="educationalLevel">{t('education.Education_level')}</label>
                    <CreatableSelect
                        className={`form-control select_dropdown ${erroreducation[i].educationalLevel ? 'error' : ''}`}
                        options={educationLevels}
                        defaultValue={x.educationalLevel && x.educationalLevel ? { label: `${x.educationalLevel}` } : ""}
                        onChange={(selectedOption) => handleChangeEducationLevel(selectedOption, i)}
                        ref={(el) => {
                            focusRefs.education.current[i] = focusRefs.education.current[i] || {};
                            focusRefs.education.current[i].educationalLevel = el;
                        }}
                        placeholder="Seleziona o inserisci il tuo titolo di studio"
                        onBlur={() => validateEducationLevel(i)}
                        isClearable={isClearable}
                    />
                    {erroreducation[i].educationalLevel && (
                        <div style={mystyle}>
                            {erroreducation[i].educationalLevel}
                        </div>
                    )}
                </div>
            </div>

            {/* {selectedOptionsEducation[i]?.submenu &&
                submenuOptions.find(
                    (option) => option.value === selectedOptionsEducation[i].submenu
                )?.subSubmenuOptions.length > 0 && (
                    <>
                        <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                            <div className="form-group">
                                <label htmlFor="educationalLevel">Indirizzo / Specializzazione</label>
                                <select
                                    className={`form-control ${erroreducation[i]?.educationalLevel ? 'error' : ''}`}
                                    name="educationalLevel"
                                    id={"educationalLevel" + i}
                                    onChange={(e) => handleSubsubmenuChange(e, i)}
                                    value={selectedOptionsEducation[i]?.subSubmenu || ""}
                             
                                >
                                    <option value="">Indirizzo / Specializzazione</option>
                                    {submenuOptions
                                        .find(
                                            (option) =>
                                                option.value === selectedOptionsEducation[i]?.submenu
                                        )
                                        ?.subSubmenuOptions.map((subOption, index) => (
                                            <option key={index} value={subOption.value}>
                                                {subOption.label}
                                            </option>
                                        ))}

                                </select>
                             
                            </div>
                        </div>
                    </>
                )} */}

            {selectedOptionsEducation[i]?.submenu && hasSubSubmenuOptions && (
                <>
                    <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                        <div className="form-group">
                            <label htmlFor="educationalLevel">Indirizzo / Specializzazione</label>
                            <select
                                // className={`form-control ${erroreducation[i]?.subeducationalLevel ? 'error' : ''}`}
                                className='form-control'
                                name="educationalLevel"
                                id={"educationalLevel" + i}
                                onChange={(e) => handleSubsubmenuChange(e, i)}
                                value={selectedOptionsEducation[i]?.subSubmenu || ""}
                                // onBlur={() => validateSubEducationLevel(i)}

                                // ref={(el) => {
                                //     focusRefs.education.current[i] = focusRefs.education.current[i] || {};
                                //     focusRefs.education.current[i].subeducationalLevel = el;
                                // }}
                            >
                                <option value="">Indirizzo / Specializzazione</option>
                                {selectedSubmenuOption?.map((subOption, index) => (
                                    <option key={index} value={subOption.specialisation}>
                                        {subOption.specialisation}
                                    </option>
                                ))}

                            </select>
                            {/* {erroreducation[i].subeducationalLevel && (
                                <div style={mystyle}>
                                    {erroreducation[i].subeducationalLevel}
                                </div>
                            )} */}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default EducationLevel