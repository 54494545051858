import { combineReducers } from "redux";
import candidateReducer from "./candidateReducer";
import companyReducer from "./companyReducer";
import agentReducer from "./agentReducer";
import franchisorReducer from "./franchisorReducer";
import { USER_LOGOUT } from "./actions";
const appReducer = combineReducers({
  candidate: candidateReducer,
  company: companyReducer,
  agent: agentReducer,
  franchisor: franchisorReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    localStorage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
