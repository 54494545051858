import React from 'react';


function FiscalCode(props) {
    const {
        data,
        fiscalCodeRef,
        handleFiscalCodeChange,
        validateFiscalCode,
        errorfiscal_code,
        mystyle,
        t,
    } = props;

    return (
        <div className="registration_col form-group col-md-6 col-12">
            <label htmlFor="fiscal_code" className="label">
                {/* Codice Fiscale */}
                {t("register.fiscal_code")}
            </label>
            <input
                type="text"
                className={`form-control set_max_width ${errorfiscal_code ? "error" : ""
                    }`}
                placeholder={t("register.fiscal_code")}
                id="fiscal_code"
                name="fiscal_code"
                ref={fiscalCodeRef}
                value={data.fiscal_code || ""}
                onChange={handleFiscalCodeChange}
                onBlur={validateFiscalCode}
            />
            {errorfiscal_code && (
                <div style={mystyle}>{errorfiscal_code}</div>
            )}
        </div>
    )
}

export default FiscalCode