import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestGetCandidate, userLogout } from "../../Redux/actions";
import Facebook from "../../images/facebook.png";
import Google from "../../images/google.png";
import LinkedIn from "../../images/LinkedIn.png";
import Logo from "../../images/logo.png";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";
import { toast } from "react-toastify";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { useNavigateToRegisterDestination } from "../../utils/routing";

function SocialRegister(props) {
  const { setShowModal } = props;
  const navigate = useNavigate();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const navigateToRegisterDestination = useNavigateToRegisterDestination();

  const redirectToRegister = () => {
    if (!isCheckboxChecked) {
      toast.error("Please accept the privacy policy to continue", {
        theme: "colored",
        autoClose: 1000,
      });
      return;
    } else {
      // navigate('/register');
      //   navigate("/candidate/register");
      navigateToRegisterDestination();
    }
  };

  const redirectToLogin = () => {
    setShowModal("login");
  };

  const handleGoogleRegister = async () => {
    if (!isCheckboxChecked) {
      toast.error("Please accept the privacy policy to continue", {
        theme: "colored",
        autoClose: 1000,
      });
      return;
    }

    try {
      const googleProvider = new GoogleAuthProvider();
      const res = await signInWithPopup(auth, googleProvider);

      if (res) {
        const email = res.user?.email;
        if (email) {
          await props.requestGetCandidate({ data: { username: email } });

          let candidateDetails;
          for (let i = 0; i < 5; i++) {
            candidateDetails = props.candidate.GetCandidateData;
            if (candidateDetails !== undefined) break;
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }

          if (candidateDetails) {
            if (candidateDetails?.data?.Status === "Success") {
              const updateData = candidateDetails.data.Message[0] || {};

              if (updateData?.username) {
                toast.error("User Already Exists", {
                  theme: "colored",
                  autoClose: 1000,
                });
              }
            } else {
              toast.success("Registered successfully", {
                theme: "colored",
                autoClose: 1000,
              });
              navigate("/register", { state: { email } });
            }
          } else {
            toast.error("Failed to retrieve user details", {
              theme: "colored",
              autoClose: 1000,
            });
          }
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      toast.error("Failed to register with Google", {
        theme: "colored",
        autoClose: 1000,
      });
      console.error("Error during Google registration:", error);
    }
  };

  const handleFaceBookRegister = async () => {
    if (!isCheckboxChecked) {
      toast.error("Please accept the privacy policy to continue", {
        theme: "colored",
        autoClose: 1000,
      });
      return;
    }

    try {
      const facebookProvider = new FacebookAuthProvider();
      const res = await signInWithPopup(auth, facebookProvider);
      if (res) {
        toast.success("Logged in successfully", {
          theme: "colored",
          autoClose: 1000,
        });
        navigate("/register");
      } else {
        navigate("/");
      }
    } catch (error) {
      toast.error("Failed to register with Facebook", {
        theme: "colored",
        autoClose: 1000,
      });
      console.log("Error during Facebook registration:", error);
      navigate("/");
    }
  };

  const handleLinkedInLogin = () => {
    if (!isCheckboxChecked) {
      toast.error("Please accept the privacy policy to continue", {
        theme: "colored",
        autoClose: 1000,
      });
      return;
    }

    linkedInLogin();
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "77pfd64ztjectx",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      console.log("LinkedIn login success code:", code);
      navigate("/register");
    },
    onError: (error) => {
      console.log("LinkedIn login error:", error);
      navigate("/");
    },
  });

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  return (
    <div className="registration_social">
      <div className="registration_social_inner">
        <div className="main_logo">
          <Link to="/">
            <img src={Logo} alt="Main Logo" />
          </Link>
        </div>

        <div className="registration_social_title">
          <h1>La nuova dimensione del lavoro </h1>
          <p>Registrati per accedere alla tua dimensione </p>
        </div>

        <div className="registration_btn">
          <button
            type="submit"
            className="btn registration_btn_inner"
            onClick={redirectToRegister}
            // disabled={!isCheckboxChecked}
          >
            Registrati con l’email
          </button>
        </div>

        <div className="registration_social_btn">
          <button
            type="submit"
            className="btn social_registration_btn"
            onClick={handleFaceBookRegister}
            // disabled={!isCheckboxChecked}
          >
            <img src={Facebook} alt="Facebook" />
            Registrati con Facebook
          </button>
          <button
            type="submit"
            className="btn social_registration_btn"
            onClick={handleGoogleRegister}
            // disabled={!isCheckboxChecked}
          >
            <img src={Google} alt="Google" />
            Registrati con Google
          </button>
          <button
            type="submit"
            className="btn social_registration_btn"
            onClick={handleLinkedInLogin}
            // disabled={!isCheckboxChecked}
          >
            <img src={LinkedIn} alt="LinkedIn" />
            Registrati con LinkedIn
          </button>
        </div>

        <div className="selection_checkboxs">
          <div className="selection_checkbox">
            <input
              type="checkbox"
              id="e_mail_consent"
              name="e_mail_consent"
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
            />
            <label>
              Accetto i
              <a
                href="https://www.iubenda.com/privacy-policy/51986058"
                target="_blank"
                rel="noopener noreferrer"
              >
                Termini di servizio
              </a>
              <br />e confermo di aver letto l’
              <a
                href="https://www.iubenda.com/privacy-policy/51986058/cookie-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Informativa Privacy.
              </a>
            </label>
          </div>
        </div>

        <div className="terms_privacy">
          <p>
            Sei già registrato?&nbsp;
            <a onClick={redirectToLogin}>Accedi</a>
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestGetCandidate,
      userLogout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SocialRegister);
