import React from 'react';
import CountrySelect from './CountrySelect';
import StreetField from './StreetField';
import ProvinceCommue from './ProvinceCommue';
import ZipCode from './ZipCode';

function LocationForm(props) {
    const {
        data,
        //===========Country=========================

        countryRef,
        onChangeData,
        validateCountry,
        sortedCountryData,
        errorCountry,
        
        //===========Street=========================

        validateStreetNumber,
        streetNumberRef,
        errorStreetnumber,

        //=============ProvinceCommue===============
        
        municipalities,
        selectedCity,
        isLoading,
        isClearable,
        handleChangeCity,
        isSearchable,
        cityRef,
        validateCity,
        filterConfig,
        errorCity,
        provinceRef,
        validateProvince,
        errorProvince,

        //=======ZipCode=====================

        zipCodeRef,
        validateZipCode,
        errorZipcode,

        mystyle,
        t,
    } = props;
    return (
        <div className="row registration_row g-4">
            <CountrySelect
                data={data}
                countryRef={countryRef}
                onChangeData={onChangeData}
                validateCountry={validateCountry}
                sortedCountryData={sortedCountryData}
                errorCountry={errorCountry}
                mystyle={mystyle}
                t={t}
            />
            <StreetField
                data={data}
                validateStreetNumber={validateStreetNumber}
                onChangeData={onChangeData}
                streetNumberRef={streetNumberRef}
                errorStreetnumber={errorStreetnumber}
                mystyle={mystyle}
                t={t}
            />

            <ProvinceCommue
                data={data}
                municipalities={municipalities}
                selectedCity={selectedCity}
                isLoading={isLoading}
                isClearable={isClearable}
                handleChangeCity={handleChangeCity}
                isSearchable={isSearchable}
                cityRef={cityRef}
                validateCity={validateCity}
                filterConfig={filterConfig}
                errorCity={errorCity}
                onChangeData={onChangeData}
                provinceRef={provinceRef}
                validateProvince={validateProvince}
                errorProvince={errorProvince}
                mystyle={mystyle}
                t={t}
            />
            <ZipCode
                data={data}
                zipCodeRef={zipCodeRef}
                onChangeData={onChangeData}
                validateZipCode={validateZipCode}
                errorZipcode={errorZipcode}
                mystyle={mystyle}
                t={t}
            />
        </div>
    )
}

export default LocationForm