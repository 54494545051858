import React from 'react'

function BackButton(props) {
    const { prevStep } = props;
    return (
        <div
            className="btn btn_default btn-prev btn_orange_outline"
            onClick={prevStep}
        >
            <svg
                width="15"
                height="11"
                viewBox="0 0 15 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.707 10.7072C6.51947 10.8947 6.26516 11 6 11C5.73484 11 5.48053 10.8947 5.293 10.7072L0.793 6.20721C0.605529 6.01969 0.500214 5.76538 0.500214 5.50021C0.500214 5.23505 0.605529 4.98074 0.793 4.79321L5.293 0.293214C5.4816 0.111055 5.7342 0.0102615 5.9964 0.0125399C6.2586 0.0148182 6.50941 0.119987 6.69482 0.305395C6.88023 0.490803 6.9854 0.741616 6.98767 1.00381C6.98995 1.26601 6.88916 1.51861 6.707 1.70721L4 4.50021L13.5 4.50021C13.7652 4.50021 14.0196 4.60557 14.2071 4.79311C14.3946 4.98064 14.5 5.235 14.5 5.50021C14.5 5.76543 14.3946 6.01978 14.2071 6.20732C14.0196 6.39486 13.7652 6.50021 13.5 6.50021L4 6.50021L6.707 9.29321C6.89447 9.48074 6.99979 9.73505 6.99979 10.0002C6.99979 10.2654 6.89447 10.5197 6.707 10.7072Z"
                    fill="#E75420"
                />
            </svg>
            Indietro
        </div>
    )
}

export default BackButton