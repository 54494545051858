import React from 'react';
import CreatableSelect from "react-select/creatable";

function SkillSelect(props) {
    const {
        skillData,
        selectedSkills,
        handleChangeSkill,
        handleCreateSkillOption,
        validateSkill,
        isClearable,
        errorskill,
        mystyle,
        skillRef,
        t,
    } = props;

    return (
        <div className="col-lg-12 col-md-6" style={{ padding: "5px" }}>
            <div className="form-group">
                <CreatableSelect
                    className={`form-control select_dropdown ${errorskill ? "error" : ""
                        }`}
                    options={skillData}
                    value={selectedSkills}
                    onChange={handleChangeSkill}
                    onCreateOption={handleCreateSkillOption}
                    onBlur={validateSkill}
                    ref={skillRef}
                    placeholder={t("skills.select_Content")}
                    isMulti
                    isClearable={isClearable}
                />
                {errorskill && <div style={mystyle}>{errorskill}</div>}
            </div>
        </div>
    )
}

export default SkillSelect