import React, { useEffect } from "react";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import EducationalLevel from "./EducationalLevel";
import CreatTwoDriverlicense from "./CreatTwoDriverlicense";
import NextButton from "../common/NextButton";
import BackButton from "../common/BackButton";

const CreaAnnuncioTwo = (props) => {
    const {
        nextStep,
        prevStep,
        CreatAd_Two,
        errorCreatAd_Two,
        setCreatAd_Two,
        setErrorCreatAd_Two,
        educationLevels,
        subSubmenuOptions,
        driverlicenseData,
        handleOnCreatAd_Two,
        validateDateTwo,
        isClearable,
        mystyle,
        t,
        selectedOptionsEducation,
        educationLevelRef,
        validateEducationLevel,
        handleOnCreatAdTwoEducationLevel,
        handleCreatAdTwoSubEducation,
        validateSubEducationLevel,
        subEducationalRef,
        jobStartingDateRef,
        isLoading,
        handleCreatTwoDriver,
        validateCreateTwoDriverlicense,
        driverLicenseRef,
        selectedOptionsDrivelicense,
        error,
        setError
    } = props;

    useEffect(() => {
        const focusField = () => {
            if (errorCreatAd_Two.educationLevels && educationLevelRef?.current) {
                educationLevelRef.current.focus();
            } else if (errorCreatAd_Two.driverlicense && driverLicenseRef?.current) {
                driverLicenseRef.current.focus();
            } else if (errorCreatAd_Two.job_starting_date && jobStartingDateRef?.current) {
                jobStartingDateRef.current.focus();
            }
        };

        if (error) {
            focusField();
            setError(false);
        }
    }, [
        error,
        errorCreatAd_Two,
        educationLevelRef,
        jobStartingDateRef
    ]);


    return (
        <>
            <Header />
            <div className="seekng_job_sec create-an-ad">
                <div className="container">
                    <form>
                        <div className="seekng_job_title_note">
                            <div className="seekng_job_title">
                                <h2>
                                    Inserisci un nuovo annuncio per la ricerca di dipendenti
                                </h2>
                                <h6>Richieste e inizio lavoro</h6>
                            </div>
                        </div>
                        <div className="seeking_job_inner">
                            <div className="seeking_job_form">
                                <div className="seeking_form_left">
                                    <div className="row g-3">
                                        <EducationalLevel
                                            errorCreatAd_Two={errorCreatAd_Two}
                                            selectedOptionsEducation={selectedOptionsEducation}
                                            educationLevels={educationLevels}
                                            subSubmenuOptions={subSubmenuOptions}
                                            educationLevelRef={educationLevelRef}
                                            validateEducationLevel={validateEducationLevel}
                                            handleOnCreatAdTwoEducationLevel={handleOnCreatAdTwoEducationLevel}
                                            handleCreatAdTwoSubEducation={handleCreatAdTwoSubEducation}
                                            validateSubEducationLevel={validateSubEducationLevel}
                                            subEducationalRef={subEducationalRef}
                                            isLoading={isLoading}
                                            isClearable={isClearable}
                                            mystyle={mystyle}
                                            t={t}
                                        />

                                        <CreatTwoDriverlicense
                                            driverlicenseData={driverlicenseData}
                                            selectedOptionsDrivelicense={selectedOptionsDrivelicense}
                                            handleCreatTwoDriver={handleCreatTwoDriver}
                                            validateCreateTwoDriverlicense={validateCreateTwoDriverlicense}
                                            driverLicenseRef={driverLicenseRef}
                                            errorCreatAd_Two={errorCreatAd_Two}
                                            isClearable={isClearable}
                                            mystyle={mystyle}
                                            t={t}
                                        />
                                    </div>
                                </div>
                                <div className="seeking_form_right"></div>
                                <div className="seeking_form_left mt-30">
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <label className="form-label">
                                                E’ già stata fissata una data di inizio per la posizione
                                                lavorativa?
                                            </label>
                                            <div className="switch-btn-wrap">
                                                <strong>No</strong>
                                                <div className="switch-btn"
                                                    onClick={() => {
                                                        setCreatAd_Two((prevstate) => ({
                                                            ...prevstate,
                                                            isStartDateVisible:
                                                                !CreatAd_Two?.isStartDateVisible,
                                                        }));
                                                        CreatAd_Two?.isStartDateVisible === true &&
                                                            setErrorCreatAd_Two((preverr) => ({
                                                                ...preverr,
                                                                isStartDateVisible: "",
                                                            }));
                                                        if (CreatAd_Two?.isStartDateVisible === false) {
                                                            setCreatAd_Two((prevstate) => ({
                                                                ...prevstate,
                                                                job_starting_date: "",
                                                            }));
                                                        }
                                                    }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={CreatAd_Two?.isStartDateVisible}
                                                        readOnly
                                                    />
                                                    <span></span>
                                                </div>
                                                <strong>Si</strong>
                                            </div>
                                        </div>
                                        {CreatAd_Two.isStartDateVisible === false &&
                                            errorCreatAd_Two?.isStartDateVisible && (
                                                <div style={mystyle}>
                                                    {errorCreatAd_Two.isStartDateVisible}
                                                </div>
                                            )}
                                    </div>
                                </div>

                                <div className="seeking_form_right mt-30">
                                    <div className="row g-3">
                                        <div className="col-12"
                                            style={{
                                                visibility: `${CreatAd_Two.isStartDateVisible === true
                                                    ? "visible"
                                                    : "hidden"
                                                    }`,
                                            }}
                                        >
                                            <label className="form-label">
                                                Data di inizio lavoro
                                            </label>
                                            <input
                                                className={`form-control w-auto ${errorCreatAd_Two.job_starting_date ? "error" : ""
                                                    }`}
                                                type="date"
                                                name="job_starting_date"
                                                id="birth_date"
                                                value={CreatAd_Two?.job_starting_date || ""}
                                                onChange={handleOnCreatAd_Two}
                                                ref={jobStartingDateRef}
                                                onBlur={validateDateTwo}
                                                placeholder={t("register.birth_date")}
                                            />
                                            {errorCreatAd_Two.job_starting_date && (
                                                <div style={mystyle}>
                                                    {errorCreatAd_Two.job_starting_date}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="seeking_form_btn">
                            <BackButton
                                prevStep={prevStep}
                            />
                            <NextButton
                                nextStep={nextStep}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CreaAnnuncioTwo;
