import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  requestCompanyDetails,
  requestCompanyRegister,
  requestCompanyUpdate,
  requestGetList,
  requestGetSectorList,
  requestRegister,
} from "../../Redux/actions";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumbs from "../../Components/Breadcrumbs";
import Select from "react-select";
import { useAppNavigation } from "../../utils/routing";
import PhoneInput from "react-phone-input-2";
import it from "react-phone-input-2/lang/it.json";

function Register(props) {
  const [formSettings, setFormSettings] = useState({
    company_name: {
      label: "Nome Azienda",
      type: "text",
    },
    franchisor_brand: {
      label: "Marchio franchisor",
      type: "text",
    },
    contact_name: {
      label: "Nome e cognome referente",
      type: "text",
    },
    phone: {
      label: "Numero telefono",
      type: "phone",
    },
    sector: {
      label: "Settore del franchise",
      type: "select",
      options: [],
    },
    sub_sector: {
      label: "Sottosettore",
      type: "select",
      options: [],
    },
  });

  const [data, setData] = useState();

  const { navigateTo } = useAppNavigation();

  const formRef = useRef();

  const handleSubmit = () => {
    let path = "/company/register";
    if (props.franchisor?.loginData?.flag_franchisor) {
      formRef.current.dispatchEvent(new Event("submit", { bubbles: true }));
      path = "/company/profile";
    }
    localStorage.setItem("registerFranchisorData", JSON.stringify(data));
    navigateTo(path, {
      registerFranchisorData: data,
    });
  };

  useEffect(() => {
    props?.requestGetSectorList();
  }, []);

  useEffect(() => {
    const accessToken = props.franchisor?.loginData?.data.Message.access;
    props.franchisor?.requestCompanyDetails(accessToken);
  }, [props.franchisor?.loginData]);

  useEffect(() => {
    if (props.franchisor?.getCompanyData) {
      setData(props.franchisor?.getCompanyData);
    }
  }, [props.franchisor?.getCompanyData]);

  useEffect(() => {
    if (props.franchisor?.SectorData || props.candidate?.SectorData) {
      let options =
        props.franchisor?.SectorData.data.Message ||
        props.candidate?.SectorData.data.Message;

      options = options.reduce((acc, curr) => {
        const existingSector = acc.find((o) => o.label === curr.sectorName);

        if (existingSector && curr.subSector) {
          existingSector.subOptions.push({
            label: curr.subSector,
            value: curr.id,
          });
        } else {
          let subOptions = [];
          if (curr.subSector) {
            subOptions.push({
              label: curr.subSector,
              value: curr.id,
            });
          }
          acc.push({
            label: curr.sectorName,
            value: curr.id,
            subOptions,
          });
        }

        return acc;
      }, []);

      setFormSettings((p) => {
        const newSettings = { ...p };

        newSettings["sector"].options = options;

        return newSettings;
      });
    }
  }, [props.franchisor?.SectorData, props.candidate?.SectorData]);

  const onFormSubmit = (e) => {
    e.preventDefault();

    const formData = {};
    const formElements = e.target.elements;

    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];

      if (!element.name?.length) continue;

      let minElLength = 1;

      if (element.type === "tel") minElLength = 11;

      if (element.value?.length > minElLength) {
        formData[element.name] = element.value;
      } else {
        element.focus();

        const container =
          element.parentElement?.parentElement?.parentElement?.parentElement;

        if (!container.parentElement.querySelector(".error-message")) {
          container.classList.add("error");
          const errorMessage = document.createElement("p");
          errorMessage.textContent = "Questo campo è obbligatorio.";
          errorMessage.style.color = "#D10000";
          errorMessage.style.marginTop = "4px";
          errorMessage.classList.add("error-message");
          container.parentElement?.appendChild(errorMessage);
        }
      }
    }

    if (
      !Object.entries(formData ?? {}).filter(([key, _]) => !key?.length)
        .length < Object.entries(formSettings ?? {}).length
    )
      return;

    if (Object.values(formData).every(Boolean)) {
      let fn = props.requestCompanyUpdate;
      let path = "/company/register";

      if (!props.franchisor?.getCompanyData) {
        fn = props.requestCompanyRegister;
        path = "/company/profile";
      }

      fn(formData);

      navigateTo(path, {
        registerFranchisorData: formData,
      });
    }
  };

  return (
    <>
      <Header />
      <Breadcrumbs />
      <div className="container">
        <div>
          <div className="seekng_job_title mb-4">
            <h2>
              {props.franchisor?.loginData
                ? "Il tuo profilo "
                : "Registrati come "}{" "}
              franchisor
            </h2>
          </div>
          <form
            ref={formRef}
            onSubmit={onFormSubmit}
            className="seeking_job_inner"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "2rem",
            }}
          >
            {Object.entries(formSettings).map(([name, setting], i) => {
              switch (setting.type) {
                case "text":
                  // HAHAHAHALOL
                  return (
                    <div key={i}>
                      <div>
                        <div>
                          <div>
                            <div className="form-group-text">
                              <label htmlFor={name} className="label">
                                {setting.label}
                              </label>
                              <input
                                type={setting.type}
                                className={`form-control set_max_width`}
                                placeholder={setting.label}
                                name={name}
                                value={data?.[name] || ""}
                                onChange={(e) =>
                                  setData((p) => ({
                                    ...p,
                                    [name]: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                case "phone":
                  return (
                    <div key={i}>
                      <div>
                        <div>
                          <div>
                            <label htmlFor={name} className="label">
                              {setting.label}
                            </label>
                            <PhoneInput
                              className={`form-control set_max_width numero_cellulare`}
                              country={"it"}
                              localization={it}
                              inputProps={{
                                name,
                              }}
                              value={data?.[name] || ""}
                              placeholder={setting.label}
                              onChange={(value) =>
                                setData((p) => ({
                                  ...p,
                                  [name]: value,
                                }))
                              }
                              enableLongNumbers={18}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                case "select":
                  return (
                    <div className="registration_col form-group col-12" key={i}>
                      <label>{setting.label}</label>
                      <Select
                        name={name}
                        className={`form-control set_max_width select_dropdown`}
                        options={setting.options}
                        value={data?.[name]}
                        onChange={(e) => {
                          setData((p) => ({ ...p, [name]: e }));
                          if (name === "sector") {
                            setFormSettings((p) => ({
                              ...p,
                              sub_sector: {
                                ...p.sub_sector,
                                options: e?.subOptions ?? [],
                              },
                            }));
                          }
                        }}
                        placeholder={"Seleziona un'opzione"}
                        isMulti={setting.isMulti}
                        isClearable={true}
                      />
                    </div>
                  );
                default:
                  return <></>;
              }
            })}
          </form>
          <div className="seeking_form_btn d-flex mt-4">
            <div
              onClick={handleSubmit}
              style={{ marginLeft: "auto" }}
              className="btn btn_default btn_orange_outline"
            >
              Avanti
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return { franchisor: state.francisor, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestRegister,
      requestGetSectorList,
      requestCompanyRegister,
      requestCompanyUpdate,
      requestCompanyDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Register);
