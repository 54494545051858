import React, { useEffect } from "react";
import MonthYearsEdu from "./MonthYearsEdu";
import LocationEdu from "./LocationEdu";
import RightArrow from "../../../images/right_green_arrow.png";
import EducationLevel from "./EducationLevel";

function EducationForm(props) {
    const {
        nextStep,
        education,
        setEducation,
        erroreducation,
        sortedCountryData,
        municipalities,
        validateEducationLevel,
        // validateSubEducationLevel,
        // validateEducationStudysector,
        validateEducationInstituteName,
        validateEducationYearFrom,
        validateEducationYearTo,
        validateEducationCountry,
        validateEducationCommon,
        validateEducationProvince,
        handleInputEducation,
        handleSubsubmenuChange,
        handleRemoveEducation,
        handleChangeEducationCity,
        handleAddEducation,
        FontAwesomeIcon,
        faTrashCan,
        isLoading,
        isClearable,
        isSearchable,
        filterConfig,
        mystyle,
        focusRefs,
        error,
        setError,
        showNavButtons,
        selectedOptionsEducation,
        t,
        handleChangeEducationLevel,
        educationLevels,
        subSubmenuOptions,

    } = props

    useEffect(() => {
        if (error && erroreducation) {
            const focusField = () => {
                for (let i = 0; i < erroreducation.length; i++) {
                    const eduError = erroreducation[i];
                    if (eduError.educationalLevel) {
                        focusRefs.education.current[i]?.educationalLevel?.focus();
                        break;
                    } else if (eduError.educationalSector) {
                        focusRefs.education.current[i]?.educationalSector?.focus();
                        break;
                    } else if (eduError.type) {
                        focusRefs.education.current[i]?.type?.focus();
                        break;
                    } else if (eduError.name) {
                        focusRefs.education.current[i]?.name?.focus();
                        break;
                    } else if (eduError.yearFrom) {
                        focusRefs.education.current[i]?.yearFromedu?.focus();
                        break;
                    } else if (eduError.yearTo) {
                        focusRefs.education.current[i]?.yearToedu?.focus();
                        break;
                    } else if (eduError.address?.country) {
                        focusRefs.education.current[i]?.country_education?.focus();
                        break;
                    } else if (eduError.address?.city) {
                        focusRefs.education.current[i]?.common_education?.focus();
                        break;
                    } else if (eduError.address?.province) {
                        focusRefs.education.current[i]?.province_education?.focus();
                        break;
                    }
                }
            };
            focusField();
            setError(false);
        }
    }, [error, erroreducation, focusRefs, education]);

    useEffect(() => {
        if (education.length === 0) {
            localStorage.removeItem('education')
            localStorage.removeItem('educationalLevel')
        }
    }, [selectedOptionsEducation])


    return (
        <>
            <div className="resume-inner-content">
                <div className="single-section education">
                    <div className="single-section-title-add">
                        <h4 className="single-section-title">
                            {t('education.title')}
                            <span style={{ color: "red" }}>*</span>
                        </h4>
                    </div>
                    <>
                        {education.map((x, i) => {
                            return (
                                <div key={i}
                                    className="row single-section-form"
                                >
                                    <div
                                        className="col-lg-12 col-md-12"
                                        style={{ padding: "5px" }}
                                    >
                                        <div className="inner-title-btn">
                                            <div className="inner-title">
                                                <h6>{t('education.title')} : {i + 1}</h6>
                                            </div>
                                            <div className="inner-btn">
                                                <button
                                                    onClick={() => handleRemoveEducation(i)}
                                                >
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <EducationLevel
                                        i={i}
                                        x={x}
                                        handleInputEducation={handleInputEducation}
                                        validateEducationLevel={validateEducationLevel}
                                        // validateSubEducationLevel={validateSubEducationLevel}
                                        selectedOptionsEducation={selectedOptionsEducation}
                                        focusRefs={focusRefs}
                                        erroreducation={erroreducation}

                                        handleSubsubmenuChange={handleSubsubmenuChange}
                                        mystyle={mystyle}
                                        t={t}
                                        handleChangeEducationLevel={handleChangeEducationLevel}
                                        educationLevels={educationLevels}
                                        subSubmenuOptions={subSubmenuOptions}
                                    />

                                    <div
                                        className="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="educationalSector">{t('education.Study_Sector')}</label>
                                            <input
                                                className={`form-control ${erroreducation[i]?.educationalSector ? 'error' : ''}`}
                                                placeholder={t('education.Study_Sector')}
                                                type="text"
                                                name="educationalSector"
                                                id={"educationalSector" + i}
                                                ref={(el) => {
                                                    focusRefs.education.current[i] = focusRefs.education.current[i] || {};
                                                    focusRefs.education.current[i].educationalSector = el;
                                                }}
                                                value={x?.educationalSector || ''}
                                                // onBlur={() => validateEducationStudysector(i)}
                                                onChange={(e) => handleInputEducation(e, i)}
                                            />
                                            {erroreducation[i].educationalSector && (
                                                <div style={mystyle}>
                                                    {erroreducation[i].educationalSector}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        className="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="name">{t('education.Institute_name')}</label>
                                            <input
                                                className={`form-control ${erroreducation[i]?.name ? 'error' : ''}`}
                                                type="text"
                                                name="name"
                                                id={"name" + i}
                                                value={x?.name}
                                                ref={(el) => {
                                                    focusRefs.education.current[i] = focusRefs.education.current[i] || {};
                                                    focusRefs.education.current[i].name = el;
                                                }}
                                                onBlur={() => validateEducationInstituteName(i)}
                                                onChange={(e) => handleInputEducation(e, i)}
                                                placeholder={t('education.Institute_name')}
                                            />
                                            {erroreducation[i].name && (
                                                <div style={mystyle}>
                                                    {erroreducation[i].name}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {
                                        selectedOptionsEducation[i]?.submenu &&
                                            subSubmenuOptions.find(option => option.levelType === selectedOptionsEducation[i].submenu) &&
                                            subSubmenuOptions.length > 0 ? null : (
                                            <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                            </div>
                                        )
                                    }

                                    <MonthYearsEdu
                                        i={i}
                                        x={x}
                                        erroreducation={erroreducation}
                                        handleInputEducation={handleInputEducation}
                                        validateEducationYearFrom={validateEducationYearFrom}
                                        validateEducationYearTo={validateEducationYearTo}
                                        focusRefs={focusRefs}
                                        mystyle={mystyle}
                                        t={t}
                                    />

                                    <LocationEdu
                                        i={i}
                                        x={x}
                                        education={education}
                                        setEducation={setEducation}
                                        erroreducation={erroreducation}
                                        sortedCountryData={sortedCountryData}
                                        municipalities={municipalities}
                                        validateEducationCountry={validateEducationCountry}
                                        validateEducationCommon={validateEducationCommon}
                                        validateEducationProvince={validateEducationProvince}
                                        handleInputEducation={handleInputEducation}
                                        handleChangeEducationCity={handleChangeEducationCity}
                                        focusRefs={focusRefs}
                                        isLoading={isLoading}
                                        isClearable={isClearable}
                                        isSearchable={isSearchable}
                                        filterConfig={filterConfig}
                                        mystyle={mystyle}
                                        t={t}
                                    />
                                </div>
                            );
                        })}
                    </>
                    <div className="add-box-btn">
                        <button className="add-box-button" type="button"
                            onClick={handleAddEducation}
                        >
                            {t('education.button.save_eduction')}
                        </button>
                    </div>

                </div>
            </div>

            {showNavButtons && (
                <div className="back_next_btn">
                    <div className="back_btn">
                    </div>
                    <div className="next_btn">
                        <button
                            className="btn btn_default btn_green"
                            type="button"
                            onClick={nextStep}>
                            avanti
                            <img src={RightArrow} alt="Main Logo" />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default EducationForm;