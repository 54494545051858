import React, { useEffect } from 'react';

const CookieBanner = () => {

    useEffect(() => {
        const iubendaConfig = {
            askConsentAtCookiePolicyUpdate: true,
            floatingPreferencesButtonDisplay: 'bottom-right',
            perPurposeConsent: true,
            siteId: 3597411,
            whitelabel: false,
            cookiePolicyId: 74855481,
            lang: 'it',
            banner: {
                acceptButtonCaptionColor: '#FFFFFF',
                acceptButtonColor: '#0073CE',
                acceptButtonDisplay: true,
                backgroundColor: '#FFFFFF',
                closeButtonRejects: true,
                customizeButtonCaptionColor: '#4D4D4D',
                customizeButtonColor: '#DADADA',
                customizeButtonDisplay: true,
                explicitWithdrawal: true,
                listPurposes: true,
                position: 'float-bottom-center',
                showTitle: false,
                textColor: '#000000',
            },
        };

        const iubendaScript = document.createElement('script');
        iubendaScript.type = 'text/javascript';
        iubendaScript.innerHTML = `
      var _iub = _iub || [];
      _iub.csConfiguration = ${JSON.stringify(iubendaConfig)};
    `;
        document.body.appendChild(iubendaScript);

        const autoblockingScript = document.createElement('script');
        autoblockingScript.type = 'text/javascript';
        autoblockingScript.src = 'https://cs.iubenda.com/autoblocking/3597411.js';
        document.body.appendChild(autoblockingScript);

        const iubendaCsScript = document.createElement('script');
        iubendaCsScript.type = 'text/javascript';
        iubendaCsScript.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
        iubendaCsScript.charset = 'UTF-8';
        iubendaCsScript.async = true;
        document.body.appendChild(iubendaCsScript);

        return () => {
            document.body.removeChild(iubendaScript);
            document.body.removeChild(autoblockingScript);
            document.body.removeChild(iubendaCsScript);
        };
    }, []);

    // return <div id="iubenda-cs-banner"></div>;
    return null;
};

export default CookieBanner;