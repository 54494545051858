import React from 'react';
import Select, { createFilter } from "react-select";

function Benefits(props) {
    const {
        benefitsData,
        validateBenefit,
        handleCreateFiveBenefits,
        benefitRef,
        isClearable,
        isSearchable,
        selectedOptionsBenefits,
        errorCreatAd_Five,
        filterConfig,
        mystyle,
    } = props;
    return (
        <>
            <div className="seeking_form_left mt-4">
                <div className="row g-3">
                    <div className="col-12">
                        <label className="form-label">
                            Benefit (es. auto aziendale)
                        </label>
                        <Select
                            className={`form-control select_dropdown  ${errorCreatAd_Five?.benefit ? "error" : ""
                                }`}
                            options={benefitsData}
                            onBlur={validateBenefit}
                            value={selectedOptionsBenefits}
                            placeholder="Seleziona i benefit"
                            onChange={handleCreateFiveBenefits}
                            ref={benefitRef}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            isMulti
                            filterOption={createFilter(filterConfig)}
                        />
                        {errorCreatAd_Five?.benefit && (
                            <div style={mystyle}>
                                {errorCreatAd_Five.benefit}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="seeking_form_right mt-lg-4">
                <div className="row g-3">
                    <div className="col-12">
                        <label className="d-none d-lg-block">&nbsp;</label>
                        <div className="selected-tag orange d-flex flex-wrap gap-3">
                            {selectedOptionsBenefits?.length > 0 &&
                                selectedOptionsBenefits?.map((item, index) => (
                                    <span key={index}>
                                        {item.label}
                                    </span>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Benefits