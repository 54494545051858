import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";

const PrivateRoute = ({ children, params }) => {
  let auth = { token: localStorage?.getItem("token") ? true : false };

  const [currentParams] = useSearchParams();

  const searchParams = new URLSearchParams(currentParams);

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
  }

  return auth.token ? (
    children
  ) : (
    <Navigate
      to={`/?${searchParams.toString()}`}
      state={{ showModal: "login" }}
    />
  );
};

export default PrivateRoute;

//======================= session exipire ================================================

// import React, { useEffect, useState } from 'react';
// import { Outlet, Navigate } from 'react-router-dom';
// import { jwtDecode } from "jwt-decode";
// import { toast } from 'react-toastify';

// const PrivateRoutes = () => {
//   const [auth, setAuth] = useState(null);

//   useEffect(() => {
//     const token = localStorage.getItem('token');

//     if (token) {
//       try {
//         const decodedToken = jwtDecode(token);
//         const currentTime = Date.now() / 1000;

//         if (decodedToken.exp > currentTime) {
//           setAuth(true);
//         } else {
//           handleAuthFailure('Your session has expired. Please log in again.');
//         }
//       } catch (error) {
//         handleAuthFailure('Invalid token. Please log in again.');
//       }
//     } else {
//       setAuth(false);
//     }
//   }, []);

//   const handleAuthFailure = (message) => {
//     localStorage.removeItem('token');
//     localStorage.removeItem('username');
//     localStorage.removeItem('userType');
//     toast.error(message, { theme: 'colored', autoClose: 1000 });
//     setAuth(false);
//   };

//   if (auth === null) {
//     return <div>Loading...</div>;
//   }

//   return auth ? <Outlet /> : <Navigate to="/" state={{ showModal: 'login' }} />;
// };

// export default PrivateRoutes;

//======================= session exipire ================================================
