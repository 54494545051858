import React from "react";

//================ Basic Register, Candidate Register, Profile ===================// 

function OtherTerms({ data, handleCheckboxChange, t }) {

  return (
    <div className="selection_checkboxs row">
      <div className="selection_checkboxs_col col-md-6 col-12">
        <div className="selection_checkbox">
          <input
            type="checkbox"
            id="tel_visible"
            name="tel_visible"
            checked={data.tel_visible}
            onChange={handleCheckboxChange}
          />
          <label>
            <span>{t("register.basic.check_1")}</span>
          </label>
        </div>
        <div className="selection_checkbox">
          <input
            type="checkbox"
            id="tel_consent"
            name="tel_consent"
            checked={data.tel_consent}
            onChange={handleCheckboxChange}
          />
          <label>
            <span>{t("register.basic.check_2")}</span>
          </label>
        </div>
      </div>
      <div className="selection_checkboxs_col col-md-6 col-12">
        <div className="selection_checkbox">
          <input
            type="checkbox"
            id="e_mail_consent"
            name="e_mail_consent"
            checked={data.e_mail_consent}
            onChange={handleCheckboxChange}
          />
          <label>
            <span>{t("register.basic.check_3")}</span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default OtherTerms;