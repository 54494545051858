// File: /src/lib/axios.js
// Created Date: Friday September 13th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Friday September 13th 2024 6:13:10 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import axios from "axios";

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Referrer-Policy": "no-referrer",
  },
});
