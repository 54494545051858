import React, { useEffect, useState } from "react";
import StepForm from "./StepForm";
import Header from "../Header";
import Footer from "../Footer";
import { useLocation } from 'react-router-dom';

function ResumeForm() {
  const [data, setData] = useState({})
  const location = useLocation();

  useEffect(() => {
    const username = location?.state?.username;
    if (username) {
      setData(prevData => ({
        ...prevData,
        username: username
      }));
    }
  }, [location.state]);


  return (
    <>
      <Header />
      <div className="resume">
        <div className="container">
          <StepForm
            data={data}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResumeForm;
