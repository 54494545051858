import React, {useEffect} from 'react';
import Components from "./Components";
import mainSaga from "./Redux/sagas";
import { store, persistor, sagaMiddleware } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import 'react-quill/dist/quill.snow.css';

sagaMiddleware.run(mainSaga);

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  },[i18n])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Components />
      </PersistGate>
    </Provider>
  );
}

export default App;

