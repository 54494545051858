import React from 'react'

function LastNameField(props) {
    const {
        data,
        onChangeData,
        validateLname,
        lastNameRef,
        errorlast_name,
        mystyle,
        t,
    } = props
    return (
        <div className="registration_col form-group col-md-6 col-12">
            <label htmlFor="last_name" className="label">
                {/* Cognome */}
                {t("register.surname")}
            </label>
            <input
                type="text"
                className={`form-control set_max_width ${errorlast_name ? "error" : ""
                    }`}
                placeholder={t("register.surname")}
                id="last_name"
                name="last_name"
                ref={lastNameRef}
                value={data.last_name || ""}
                onChange={onChangeData}
                onBlur={validateLname}
            />
            {errorlast_name && <div style={mystyle}>{errorlast_name}</div>}
        </div>
    )
}

export default LastNameField