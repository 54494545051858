import React from 'react';

function FirstNameField(props) {
    const {
        data,
        onChangeData,
        validateFname,
        firstNameRef,
        errorfirst_name,
        mystyle,
        t,
    } = props;


    return (
        <div className="form-group col-md-10 col-12">
            <label htmlFor="first_name" className="label">
                {/* Nome */}
                {t("register.name")}
            </label>
            <input
                type="text"
                className={`form-control ${errorfirst_name ? "error" : ""
                    }`}
                placeholder={t("register.name")}
                id="first_name"
                name="first_name"
                ref={firstNameRef}
                value={data.first_name || ""}
                onChange={onChangeData}
                onBlur={validateFname}
            />
            {errorfirst_name && (
                <div style={mystyle}>{errorfirst_name}</div>
            )}
        </div>
    )
}

export default FirstNameField