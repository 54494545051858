import React, { useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import LeftArrow from "../../../images/left_orange_arrow.png";
import ResumeFile from "./ResumeFile";
import SkillSelect from "./SkillSelect";

function SkillForm(props) {
    const {
        prevStep,
        skillData,
        selectedSkills,
        handleChangeSkill,
        handleCreateSkillOption,
        validateSkill,
        isClearable,
        errorskill,
        mystyle,
        skillRef,
        error,
        setError,
        errorResume_upload,
        resumeUploadRef,
        validateResume_upload,
        handleChangeResume,
        handleDownloadPdf,
        t,
    } = props;

    useEffect(() => {
        const focusField = () => {
            if (error && errorskill) {
                if (skillRef.current) {
                    skillRef.current.focus();
                }
            }
        };

        focusField();
        setError(false);
    }, [error, errorskill, skillRef]);

    return (
        <>
            <div className="resume-inner-content">
                <div className="single-section education">
                    <div className="single-section-title-add">
                        <h4 className="single-section-title">
                            {t("skills.skill_title")}
                            <span style={{ color: "red" }}>*</span>
                        </h4>
                    </div>

                    <div className="row single-section-form bg-white border-0">
                        {/* <div className="col-lg-12 col-md-6" style={{ padding: "5px" }}>
                            <div className="form-group">
                                <CreatableSelect
                                    className={`form-control select_dropdown ${errorskill ? "error" : ""
                                        }`}
                                    options={skillData}
                                    value={selectedSkills}
                                    onChange={handleChangeSkill}
                                    onCreateOption={handleCreateSkillOption}
                                    onBlur={validateSkill}
                                    ref={skillRef}
                                    placeholder={t("skills.select_Content")}
                                    isMulti
                                    isClearable={isClearable}
                                />
                                {errorskill && <div style={mystyle}>{errorskill}</div>}
                            </div>
                        </div> */}
                        <SkillSelect
                            skillData={skillData}
                            selectedSkills={selectedSkills}
                            handleChangeSkill={handleChangeSkill}
                            handleCreateSkillOption={handleCreateSkillOption}
                            validateSkill={validateSkill}
                            isClearable={isClearable}
                            errorskill={errorskill}
                            mystyle={mystyle}
                            skillRef={skillRef}
                            t={t}
                        />
                        <div className="form-group col-lg-6 col-md-6">
                            <ResumeFile
                                errorResume_upload={errorResume_upload}
                                resumeUploadRef={resumeUploadRef}
                                validateResume_upload={validateResume_upload}
                                handleChangeResume={handleChangeResume}
                                mystyle={mystyle}
                            />
                        </div>
                        <div className="col-lg-6 col-md-6"></div>
                    </div>
                    <div className="curriculum_btn">
                        <button
                            className="btn btn_default btn_orange_outline"
                            type="button" onClick={handleDownloadPdf}>
                            Salva Curriculum
                        </button>
                    </div>
                </div>
            </div>
            <div className="back_next_btn">
                <div className="back_btn">
                    <button
                        className="btn btn_default btn_orange_outline"
                        type="button"
                        onClick={prevStep}>
                        <img src={LeftArrow} alt="Main Logo" />
                        Indietro
                    </button>
                </div>
            </div>
        </>
    );
}

export default SkillForm;
