// File: /src/Franchisor/auth/FranchisorAuth.js
// Created Date: Wednesday September 11th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Wednesday September 11th 2024 10:54:45 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

const FranchisorAuth = () => {
  return (
    <>
      <Header />
      <div className="registration_sec">
        <div className="container">
          <div className="registration_title">
            <h3>Registrati per offrire i tuoi servizi</h3>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FranchisorAuth;
