// import React, { useEffect, useState } from "react";
// import Header from '../../Components/Header';
// import Footer from '../../Components/Footer';
// import CompanyLogo from "../../../src/images/logo.png";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { requestGetJobDetails } from "../../Redux/actions";

// const PackageToken = (props) => {

//     return (
//         <>
//             <Header />
//             <div className="job_listing_sec package_token" style={{ maxHeight: "100%", overflow: "auto" }}>
//                 <div className="container">
//                     <h2 className='package_token_title mb-3'>Acquista un nuovo pacchetto di crediti</h2>
//                     <div className="job_listing_blocks">
//                         <div
//                             className="job_listing_block"
//                         >
//                             <a
//                                 className="redirect_a"
//                             >
//                                 <div className="job_listing_block_inner">
//                                     <div className="job_title">
//                                         <h2>Giambruno Cosacchi</h2>
//                                     </div>
//                                     <div className="logo_detailbtn">
//                                         <div className="company_logo">
//                                             <img src={CompanyLogo} alt="company logo" />
//                                         </div>
//                                     </div>
//                                     <div className="job_description">
//                                         <p>Presentazione se inserita, altrimenti vuoto</p>
//                                     </div>

//                                     <div className='token_detail'>
//                                         <p><strong>Settore:</strong>ICT</p>
//                                         <p><strong>Luogo:</strong>Abbadia Lariana, Lecco, Italia</p>
//                                         <p><strong>Competenze:</strong><span>Microsoft Office</span><span>Gestione chiamate</span></p>
//                                     </div>
//                                 </div>
//                             </a>
//                         </div>
//                         <div
//                             className="job_listing_block"
//                         >
//                             <a
//                                 className="redirect_a"
//                             >
//                                 <div className="job_listing_block_inner">
//                                     <div className="job_title">
//                                         <h2>Giambruno Cosacchi</h2>
//                                     </div>
//                                     <div className="logo_detailbtn">
//                                         <div className="company_logo">
//                                             <img src={CompanyLogo} alt="company logo" />
//                                         </div>
//                                     </div>
//                                     <div className="job_description">
//                                         <p>Presentazione se inserita, altrimenti vuoto</p>
//                                     </div>

//                                     <div className='token_detail'>
//                                         <p><strong>Settore:</strong>ICT</p>
//                                         <p><strong>Luogo:</strong>Abbadia Lariana, Lecco, Italia</p>
//                                         <p><strong>Competenze:</strong><span>Microsoft Office</span><span>Gestione chiamate</span></p>
//                                     </div>
//                                 </div>
//                             </a>
//                         </div>
//                         <div
//                             className="job_listing_block"
//                         >
//                             <a
//                                 className="redirect_a"
//                             >
//                                 <div className="job_listing_block_inner">
//                                     <div className="job_title">
//                                         <h2>Giambruno Cosacchi</h2>
//                                     </div>
//                                     <div className="logo_detailbtn">
//                                         <div className="company_logo">
//                                             <img src={CompanyLogo} alt="company logo" />
//                                         </div>
//                                     </div>
//                                     <div className="job_description">
//                                         <p>Presentazione se inserita, altrimenti vuoto</p>
//                                     </div>

//                                     <div className='token_detail'>
//                                         <p><strong>Settore:</strong>ICT</p>
//                                         <p><strong>Luogo:</strong>Abbadia Lariana, Lecco, Italia</p>
//                                         <p><strong>Competenze:</strong><span>Microsoft Office</span><span>Gestione chiamate</span></p>
//                                     </div>
//                                 </div>
//                             </a>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </>
//     )
// }

// const mapStateToProps = (state) => {
//     return { candidate: state.candidate, };
// };

// const mapDispatchToProps = (dispatch) =>
//     bindActionCreators({ requestGetJobDetails }, dispatch);

// export default connect(mapStateToProps, mapDispatchToProps)(PackageToken);

import React, { useEffect, useState } from "react";
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import CompanyLogo from "../../../src/images/logo.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestCompanyPackage } from "../../Redux/actions";
import { useNavigate } from "react-router-dom";

const PackageToken = (props) => {
    const [packageData, setPackageData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const updatedRequestData = { token };
            props.requestCompanyPackage({ data: updatedRequestData });
        }
    }, [props.requestCompanyPackage]);

    useEffect(() => {
        const packageDetails = props.company.packageData;
        if (packageDetails !== undefined) {
            if (packageDetails?.data?.Status === "Success") {
                console.log(packageDetails.data.Message, "packageDetails");
                setPackageData(packageDetails.data.Message || []);
            } else {
                // Handle unsuccessful status or clear data as needed
                console.error("Failed to fetch package details");
                setPackageData([]);
            }
        }
    }, [props.company.packageData]);


    const redirectToAdd = () => {
        navigate("/company/create-adv");
    };

    const redirectToPayment = (item) => {
        navigate('/company/payment', { state: { item } });
    }

    // const redirectToDetaisl = (item) => {
    //     navigate("/company/Creditmanagement", { state: { item } });
    // }

    return (
        <>
            <Header />
            <div className="job_listing_sec package_token" style={{ maxHeight: "100%", overflow: "auto" }}>
                <div className="container">
                    <div className="new_research_btn mt-4 text-end">
                        <button
                            className="btn btn_default btn_orange_outline"
                            type="button"
                            onClick={redirectToAdd}
                        >
                            Indietro
                        </button>
                    </div>
                    <h2 className='package_token_title mb-3'>Acquista un nuovo pacchetto di crediti</h2>
                    <div className="job_listing_blocks">
                        {packageData.length > 0 ? (
                            packageData.map((item, index) => (
                                <div key={index} className="job_listing_block" onClick={() => redirectToPayment(item)}>
                                    <a className="redirect_a">
                                        <div className="job_listing_block_inner">
                                            <div className="job_title">
                                                <h2>{item.package_name}</h2>
                                            </div>
                                            <div className="logo_detailbtn">
                                                <div className="company_logo">
                                                    <img src={CompanyLogo} alt="company logo" />
                                                </div>
                                            </div>
                                            {/* <div className="job_description">
                                                <p>{item.price}</p>
                                            </div> */}
                                            <div className='token_detail'>
                                                <p><strong>Price:</strong> {item.price}</p>
                                                <p><strong>Price currency:</strong> {item.price_currency}</p>
                                                <p><strong>Token Amount:</strong> {item.token_amount}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))
                        ) : (
                            <p>No package data available.</p>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

const mapStateToProps = (state) => ({
    candidate: state.candidate,
    company: state.company
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ requestCompanyPackage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PackageToken);
