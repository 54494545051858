import React from 'react'

function BirthDateField(props) {
    const {
        data,
        handleDateChange,
        validateBirthDate,
        birthDateRef,
        errorbirthDate,
        mystyle,
        t,
    } = props
    return (
        <div className="registration_col form-group col-md-6 col-12">
            <label className="label">{t("register.birth_date")}</label>
            <input
                className={`form-control w-auto ${errorbirthDate ? "error" : ""
                    }`}
                type="date"
                name="birth_date"
                id={"birth_date"}
                ref={birthDateRef}
                value={data.birth_date || ""}
                onBlur={validateBirthDate}
                onChange={handleDateChange}
                placeholder={t("register.birth_date")}
            />
            {errorbirthDate && <div style={mystyle}>{errorbirthDate}</div>}
        </div>
    )
}

export default BirthDateField