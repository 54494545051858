import React from 'react'
import Select, { createFilter } from "react-select";
import CreatableSelect from 'react-select/creatable';

function Availabilities(props) {
    const {
        errorCreatAd_Four,
        availabilityRef,
        availabilitiesData,
        validateAvailability,
        filterConfig,
        mystyle,
        t,
        isSearchable,
        isClearable,
        selectedOptionsAvailabilityType,
        handleCreateAvailability
    } = props;


    return (
        <>
            <div className="seeking_form_right mt-30">
                <div className="row g-3">
                    <div className="col-12"></div>
                </div>
            </div>
            <div className="seeking_form_full mt-30">
                <div className="row g-3">
                    <div className="col-12">
                        <label className="form-label">
                            Disponibilità richieste (es. lavoro nei festivi)
                        </label>
                        <CreatableSelect
                            className={`form-control select_dropdown ${errorCreatAd_Four?.availability ? "error" : ""
                                }`}
                            options={availabilitiesData}
                            isMulti
                            placeholder="Seleziona le disponibilità o inseriscine di nuove"
                            ref={availabilityRef}
                            onChange={handleCreateAvailability}
                            value={selectedOptionsAvailabilityType}
                            onBlur={validateAvailability}
                            filterOption={createFilter(filterConfig)}
                            isSearchable={isSearchable}
                            isClearable={isClearable}
                        />

                        {errorCreatAd_Four?.availability && (
                            <div style={mystyle}>
                                {errorCreatAd_Four.availability}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>

    )
}

export default Availabilities