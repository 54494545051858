import React, { useEffect } from "react";
import MonthYearsExp from './MonthYearsExp';
import LocationExp from './LocationExp';
import LeftArrow from "../../../images/left_orange_arrow.png";
import RightArrow from "../../../images/right_green_arrow.png";
import SectorLevel from "./SectorLevel";


function ExperienceForm(props) {
    const {
        nextStep,
        prevStep,
        experience,
        setExperience,
        errorexperience,
        sortedCountryData,
        municipalities,
        validateExperienceSector,
        validateExperienceRole,
        validateExperienceCountry,
        validateExperienceCommon,
        validateExperienceProvince,
        validateExperienceCompany,
        validateExperienceYearFrom,
        validateExperienceYearTo,
        validateExperienceLetter,
        // validateExperienceDescription,
        handleInputExperience,
        handleSubMenuExpChange,
        handleInputExperience1,
        handleRemoveExperience,
        handleChangeExprienceCity,
        handleAddExperience,
        FontAwesomeIcon,
        faTrashCan,
        isLoading,
        isClearable,
        isSearchable,
        filterConfig,
        mystyle,
        focusRefs,
        error,
        setError,
        showNavButtons,
        selectedOptionsExperience,
        t,
        handleChangeSectorLevel,
        sectorsLevel,
        subSubSectorsLevel
    } = props

    useEffect(() => {
        if (error && errorexperience) {
            const focusField = () => {
                for (let i = 0; i < errorexperience.length; i++) {
                    const expError = errorexperience[i];
                    if (expError?.sector) {
                        focusRefs.experience.current[i]?.sector?.focus();
                        break;
                    } else if (expError?.role) {
                        focusRefs.experience.current[i]?.role?.focus();
                        break;
                    } else if (expError?.address?.country) {
                        focusRefs.experience.current[i]?.country_experience?.focus();
                        break;
                    } else if (expError?.address?.city) {
                        focusRefs.experience.current[i]?.common_experience?.focus();
                        break;
                    } else if (expError?.address?.province) {
                        focusRefs.experience.current[i]?.province_experience?.focus();
                        break;
                    } else if (expError?.company) {
                        focusRefs.experience.current[i]?.company?.focus();
                        break;
                    } else if (expError?.yearFrom) {
                        focusRefs.experience.current[i]?.yearFromexp?.focus();
                        break;
                    } else if (expError?.yearTo) {
                        focusRefs.experience.current[i]?.yearToexp?.focus();
                        break;
                    }
                    // else if (expError?.description) {
                    //     focusRefs.experience.current[i]?.description?.focus();
                    //     break;
                    // }
                }
            };
            focusField();
            setError(false);
        }
    }, [error, errorexperience, focusRefs]);

    useEffect(() => {
        if (experience.length === 0) {
            localStorage.removeItem('experience');
            localStorage.removeItem('experienceSector');
        }
    }, [selectedOptionsExperience])


    return (
        <>
            <div className="resume-inner-content">
                <div className="single-section exprerience">
                    <div className="single-section-title-add">
                        <h4 className="single-section-title">
                            {t('exprience.title')}
                            <span style={{ color: "red" }}>*</span>
                        </h4>
                    </div>
                    <>
                        {experience.map((x, i) => {
                            return (
                                <div key={i}
                                    className="row single-section-form"
                                >
                                    <div
                                        className="col-lg-12 col-md-12"
                                        style={{ padding: "5px" }}
                                    >
                                        <div className="inner-title-btn">
                                            <div className="inner-title">
                                                <h6>{t('exprience.title')} : {i + 1}</h6>
                                            </div>
                                            <div className="inner-btn">
                                                <button
                                                    onClick={() => handleRemoveExperience(i)}
                                                >
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <SectorLevel
                                        i={i}
                                        x={x}
                                        validateExperienceSector={validateExperienceSector}
                                        selectedOptionsExperience={selectedOptionsExperience}
                                        focusRefs={focusRefs}
                                        errorexperience={errorexperience}
                                        //============sub sector===================
                                        handleSubMenuExpChange={handleSubMenuExpChange}
                                        mystyle={mystyle}
                                        t={t}
                                        handleChangeSectorLevel={handleChangeSectorLevel}
                                        sectorsLevel={sectorsLevel}
                                        subSubSectorsLevel={subSubSectorsLevel}
                                    />
                                    <div
                                        className="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="role">{t('exprience.role')}</label>
                                            <input
                                                className={`form-control ${errorexperience[i].role ? 'error' : ''}`}
                                                type="text"
                                                name="role"
                                                id={"role" + i}
                                                value={x.role}
                                                ref={(el) => {
                                                    focusRefs.experience.current[i] = focusRefs.experience.current[i] || {};
                                                    focusRefs.experience.current[i].role = el;
                                                }}
                                                onBlur={() => validateExperienceRole(i)}
                                                onChange={(e) => handleInputExperience(e, i)}
                                                placeholder={t('exprience.role')}
                                            />
                                            {errorexperience[i].role && (
                                                <div style={mystyle}>
                                                    {errorexperience[i].role}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <LocationExp
                                        i={i}
                                        x={x}
                                        experience={experience}
                                        setExperience={setExperience}
                                        errorexperience={errorexperience}
                                        sortedCountryData={sortedCountryData}
                                        municipalities={municipalities}
                                        validateExperienceCountry={validateExperienceCountry}
                                        validateExperienceCommon={validateExperienceCommon}
                                        validateExperienceProvince={validateExperienceProvince}
                                        handleInputExperience={handleInputExperience}
                                        handleChangeExprienceCity={handleChangeExprienceCity}
                                        focusRefs={focusRefs}
                                        isLoading={isLoading}
                                        isClearable={isClearable}
                                        isSearchable={isSearchable}
                                        filterConfig={filterConfig}
                                        mystyle={mystyle}
                                        t={t}
                                    />
                                    <div
                                        className="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="company">{t('exprience.company_name')}</label>
                                            <input
                                                className={`form-control ${errorexperience[i].company ? 'error' : ''}`}
                                                type="text"
                                                name="company"
                                                id={"company" + i}
                                                value={x.company}
                                                ref={(el) => {
                                                    focusRefs.experience.current[i] = focusRefs.experience.current[i] || {};
                                                    focusRefs.experience.current[i].company = el;
                                                }}
                                                onBlur={() => validateExperienceCompany(i)}
                                                onChange={(e) => handleInputExperience(e, i)}
                                                placeholder={t('exprience.company_name')}
                                            />
                                            {errorexperience[i].company && (
                                                <div style={mystyle}>
                                                    {errorexperience[i].company}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {
                                        selectedOptionsExperience[i]?.submenu &&
                                            subSubSectorsLevel.find(option => option.sectorName === selectedOptionsExperience[i].submenu) &&
                                            subSubSectorsLevel.length > 0 ? null : (
                                            <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                            </div>
                                        )
                                    }

                                    {experience[i].address.country.length > 0 && experience[i].address.country === "Italia"
                                        ? null : (
                                            <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                            </div>
                                        )
                                    }

                                    <MonthYearsExp
                                        i={i}
                                        x={x}
                                        errorexperience={errorexperience}
                                        handleInputExperience={handleInputExperience}
                                        validateExperienceYearFrom={validateExperienceYearFrom}
                                        validateExperienceYearTo={validateExperienceYearTo}
                                        focusRefs={focusRefs}
                                        mystyle={mystyle}
                                        t={t}
                                    />
                                    <div
                                        className="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                    >
                                        <div className="form-group">
                                            <label >{t('exprience.Reference_letter')}</label>
                                            <div className="input-group custom-file-button">
                                                <label className={`input-group-text file-button ${errorexperience[i].reccomendationLetter ? 'error' : ''}`} htmlFor={`reccomendationLetterexp${i}`} >{t('exprience.Reference_letter_title')}</label>
                                                <input type="file"
                                                    accept=".pdf"
                                                    className={`form-control ${errorexperience[i].reccomendationLetter ? 'error' : ''}`}
                                                    name="reccomendationLetter"
                                                    id={"reccomendationLetterexp" + i}
                                                    ref={(el) => {
                                                        focusRefs.experience.current[i] = focusRefs.experience.current[i] || {};
                                                        focusRefs.experience.current[i].reccomendationLetterexp = el;
                                                    }}
                                                    onBlur={() => validateExperienceLetter(i)}
                                                    onChange={(e) => handleInputExperience1(e, i)}
                                                    placeholder="Nessun file selezionato" />
                                                {errorexperience[i].reccomendationLetter && (
                                                    <div style={mystyle}>
                                                        {errorexperience[i].reccomendationLetter}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6"></div>
                                    {/* description */}
                                    <div
                                        className="col-lg-12 col-md-12"
                                        style={{ padding: "5px" }}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="description">{t('exprience.Job_description')}</label>
                                            <textarea
                                                className={`form-control ${errorexperience[i].description ? 'error' : ''}`}
                                                name="description"
                                                id={"description" + i}
                                                value={x.description}
                                                ref={(el) => {
                                                    focusRefs.experience.current[i] = focusRefs.experience.current[i] || {};
                                                    focusRefs.experience.current[i].description = el;
                                                }}
                                                style={{ minHeight: "120px", overflow: "auto" }}
                                                // onBlur={() => validateExperienceDescription(i)}
                                                onChange={(e) => handleInputExperience(e, i)}
                                                placeholder={t('exprience.Job_description')}
                                            />
                                            {errorexperience[i].description && (
                                                <div style={mystyle}>
                                                    {errorexperience[i].description}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}</>
                    <div className="add-box-btn">
                        <button className="add-box-button" type="button"
                            onClick={handleAddExperience}
                        >{t('exprience.button.save_exprience')}
                        </button>
                    </div>
                </div>
            </div>

            {showNavButtons && (
                <div className="back_next_btn">
                    <div className="back_btn">
                        <button
                            className="btn btn_default btn_orange_outline"
                            type="button"
                            onClick={prevStep}>
                            <img src={LeftArrow} alt="Main Logo" />
                            Indietro
                        </button>
                    </div>
                    <div className="next_btn">
                        <button
                            className="btn btn_default btn_green"
                            type="button"
                            onClick={nextStep}>
                            avanti
                            <img src={RightArrow} alt="Main Logo" />
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default ExperienceForm