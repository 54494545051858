import React, { useEffect } from 'react';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import { useNavigate } from 'react-router-dom';
import BackButton from '../common/BackButton';
import ChildCreaAnnuncio7Bis from './ChildCreaAnnuncio7Bis';

function CreaAnnuncioSeven(props) {
    const navigate = useNavigate();
    const {
        prevStep,
        CreatAd_Seven,
        errorCreatAd_Seven,
        handleOnCreatAd_Seven,
        validateAdSponsorDate,
        validateforSpent,
        validateForSponsorRecurrency,
        handleOnPatchAdvertisement,
        handleOnCreatSponsorType,
        isClearable,
        isSearchable,
        filterConfig,
        spentRef,
        sponsorRecurrencyRef,
        sponsorDueDateRef,
        error,
        setError,
        mystyle,
        t
    } = props


    useEffect(() => {
        const focusField = () => {
            if (errorCreatAd_Seven.spent && spentRef?.current) {
                spentRef.current.focus();
            } else if (errorCreatAd_Seven.sponsor_recurrency && sponsorRecurrencyRef?.current) {
                sponsorRecurrencyRef.current.focus();
            } else if (errorCreatAd_Seven.sponsor_due_date && sponsorDueDateRef?.current) {
                sponsorDueDateRef.current.focus();
            }
        };

        if (error) {
            focusField();
            setError(false);
        }
    }, [
        error,
        errorCreatAd_Seven,
        spentRef,
        sponsorRecurrencyRef,
        sponsorDueDateRef
    ]);

 
    return (
        <>
            <Header />
            <div className="seekng_job_sec create-an-ad">
                <div className="container">
                    <form>
                        {/* <div className="seekng_job_title_note">
                            <div className="seekng_job_title">
                                <h2>
                                    Inserisci un nuovo annuncio per la ricerca di dipendenti
                                </h2>
                                <h6>Pubblica annuncio</h6>
                            </div>
                        </div>
                        <div className="seeking_job_inner">
                            <div className="seeking_job_form">
                                <div className="seeking_form_full m-0">
                                    <div className="row g-3">
                                        <div className="col-12 mt-0">
                                            <div className="publish_block">
                                                <div className="publish_block_inner">
                                                    <div className="publish_text">
                                                        <p>
                                                            Pubblica il tuo annuncio sponsorizzandolo. <br />
                                                            In questo modo l annuncio risulterà molto più
                                                            visibile ai potenziali candidati interessati
                                                        </p>
                                                        <p>
                                                            Ti suggeriamo un valore di sponsorizzazione che,
                                                            in base alla nostre statistiche storiche può far
                                                            aumentare la percentuale di candidature
                                                            interessate fino ad un valore dell 84%
                                                        </p>
                                                    </div>

                                                    <div className="row g-3">
                                                        <div className="col-md-6 col-12">
                                                            <label className="form-label">
                                                                Crediti da investire
                                                            </label>
                                                            <div className="d-flex flex-wrap flex-sm-nowrap contact-duration">
                                                                <input
                                                                    className={`form-control ${CreatAd_Seven?.spent?.length === 0 && errorCreatAd_Seven.spent ? "error" : ""
                                                                        }`}
                                                                    type='number'
                                                                    name='spent'
                                                                    ref={spentRef}
                                                                    value={CreatAd_Seven.spent || ""}
                                                                    onChange={(e) => handleOnCreatAd_Seven(e)}
                                                                    onBlur={validateforSpent}
                                                                />

                                                                <Select
                                                                    className={`form-control select_dropdown  ${CreatAd_Seven?.sponsor_recurrency?.length === 0 &&
                                                                        errorCreatAd_Seven.sponsor_recurrency
                                                                        ? "error"
                                                                        : ""
                                                                        }`}
                                                                    name='sponsor_recurrency'
                                                                    placeholder="Giorno"
                                                                    ref={sponsorRecurrencyRef}
                                                                    value={CreatAd_Seven.sponsor_recurrency ? { label: `${CreatAd_Seven.sponsor_recurrency}`, value: `${CreatAd_Seven.sponsor_recurrency}` } : ""}
                                                                    onChange={handleOnCreatSponsorType}
                                                                    onBlur={validateForSponsorRecurrency}
                                                                    options={DayList}
                                                                    filterOption={createFilter(filterConfig)}
                                                                    isClearable={isClearable}
                                                                    isSearchable={isSearchable}
                                                                />
                                                            </div>

                                                            {CreatAd_Seven?.spent?.length === 0 && errorCreatAd_Seven.spent && (
                                                                <div style={mystyle}>
                                                                    {errorCreatAd_Seven.spent}
                                                                </div>
                                                            )}

                                                            {CreatAd_Seven?.sponsor_recurrency?.length === 0 &&
                                                                errorCreatAd_Seven?.sponsor_recurrency && (
                                                                    <div style={mystyle}>
                                                                        {errorCreatAd_Seven.sponsor_recurrency}
                                                                    </div>
                                                                )}

                                                        </div>
                                                        <div className="col-md-6 col-12 text-center">
                                                            <label className="form-label">
                                                                Sponsorizza fino al
                                                            </label>
                                                            <div className="d-flex flex-wrap flex-sm-nowrap contact-duration justify-content-center">
                                                                <input
                                                                    className={`form-control w-auto ${errorCreatAd_Seven.sponsor_due_date ? "error" : ""
                                                                        }`}
                                                                    type="date"
                                                                    name="sponsor_due_date"
                                                                    id="sponsor_due_date"
                                                                    value={CreatAd_Seven?.sponsor_due_date || ""}
                                                                    ref={sponsorDueDateRef}
                                                                    onChange={(e) => handleOnCreatAd_Seven(e)}
                                                                    onBlur={validateAdSponsorDate}
                                                                    placeholder="Sponsorizza fino al"
                                                                />
                                                            </div>
                                                            {errorCreatAd_Seven.sponsor_due_date && (
                                                                <div style={mystyle}>
                                                                    {errorCreatAd_Seven.sponsor_due_date}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="seeking_form_btn">
                            <p className="credits_text">
                                Crediti spesi al giorno:
                                <span>Non hai crediti sufficienti!</span>
                            </p>
                            <div className="btn btn_default btn_green_outline" onClick={() => redirectToPackage()}>
                                acquista crediti
                            </div>
                            <div className="btn btn_default btn_green">
                                Sponsorizza annuncio
                            </div>
                        </div> */}

                        <ChildCreaAnnuncio7Bis
                            //==================spent=========================

                            CreatAd_Seven={CreatAd_Seven}
                            handleOnCreatAd_Seven={handleOnCreatAd_Seven}
                            validateforSpent={validateforSpent}
                            errorCreatAd_Seven={errorCreatAd_Seven}
                            spentRef={spentRef}

                            //==================sponsor_recurrency=========================
                            handleOnCreatSponsorType={handleOnCreatSponsorType}
                            validateForSponsorRecurrency={validateForSponsorRecurrency}
                            sponsorRecurrencyRef={sponsorRecurrencyRef}

                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            filterConfig={filterConfig}

                            //==================sponsor_due_date=========================
                            validateAdSponsorDate={validateAdSponsorDate}
                            sponsorDueDateRef={sponsorDueDateRef}
                            mystyle={mystyle}
                            t={t}
                        />
                        <div className="seeking_job_inner">
                            <div className="seeking_job_form">
                                <div className="seeking_form_full m-0">
                                    <div className="row g-3">
                                        <div className="col-12 mt-0">
                                            <div className="publish_block">
                                                <div className="publish_block_inner">
                                                    <div className="publish_text mb-0">
                                                        <p>
                                                            Pubblica il tuo annuncio senza alcuna
                                                            sponsorizzazione.
                                                        </p>
                                                        <p>
                                                            L’annuncio sarà visibile ad un numero limitato di
                                                            potenziali candidati interessati
                                                        </p>
                                                        <p>
                                                            Se le candidature interessate che ti
                                                            raggiungeranno non ti soddisferanno potrai in ogni
                                                            momento procedere ad una sponsorizzazione
                                                            dell’annuncio aumentandone in modo considerevole
                                                            la visibilità e di conseguenza il numero di
                                                            potenziali candidati che potranno rendersi
                                                            disponibili
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="seeking_form_btn">
                            <BackButton
                                prevStep={prevStep}
                            />
                            <div className="btn btn_default btn_orange_outline" onClick={handleOnPatchAdvertisement}>
                                pubblica senza sponsorizzare
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CreaAnnuncioSeven;