import React from "react";

function CategoriesCheckbox({
  data,
  handleCheckboxChange,
}) {
  return (
    <div className="selection_checkboxs row">
      <div className="selection_checkboxs_col col-md-6 col-12">
        {/* <div className="selection_checkbox">
          <input
            type="checkbox"
            id="minority_group"
            name="minority_group"
            checked={data.minority_group}
            onChange={handleCheckboxChange}
          />
          <label className="highlighted_label">
            <span>Appartenente a categorie protette</span>
          </label>
        </div> */}
      </div>
      
      <div className="selection_checkboxs_col col-md-6 col-12">
        <div className="selection_checkbox">
          <input
            type="checkbox"
            id="travel_available"
            name="travel_available"
            checked={data.travel_available}
            onChange={handleCheckboxChange}
          />
          <label className="highlighted_label">
            <span>Disponibile a trasferirmi</span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default CategoriesCheckbox;