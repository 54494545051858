// import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import { useNavigate, useLocation } from "react-router-dom";
// import { bindActionCreators } from "redux";
// import { requestGetJobList } from "../../Redux/actions";
// import CompanyLogo from "../../../src/images/logo.png";
// import { useTranslation } from 'react-i18next';
// import Header from "../../Components/Header";
// import Footer from "../../Components/Footer";
// import { jwtDecode } from "jwt-decode";

// function CandidateJobsList(props) {
//     const [jobs, setJobs] = useState([]);
//     const [user, setUser] = useState(null);
//     const { t } = useTranslation();
//     const location = useLocation();
//     const navigate = useNavigate();

//     useEffect(() => {
//         const data = location.state?.jobListData || [];
//         setJobs(data);
//     }, [location.state?.jobListData]);

//     useEffect(() => {
//         const token = localStorage.getItem("token");
//         if (token) {
//             try {
//                 const decodedToken = jwtDecode(token);
//                 const currentTime = Date.now() / 1000;
//                 if (decodedToken.exp > currentTime) {
//                     setUser(decodedToken);
//                 } else {
//                     console.warn("Token has expired");
//                 }
//             } catch (error) {
//                 console.error("Invalid token", error);
//             }
//         }
//     }, []);

//     const getValue = (value) => (user ? value : "xxxxxxxxxxxxxxxx");

//     const redirectToJobDetails = (id) => {
//         navigate("/job-details/" + id);
//     };

//     const redirectToJobFinder = () => {
//         navigate("/company/candidate-finder", { state: { jobListData: [] } });
//     };

//     return (
//         <>
//             <Header />
//             {jobs.length > 0 && (
//                 <div className="job_listing_sec">
//                     <div className="container">
//                         <div className="new_research_btn">
//                             <button
//                                 className="btn btn_default btn_orange_outline"
//                                 type="button"
//                                 onClick={redirectToJobFinder}
//                             >
//                                 Nuova ricerca
//                             </button>
//                         </div>
//                         <div className="job_listing_blocks">
//                             {jobs.map((job) => (
//                                 <div
//                                     key={job.id}
//                                     className={`job_listing_block ${!user ? 'blur_effect' : ''}`}
//                                 >
//                                     <a
//                                         className="redirect_a"
//                                         onClick={() => redirectToJobDetails(job.id)}
//                                     >
//                                         <div className="job_listing_block_inner">
//                                             <div className="job_title">
//                                                 <h2>{getValue(job.job_title)}</h2>
//                                             </div>
//                                             <div className="logo_detailbtn">
//                                                 <div className="company_logo">
//                                                     <img src={CompanyLogo} alt="company logo" />
//                                                 </div>
//                                                 <div className="detail_btn">
//                                                     <button
//                                                         className="btn btn_default btn_green_outline"
//                                                         type="button"
//                                                         onClick={() => redirectToJobDetails(job.id)}
//                                                     >
//                                                         {t('job-list.button.save')}
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                             <div className="job_description">
//                                                 <p>{getValue(job.job_description)}</p>
//                                             </div>
//                                             <div className="sector_name">
//                                                 <p>{getValue(job.sector.sectorName)}</p>
//                                             </div>
//                                             <div className="job_salary_address">
//                                                 <div className="job_salary">
//                                                     <p>
//                                                         {getValue(
//                                                             job?.salary?.fixedAmount
//                                                                 ? `${job?.salary?.fixedAmount} ${job?.salary?.reccurency}`
//                                                                 : job?.salary?.minAmount && job?.salary?.maxAmount
//                                                                     ? `${job?.salary?.minAmount} - ${job?.salary?.maxAmount} ${job?.salary?.reccurency}`
//                                                                     : "Not disclosed"
//                                                         )}
//                                                     </p>
//                                                 </div>
//                                                 <div className="job_address">
//                                                     <p>
//                                                         {getValue(
//                                                             job.address
//                                                                 ? `${job.address.city ? job.address.city : ""}${job.address.province
//                                                                     ? `, ${job.address.province}`
//                                                                     : ""
//                                                                 }${job.address.country
//                                                                     ? `, ${job.address.country}`
//                                                                     : ""
//                                                                 }`
//                                                                 : ""
//                                                         )}
//                                                     </p>
//                                                 </div>
//                                             </div>
//                                             <div className="required_education_skills">
//                                                 <div className="required_education">
//                                                     <p>{getValue(job.educationalLevel?.levelType || "Not mentioned")}</p>
//                                                 </div>
//                                                 <div className="required_skills">
//                                                     <ul>
//                                                         {user && job.skills && job.skills.length > 0 ? (
//                                                             job.skills.map((skill, index) => (
//                                                                 <li key={index}>{skill.skillName}</li>
//                                                             ))
//                                                         ) : (
//                                                             <li>{getValue("No skills listed")}</li>
//                                                         )}
//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </a>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             )}
//             <Footer />
//         </>
//     );
// }

// const mapStateToProps = (state) => {
//     return { candidate: state.candidate };
// };

// const mapDispatchToProps = (dispatch) =>
//     bindActionCreators({ requestGetJobList }, dispatch);

// export default connect(mapStateToProps, mapDispatchToProps)(CandidateJobsList);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { requestGetJobList } from "../../Redux/actions";
import CompanyLogo from "../../../src/images/logo.png";
import { useTranslation } from "react-i18next";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { jwtDecode } from "jwt-decode";
import CandidateJobsDetails from "./Candidate-jobs-details";
import { useAppNavigation } from "../../utils/routing";
import { resetGetJobList } from "../../Redux/actions";
function CandidateJobsList(props) {
  const [jobs, setJobs] = useState([]);
  const [JobId, setJobId] = useState(null);
  const [details, setDetails] = useState(null);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { navigateTo } = useAppNavigation();

  useEffect(() => {
    const data = location.state?.jobListData || [];
    setJobs(data);

    return () => {
      if (props.candidate && "GetJobListData" in props.candidate) {
        props.candidate.GetJobListData = undefined;
        console.log("Setting candidate GetJobListData to undefined");
      }
      if (props.company && "GetJobListData" in props.company) {
        props.company.GetJobListData = undefined;
        console.log("Setting company GetJobListData to undefined");
      }
      if (props.agent && "GetJobListData" in props.agent) {
        props.agent.GetJobListData = undefined;
        console.log("Setting agent GetJobListData to undefined");
      }
      if (props.franchisor && "GetJobListData" in props.franchisor) {
        props.franchisor.GetJobListData = undefined;
      }
      props.resetGetJobList();
    };
  }, [
    location.state?.jobListData,
    props.candidate?.GetJobListData,
    props.company?.GetJobListData,
    props.agent?.GetJobListData,
    props.franchisor?.GetJobListData,
  ]);

  useEffect(() => {
    const companyData = props?.company?.getCompanyData;
    const employeeData = props?.candidate?.GetCandidateData;
    const agentData = props?.agent?.CandidateProfileData;

    if (props.company?.loginData?.data.Message.flag_company) {
      setUser({ ...companyData, ...props.candidate?.loginData?.data.Message });
    }

    if (props.candidate?.loginData?.data.Message.flag_candidate) {
      setUser({ ...employeeData, ...props.candidate?.loginData?.data.Message });
    }

    if (props.candidate?.loginData?.data.Message.flag_agent) {
      setUser({ ...agentData, ...props.candidate?.loginData?.data.Message });
    }
  }, [props.company, props.candidate, props.agent]);

  useEffect(() => {
    if (location.state?.resultsType === "employees") {
      setUser((p) => ({ ...p, company: true }));
    }

    if (location.state?.resultsType === "jobs") {
      setUser((p) => ({ ...p, company: false }));
    }

    if (location.state?.resultsType === "agents") {
      setUser((p) => ({ ...p, company: true }));
    }
  }, [location.state]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          setUser((p) => ({ ...p, ...decodedToken }));
        } else {
          console.warn("Token has expired");
        }
      } catch (error) {
        console.error("Invalid token", error);
      }
    }
  }, []);

  const getValue = (value) => (user.user_id ? value : "xxxxxxxxxxxxxxxx");
  console.log(user);
  const redirectToJobDetails = (id, details) => {
    if (!user?.company && !user?.user_id) {
      navigateTo(location.pathname, {
        state: location.state,
        params: { userType: "candidate" },
      });
      setShowModal("login");
      return;
    }
    if (user?.company && !user?.user_id) {
      navigateTo(location.pathname, {
        state: location.state,
        params: { userType: "company" },
      });
      setShowModal("login");
      return;
    }
    setDetails(details);
    setJobId(id);
  };

  const redirectToJobFinder = () => {
    navigate("/company/candidate-finder", { state: { jobListData: [] } });
  };

  return (
    <>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <div className="container">
        <div className="new_research_btn mt-4 text-end">
          <button
            style={{ marginRight: "10px" }}
            className="btn btn_default btn_orange_outline"
            type="button"
            onClick={redirectToJobFinder}
          >
            Nuova ricerca
          </button>
          {props.candidate?.loginData?.data.Message.flag_company ? (
            <button
              className="btn btn_default btn_orange_outline"
              type="button"
              onClick={() => {
                navigate("/company/create-adv");
              }}
            >
              Crea nuovo annuncio
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            {jobs.length > 0 && (
              <div className="job_listing_sec">
                {/* <div className="container"> */}

                <div className="job_listing_blocks">
                  {jobs.map((job) => {
                    return (
                      <div
                        key={job.id}
                        className={`job_listing_block ${
                          !user.user_id ? "blur_effect" : ""
                        }`}
                      >
                        <a
                          className="redirect_a"
                          onClick={() => redirectToJobDetails(job.id, job)}
                        >
                          <div className="job_listing_block_inner">
                            <div className="job_title">
                              <h2>
                                {(() => {
                                  if (user?.company) {
                                    return `${getValue(
                                      job.name || ""
                                    )} ${getValue(job.surname || "")}`;
                                  } else {
                                    return `${getValue(
                                      job.first_name || ""
                                    )} ${getValue(job.last_name || "")}`;
                                  }
                                })()}
                                {getValue(job.first_name || "")}{" "}
                                {getValue(job.last_name || "")}
                              </h2>
                            </div>
                            <div className="logo_detailbtn">
                              <div className="company_logo">
                                <img src={CompanyLogo} alt="company logo" />
                              </div>
                              <div className="detail_btn">
                                <button
                                  className="btn btn_default btn_green_outline"
                                  type="button"
                                  // onClick={() => redirectToJobDetails(job.id)}
                                >
                                  {location.state.resultsType === "employees" ||
                                  location.state.resultsType === "agents"
                                    ? "Dettagli candidato"
                                    : t("job-list.button.save")}
                                </button>
                              </div>
                            </div>
                            {/* <div className="job_description">
                                                        <p>{getValue(job.job_description)}</p>
                                                    </div> */}
                            <div className="sector_name">
                              <p>
                                <strong>
                                  {user?.company ? "Settore" : "Name"}
                                  :&nbsp;&nbsp;
                                </strong>
                                {(() => {
                                  if (user?.company) {
                                    return getValue(
                                      job?.sector?.sectorName || ""
                                    );
                                  } else {
                                    return `${getValue(
                                      job?.title?.title || ""
                                    )} ${getValue(job.name || "")} ${getValue(
                                      job.surname || ""
                                    )}`;
                                  }
                                })()}
                              </p>
                            </div>
                            {user?.company ? (
                              <></>
                            ) : (
                              <div className="sector_name">
                                <p>
                                  <strong>cellphone:&nbsp;&nbsp;</strong>
                                  {getValue(job?.cellphone || "")}
                                </p>
                              </div>
                            )}
                            <div className="job_salary_address">
                              {/* <div className="job_salary">
                                                            <p>
                                                                <strong>Retribuzione:&nbsp;&nbsp;</strong>
                                                                {getValue(
                                                                    job?.salary?.fixedAmount
                                                                        ? `${job?.salary?.fixedAmount} ${job?.salary?.reccurency}`
                                                                        : job?.salary?.minAmount && job?.salary?.maxAmount
                                                                            ? `${job?.salary?.minAmount} - ${job?.salary?.maxAmount} ${job?.salary?.reccurency}`
                                                                            : ""
                                                                )}
                                                            </p>
                                                        </div> */}
                              <div className="job_address">
                                <p>
                                  <strong>Luogo:&nbsp;&nbsp;</strong>
                                  <span>
                                    {getValue(
                                      job?.address?.[0]
                                        ? `${
                                            job?.address?.[0]?.city
                                              ? job?.address?.[0]?.city
                                              : ""
                                          }${
                                            job?.address?.[0]?.province
                                              ? `, ${job?.address?.[0]?.province}`
                                              : ""
                                          }${
                                            job?.address?.[0]?.country
                                              ? `, ${job?.address?.[0]?.country}`
                                              : ""
                                          }`
                                        : ""
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="required_education_skills">
                              <div className="required_education">
                                <p>
                                  {" "}
                                  {job.educationalLevel?.levelType ? (
                                    <strong>
                                      {(() => {
                                        if (user?.company) {
                                          return "Istruzione:";
                                        } else {
                                          return "Istruzione richiesta:";
                                        }
                                      })()}
                                      &nbsp;&nbsp;
                                    </strong>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {getValue(
                                    job.educationalLevel?.levelType || ""
                                  )}
                                </p>
                              </div>
                              {/* <div className="required_skills">

                                                            {user && job.skills && job.skills.length > 0 ?
                                                                (<>
                                                                    <p>
                                                                        <strong>
                                                                            Competenze richieste:&nbsp;&nbsp;
                                                                        </strong>

                                                                        {
                                                                            job.skills.map((skill, index) => (
                                                                                <span key={index}>{skill.skillName},</span>
                                                                            ))
                                                                        }

                                                                    </p>
                                                                </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                        </div> */}
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
                {/* </div> */}
              </div>
            )}
          </div>
          {JobId && user && (
            <div className="col-md-6 col-12">
              <CandidateJobsDetails id={JobId} user={user} detail={details} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate, company: state.company };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestGetJobList, resetGetJobList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CandidateJobsList);
