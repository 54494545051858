import React from 'react';

//================ Basic Register, Candidate Register, Profile ===================// 

function StreetField(props) {
    const {
        data,
        validateStreetNumber,
        onChangeData,
        streetNumberRef,
        errorStreetnumber,
        mystyle,
        t,
    } = props;

    return (
        <div className="registration_col form-group col-md-6 col-12">
            <label htmlFor="qualification" className="label">
                {/* Indirizzo [via, numero ] */}
                {t("register.street")}
            </label>
            <input
                type="text"
                className={`form-control set_max_width ${errorStreetnumber ? "error" : ""
                    }`}
                placeholder={t("register.street_placeholder")}
                id="street_number"
                name="street_number"
                ref={streetNumberRef}
                value={data.street_number || ""}
                onChange={onChangeData}
                onBlur={validateStreetNumber}
            />
            {errorStreetnumber && (
                <div style={mystyle}>{errorStreetnumber}</div>
            )}
        </div>
    )
}

export default StreetField