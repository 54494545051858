import React, { useState, useEffect } from 'react';
import PdfIcon from "../../../images/file-pdf.png";
import DownloadIcon from "../../../images/download.png";
import TrashIcon from "../../../images/trash_icon.png";

const FileInputComponent = ({
    i,
    x,
    errorexperience,
    validateExperienceLetter,
    handleInputExperience1,
    focusRefs,
    t,
    deleteExperienceLetter,
    deleteIndexRef
}) => {
    const [fileName, setFileName] = useState('');
    const [isFileInputVisible, setIsFileInputVisible] = useState(false);

    const mystyle_resume_file = {
        color: "#D10000",
        padding: "5px 0px",
        width: "100%",
    }

    useEffect(() => {
        if (x.reccomendationLetter) {
            if (x.reccomendationLetter instanceof File) {
                setFileName(x.reccomendationLetter.name);
            } else if (typeof x.reccomendationLetter === 'string') {
                const extractedFileName = x.reccomendationLetter.split('/').pop();
                setFileName(decodeURIComponent(extractedFileName));
            }
        } else {
            setFileName('');
        }
    }, [x.reccomendationLetter]);

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     console.log(file,"dinesh")
    //     if (file) {
    //         setFileName(file.name);
    //         handleInputExperience1(e, i);
    //     } else {
    //         setFileName('');
    //     }
    // };

    const handleFileChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
            handleInputExperience1(e, index, file);
        } else {
            setFileName('');
            handleInputExperience1(e, index, null);
        }
    };

    const toggleFileInputVisibility = () => {
        setIsFileInputVisible(!isFileInputVisible);
    };

    const handleDownload = (e) => {
        e.preventDefault();
        if (x.reccomendationLetter) {
            let url = '';
            let downloadFileName = '';

            if (typeof x.reccomendationLetter === 'string') {
                downloadFileName = x.reccomendationLetter.split('/').pop();
                const apiHost = 'http://85.235.151.227';
                url = `${apiHost}${x.reccomendationLetter}`;
            } else {
                console.error('Invalid recommendation letter data type.');
                return;
            }

            if (url) {
                const aTag = document.createElement("a");
                aTag.href = url;
                aTag.setAttribute("download", downloadFileName);
                document.body.appendChild(aTag);
                aTag.click();
                document.body.removeChild(aTag);
            } else {
                console.error('Failed to create a download link.');
            }
        } else {
            console.error('No recommendation letter data available.');
        }
    };

    const handleDeleteLetter = (e) => {
        e.preventDefault();
        const confirmDelete = window.confirm("Sei sicuro di voler cancellare il tuo CV?");
        if (confirmDelete) {
            if (x.reccomendationLetter === null) {
                setFileName('');
            }
            deleteIndexRef.current = i;
            deleteExperienceLetter({ target: { name: 'reccomendationLetter', fileName } }, true);
        }
    };

    const truncateFileName = (fileName, maxLength) => {
        if (!fileName) return "Nome file";
        if (fileName.length <= maxLength) return fileName;

        const separator = '...';
        const sepLen = separator.length;
        const charsToShow = maxLength - sepLen;
        const frontChars = Math.ceil(charsToShow / 2);
        const backChars = Math.floor(charsToShow / 2);

        return fileName.substr(0, frontChars) + separator + fileName.substr(fileName.length - backChars);
    };

    const isDownloadDisabled = !(typeof x.reccomendationLetter === 'string') || !fileName || fileName === 'null';
    const isDeleteDisabled = !(typeof x.reccomendationLetter === 'string') || !fileName || fileName === 'null';

    return (
        <div className="professional_experience_files">
            <div className="professional_experience_files_inner">
                <div className="download_options">
                    <div className="download_option file_upload">
                        <div onClick={toggleFileInputVisibility} style={{ cursor: "pointer" }}>
                            <img src={PdfIcon} alt="Upload Resume" />
                        </div>
                    </div>

                    {isFileInputVisible && (
                        <div className="upload_file_option">
                            <div className="form-group">
                                <div className="input-group custom-file-button">
                                    <label
                                        className={`input-group-text file-button ${errorexperience[i]?.reccomendationLetter ? "error" : ""}`}
                                        htmlFor={`reccomendationLetterexp${i}`}>
                                        {t("exprience.Reference_letter_title")}
                                    </label>
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        className={`form-control ${errorexperience[i]?.reccomendationLetter ? "error" : ""}`}
                                        name="reccomendationLetter"
                                        id={`reccomendationLetterexp${i}`}
                                        ref={(el) => {
                                            focusRefs.experience.current[i] =
                                                focusRefs.experience.current[i] || {};
                                            focusRefs.experience.current[i].reccomendationLetterexp = el;
                                        }}
                                        onBlur={() => validateExperienceLetter(i)}
                                        // onChange={handleFileChange}
                                        onChange={(e) => handleFileChange(e, i)}
                                        placeholder="Nessun file selezionato"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {isFileInputVisible ? (
                        <div>
                            {fileName && (
                                <div className="file-name-display" title={fileName}>
                                    {fileName === "null" ? "" : truncateFileName(fileName, 20)}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {fileName ? (
                                <div className="file-name-display" title={fileName}>
                                    {fileName === "null" ? "Nome file" : truncateFileName(fileName, 20)}
                                </div>
                            ) : (
                                <div className="file-name-display">Nome file</div>
                            )}
                        </div>
                    )}


                    <div className="download_option file_download">
                        <div
                            onClick={!isDownloadDisabled ? handleDownload : undefined}
                            style={{
                                cursor: isDownloadDisabled ? "not-allowed" : "pointer",
                                opacity: isDownloadDisabled ? 0.5 : 1,
                            }}
                            title={
                                isDownloadDisabled
                                    ? "Please upload the file to the server first"
                                    : "Download Resume"
                            }>
                            <img src={DownloadIcon} alt="Download Resume" />
                        </div>
                    </div>

                    <div className="download_option file_delete">
                        <div
                            onClick={!isDeleteDisabled ? handleDeleteLetter : undefined}
                            style={{
                                cursor: isDeleteDisabled ? "not-allowed" : "pointer",
                                opacity: isDeleteDisabled ? 0.5 : 1,
                            }}
                            title={
                                isDeleteDisabled
                                    ? "No file available to delete. Please upload a file first."
                                    : "Click to delete the resume"
                            }>
                            <img src={TrashIcon} alt="Delete Resume" />
                        </div>
                    </div>
                </div>

                <div>
                    {errorexperience[i]?.reccomendationLetter && (
                        <div style={mystyle_resume_file}>
                            {errorexperience[i]?.reccomendationLetter}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FileInputComponent;
