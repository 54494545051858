import React from 'react';
import Select, { createFilter } from "react-select";

function AdditionalWages(props) {
    const {
        additionalWagesData,
        validateAdditionalWages,
        handleCreateFiveAdditionalWages,
        isClearable,
        isSearchable,
        selectedOptionsAdditionalWages,
        additionalWagesRef,
        errorCreatAd_Five,
        filterConfig,
        mystyle,
    } = props;
    return (
        <>
            <div className="seeking_form_left mt-lg-4">
                <div className="row g-3">
                    <div className="col-12">
                        <label className="form-label">
                            Retribuzioni aggiuntive
                        </label>
                        <Select
                            className={`form-control select_dropdown  ${errorCreatAd_Five?.additional_wages ? "error" : ""
                                }`}
                            options={additionalWagesData}
                            onBlur={validateAdditionalWages}
                            value={selectedOptionsAdditionalWages}
                            placeholder="Seleziona le retribuzioni"
                            ref={additionalWagesRef}
                            onChange={handleCreateFiveAdditionalWages}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            isMulti
                            filterOption={createFilter(filterConfig)}
                        />
                        {errorCreatAd_Five?.additional_wages && (
                            <div style={mystyle}>
                                {errorCreatAd_Five.additional_wages}
                            </div>
                        )}

                    </div>
                </div>
            </div>
            <div className="seeking_form_right mt-lg-4">
                <div className="row g-3">
                    <div className="col-12">
                        <label className="d-none d-lg-block">&nbsp;</label>
                        <div className="selected-tag d-flex flex-wrap gap-3">
                            {selectedOptionsAdditionalWages?.length > 0 &&
                                selectedOptionsAdditionalWages?.map((item, index) => (
                                    <span key={index}>
                                        {item.label}
                                    </span>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdditionalWages