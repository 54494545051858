import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    requestCompanyGetCrediteToken,
    requestCompanyCorrespondingToken,
    userLogout,
} from "../../../Redux/actions";
import Select, { createFilter } from "react-select";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

function ChildCreaAnnuncio7Bis(props) {
    const {
        //==================spent=========================

        CreatAd_Seven,
        handleOnCreatAd_Seven,
        validateforSpent,
        errorCreatAd_Seven,
        spentRef,

        //==================sponsor_recurrency=========================
        handleOnCreatSponsorType,
        validateForSponsorRecurrency,
        sponsorRecurrencyRef,

        isClearable,
        isSearchable,
        filterConfig,

        //==================sponsor_due_date=========================
        validateAdSponsorDate,
        sponsorDueDateRef,
        mystyle,
        t

    } = props;

    const navigate = useNavigate();

    const [availableToken, setAvailableToken] = useState("")
    const [error, setError] = useState(false);

    const DayList = [
        { id: 1, label: "giorno", value: "giorno" },
        { id: 2, label: "settimana", value: "settimana" },
        { id: 3, label: "mese", value: "mese" },
    ];

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            props.requestCompanyGetCrediteToken({ data: { token } });
        }
    }, []);

    useEffect(() => {
        const getTokensData = props.company.getCrediteToken;
        if (getTokensData !== undefined) {
            if (getTokensData.data.Status === "Success") {
                const TotleToken = getTokensData?.data?.Message?.tokens
                setAvailableToken(TotleToken)
            } else {
                setAvailableToken([])
                console.log("token is not available");
            }
        }

    }, [props.company.getCrediteToken])

    // useEffect(() => {
    //     const allInfoCredit = props.company.crediteData
    //     if (allInfoCredit !== undefined) {
    //         if (allInfoCredit.data.Status === "Success") {
    //             console.log("allInfoCredit", allInfoCredit.data.Message);
    //             const TotleToken = allInfoCredit?.data?.Message?.tokens
    //             setAvailableToken(TotleToken)
    //         } else {
    //             console.log("token is not available");
    //         }
    //     }

    // }, [props.company.crediteData])

    //=====================================STEP 7 Validateion  ==========================================

    // const validateAdSponsorDate = () => {
    //     const { sponsor_due_date } = CreatAd_Seven;
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);

    //     if (!sponsor_due_date) {
    //         setErrorCreatAd_Seven((prevState) => ({
    //             ...prevState,
    //             sponsor_due_date: "Inserisci una data di scadenza valida.",
    //         }));
    //         return false;
    //     } else if (new Date(sponsor_due_date).getTime() >= today.getTime()) {
    //         setErrorCreatAd_Seven((prevState) => ({
    //             ...prevState,
    //             sponsor_due_date: "Inserisci una data di scadenza valida.",
    //         }));

    //         return false;
    //     } else {
    //         setErrorCreatAd_Seven((prevState) => ({
    //             ...prevState,
    //             sponsor_due_date: "",
    //         }));
    //         return true;
    //     }
    // };

    // const validateforSpent = () => {
    //     const { spent } = CreatAd_Seven;
    //     if (!spent) {
    //         setErrorCreatAd_Seven((prevState) => ({
    //             ...prevState,
    //             spent: "Inserisci il tuo spent",
    //         }));
    //         return false;
    //     } else {
    //         setErrorCreatAd_Seven((prevState) => ({
    //             ...prevState,
    //             spent: "",
    //         }));
    //         return true;
    //     }
    // }

    // const validateForSponsorRecurrency = () => {
    //     const { sponsor_recurrency } = CreatAd_Seven;
    //     if (!sponsor_recurrency) {
    //         setErrorCreatAd_Seven((prevState) => ({
    //             ...prevState,
    //             sponsor_recurrency: "Inserisci il tuo sponsor recurrency",
    //         }));
    //         return false;
    //     } else {
    //         setErrorCreatAd_Seven((prevState) => ({
    //             ...prevState,
    //             sponsor_recurrency: "",
    //         }));
    //         return true;
    //     }
    // }

    //============================================= Step 7  Function================================================
    // const handleOnCreatAd_Seven = (e) => {
    //     const { name, value } = e.target;
    //     if (name === "spent" && value < 0) {
    //         return;
    //     }
    //     setCreatAd_Seven((prevData) => ({ ...prevData, [name]: value }));
    // };

    // const handleOnCreatSponsorType = (value) => {
    //     const newValue = value?.label || "";

    //     if (newValue) {
    //         setCreatAd_Seven((prevData) => ({
    //             ...prevData,
    //             sponsor_recurrency: newValue,
    //         }));
    //     } else {
    //         setCreatAd_Seven((prevData) => ({
    //             ...prevData,
    //             sponsor_recurrency: "",
    //         }));
    //     }
    // };

    function validateFormSeven() {
        let spentValid = validateforSpent();
        let sponsorRecurrencyValid = validateForSponsorRecurrency();
        let sponsorDueDateValid = validateAdSponsorDate();

        const validall =
            spentValid &&
            sponsorRecurrencyValid &&
            sponsorDueDateValid
        return validall;
    }

    const redirectToPackage = () => {
        navigate("/company/PackageToken")
    }


    const getDayDifference = (dueDate, today) => {
        const dueDateOnly = new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate());
        const todayOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const timeDiff = dueDateOnly - todayOnly;
        const dayDifference = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        return dayDifference + 1;
    };

    const getWeekDifference = (dueDate, today) => {
        const daysDifference = getDayDifference(dueDate, today);
        const weekDifference = Math.ceil(daysDifference / 7);
        return weekDifference;
    };


    const getMonthDifference = (dueDate, today) => {
        const yearDiff = dueDate.getFullYear() - today.getFullYear();
        const monthDiff = (yearDiff * 12) + (dueDate.getMonth() - today.getMonth());
        return Math.ceil(monthDiff) + 1;
    };

    const CountSpentTokensOld = () => {
        const { spent, sponsor_due_date, sponsor_recurrency } = CreatAd_Seven;
        const dueDate = new Date(sponsor_due_date);
        const today = new Date();
        const DaysRemaining = getDayDifference(dueDate, today);
        const WeeksRemaining = getWeekDifference(dueDate, today);
        const MonthsRemaining = getMonthDifference(dueDate, today);

        if (sponsor_recurrency === "giorno") {

            const spentsTokenForDay = spent * DaysRemaining / 1;
            console.log("Total Tokens (Day):", spentsTokenForDay);
            const confirmation = window.confirm(
                `You are about to spend ${spentsTokenForDay}  ${sponsor_recurrency} and day of ${DaysRemaining}`
            );
            return spentsTokenForDay;
        } else if (sponsor_recurrency === "settimana") {
            const spentsTokenForWeek = Math.ceil(spent * WeeksRemaining / 7);
            console.log("Total Tokens (Week):", spentsTokenForWeek);
            return spentsTokenForWeek;
        } else if (sponsor_recurrency === "mese") {
            console.log(MonthsRemaining, "MonthsRemaining")
            const spentsTokenForMonth = Math.ceil(spent * MonthsRemaining / 31);
            console.log("Total Tokens (Month):", spentsTokenForMonth);
            return spentsTokenForMonth;
        }
    };

    const CountSpentTokens = () => {
        const { spent, sponsor_due_date, sponsor_recurrency } = CreatAd_Seven;
        const dueDate = new Date(sponsor_due_date);
        const today = new Date();

        const DaysRemaining = getDayDifference(dueDate, today);
        const WeeksRemaining = getWeekDifference(dueDate, today);
        const MonthsRemaining = getMonthDifference(dueDate, today);

        if (sponsor_recurrency === "giorno") {
            const spentsTokenForDay = spent * DaysRemaining;
            const confirmation = window.confirm(
                `You are about to spend ${spentsTokenForDay} tokens for ${DaysRemaining} days. Do you want to proceed?`
            );
            if (confirmation) {
                console.log("Confirmed for day recurrency:", spentsTokenForDay);
                return spentsTokenForDay;
            } else {
                console.log("Cancelled.");
                return null;
            }

        } else if (sponsor_recurrency === "settimana") {
            const spentsTokenForWeek = Math.ceil(spent * WeeksRemaining);
            const confirmation = window.confirm(
                `You are about to spend ${spentsTokenForWeek} tokens for ${WeeksRemaining} weeks. Do you want to proceed?`
            );
            if (confirmation) {
                console.log("Confirmed for week recurrency:", spentsTokenForWeek);
                return spentsTokenForWeek;
            } else {
                console.log("Cancelled.");
                return null;
            }

        } else if (sponsor_recurrency === "mese") {
            const spentsTokenForMonth = Math.ceil(spent * MonthsRemaining);
            const confirmation = window.confirm(
                `You are about to spend ${spentsTokenForMonth} tokens for ${MonthsRemaining} months. Do you want to proceed?`
            );
            if (confirmation) {
                console.log("Confirmed for month recurrency:", spentsTokenForMonth);
                return spentsTokenForMonth;
            } else {
                console.log("Cancelled.");
                return null;
            }
        }
    };


    const handleOnSponsorAd = (e) => {
        props.userLogout();
        e.preventDefault();
        const token = localStorage.getItem("token");
        if (validateFormSeven()) {
            const totalSpentTokens = CountSpentTokens();
            if (totalSpentTokens) {
                const requestData = {
                    spent: totalSpentTokens || "",
                    in: "sponsor annuncio",
                };

                props.requestCompanyCorrespondingToken({
                    data: requestData,
                    token: token,
                });

                if (token) {
                    props.requestCompanyGetCrediteToken({ data: { token } });
                }
            }


            setError(false);
        } else {
            setError(true);
        }
    }

    useEffect(() => {
        const corresPondingData = props.company.corresPondingData
        if (corresPondingData !== undefined) {

            if (corresPondingData.data.Status === "Success") {
                toast.success(corresPondingData.data.Status, { theme: "colored", autoClose: 1000 });
            } else {
                toast.error(corresPondingData.data.Message, { theme: "colored", autoClose: 1000 });
                toast.error(corresPondingData.data.detail, { theme: "colored", autoClose: 1000 });
            }
            props.company.corresPondingData = undefined;
        } else {
            props.company.corresPondingData = undefined;
        }

    }, [props.company.corresPondingData])

    useEffect(() => {
        const focusField = () => {
            if (errorCreatAd_Seven.spent && spentRef?.current) {
                spentRef.current.focus();
            } else if (errorCreatAd_Seven.sponsor_recurrency && sponsorRecurrencyRef?.current) {
                sponsorRecurrencyRef.current.focus();
            } else if (errorCreatAd_Seven.sponsor_due_date && sponsorDueDateRef?.current) {
                sponsorDueDateRef.current.focus();
            }
        };

        if (error) {
            focusField();
            setError(false);
        }
    }, [
        error,
        errorCreatAd_Seven,
        spentRef,
        sponsorRecurrencyRef,
        sponsorDueDateRef
    ]);

    return (
        <>
            <div className="seekng_job_title_note">
                <div className="seekng_job_title">
                    <h2>
                        Inserisci un nuovo annuncio per la ricerca di dipendenti
                    </h2>
                    <h6>Pubblica annuncio</h6>
                </div>
            </div>
            <div className="seeking_job_inner">
                <div className="seeking_job_form">
                    <div className="seeking_form_full m-0">
                        <div className="row g-3">
                            <div className="col-12 mt-0">
                                <div className="publish_block">
                                    <div className="publish_block_inner">
                                        <div className="publish_text">
                                            <p>
                                                Pubblica il tuo annuncio sponsorizzandolo. <br />
                                                In questo modo l annuncio risulterà molto più
                                                visibile ai potenziali candidati interessati
                                            </p>
                                            <p>
                                                Ti suggeriamo un valore di sponsorizzazione che,
                                                in base alla nostre statistiche storiche può far
                                                aumentare la percentuale di candidature
                                                interessate fino ad un valore dell 84%
                                            </p>
                                        </div>

                                        <div className="row g-3">
                                            <div className="col-md-6 col-12">
                                                <label className="form-label">
                                                    Crediti da investire
                                                </label>
                                                <div className="d-flex flex-wrap flex-sm-nowrap contact-duration">
                                                    <div>
                                                        <input
                                                            className={`form-control ${CreatAd_Seven?.spent?.length === 0 && errorCreatAd_Seven.spent ? "error" : ""
                                                                }`}
                                                            type='number'
                                                            name='spent'
                                                            ref={spentRef}
                                                            value={CreatAd_Seven.spent || ""}
                                                            onChange={(e) => handleOnCreatAd_Seven(e)}
                                                            onBlur={validateforSpent}
                                                        />
                                                        {CreatAd_Seven?.spent?.length === 0 && errorCreatAd_Seven.spent && (
                                                            <div style={mystyle} className="ps-0">
                                                                {errorCreatAd_Seven.spent}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div>
                                                        <Select
                                                            className={`form-control select_dropdown  ${CreatAd_Seven?.sponsor_recurrency?.length === 0 &&
                                                                errorCreatAd_Seven.sponsor_recurrency
                                                                ? "error"
                                                                : ""
                                                                }`}
                                                            name='sponsor_recurrency'
                                                            placeholder="Seleziona"
                                                            ref={sponsorRecurrencyRef}
                                                            value={CreatAd_Seven.sponsor_recurrency ? { label: `${CreatAd_Seven.sponsor_recurrency}`, value: `${CreatAd_Seven.sponsor_recurrency}` } : ""}
                                                            onChange={handleOnCreatSponsorType}
                                                            onBlur={validateForSponsorRecurrency}
                                                            options={DayList}
                                                            filterOption={createFilter(filterConfig)}
                                                            isClearable={isClearable}
                                                            isSearchable={isSearchable}
                                                        />
                                                        {CreatAd_Seven?.sponsor_recurrency?.length === 0 &&
                                                            errorCreatAd_Seven?.sponsor_recurrency && (
                                                                <div style={mystyle} className="ps-0">
                                                                    {errorCreatAd_Seven.sponsor_recurrency}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 text-center">
                                                <label className="form-label">
                                                    Sponsorizza fino al
                                                </label>
                                                <div className="d-flex flex-wrap flex-sm-nowrap contact-duration justify-content-center">
                                                    <div>
                                                        <input
                                                            className={`form-control w-auto ${errorCreatAd_Seven.sponsor_due_date ? "error" : ""
                                                                }`}
                                                            type="date"
                                                            name="sponsor_due_date"
                                                            id="sponsor_due_date"
                                                            value={CreatAd_Seven?.sponsor_due_date || ""}
                                                            ref={sponsorDueDateRef}
                                                            onChange={(e) => handleOnCreatAd_Seven(e)}
                                                            onBlur={validateAdSponsorDate}
                                                            placeholder="Sponsorizza fino al"
                                                        />
                                                        {errorCreatAd_Seven.sponsor_due_date && (
                                                            <div style={{ ...mystyle, maxWidth: "161px" }} className="ps-0 pe-0 text-start">
                                                                {errorCreatAd_Seven.sponsor_due_date}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="seeking_form_btn">
                <p className="credits_text">
                    Crediti spesi al giorno:
                    {availableToken.length === 0 ? <span>Non hai crediti sufficienti!</span> : <span>{availableToken}</span>}
                </p>
                <div className="btn btn_default btn_green_outline" onClick={() => redirectToPackage()}>
                    acquista crediti
                </div>
                <div className="btn btn_default btn_green" onClick={handleOnSponsorAd}>
                    Sponsorizza annuncio
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        requestCompanyGetCrediteToken,
        requestCompanyCorrespondingToken,
        userLogout,
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChildCreaAnnuncio7Bis);