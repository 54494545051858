import React, { useEffect } from "react";
import CreatableSelect from 'react-select/creatable';
import languageOptions from "./LanguageOption";
import languageLevelOptions from "./LanguageLevelOption";


function LanguageForm(props) {
    const {
        languages,
        errorlanguages,
        validateLevelListening,
        validateLevelReading,
        validateLevelWritten,
        validateLevelSpoken,
        handleInputLanguage,
        handleChangeLanguage,
        handleRemoveLanguage,
        handleAddLanguages,
        // selectedOptionsLanguage,
        mystyle,
        isClearable,
        FontAwesomeIcon,
        faTrashCan,
        focusRefs,
        t
    } = props

    // useEffect(() => {
    //     if (languages.length === 0) {
    //         localStorage.removeItem('languages')
    //     }
    // },[])

    return (
        <>
            <div className="resume-inner-content">
                <div className="single-section education">
                    <>
                        {languages.map((x, i) => {
                            return (
                                <div key={i}
                                    className="row single-section-form"
                                >
                                    <div
                                        className="col-lg-12 col-md-12"
                                        style={{ padding: "5px" }}
                                    >
                                        <div className="inner-title-btn">
                                            <div className="inner-title">
                                                <h6>{t('language.title')} : {i + 1}</h6>
                                            </div>
                                            <div className="inner-btn">
                                                <button
                                                    onClick={() => handleRemoveLanguage(i)}
                                                >
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                        <div className="form-group">
                                            <label>{t('language.language')}</label>
                                            <CreatableSelect
                                                className={`form-control select_dropdown ${errorlanguages[i].language ? 'error' : ''}`}
                                                options={languageOptions}
                                                // value={selectedOptionsLanguage[i]}
                                                value={x.language && x.language ? { label: `${x.language}` } : ""}
                                                onChange={(selectedOption) => handleChangeLanguage(selectedOption, i)}
                                                ref={(el) => {
                                                    focusRefs.languages.current[i] = focusRefs.languages.current[i] || {};
                                                    focusRefs.languages.current[i].language = el;
                                                }}
                                                placeholder={t('language.language_select')}
                                                isClearable={isClearable}
                                            />
                                            {errorlanguages[i].language && (
                                                <div style={mystyle}>
                                                    {errorlanguages[i].language}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                        <div className="form-group">
                                            <label>{t('language.Listening_level')}</label>
                                            <select
                                                className={`form-control ${errorlanguages[i]?.levelListening ? 'error' : ''}`}
                                                name="levelListening"
                                                id={"levelListening" + i}
                                                value={x?.levelListening}
                                                ref={(el) => {
                                                    focusRefs.languages.current[i] = focusRefs.languages.current[i] || {};
                                                    focusRefs.languages.current[i].levelListening = el;
                                                }}
                                                onBlur={() => validateLevelListening(i)}
                                                onChange={(e) => handleInputLanguage(e, i)}
                                            >
                                                <option value="">{t('language.select')}</option>
                                                {languageLevelOptions.map(level => (
                                                    <option key={level.id} value={level.name}>{level.name}</option>
                                                ))}
                                            </select>
                                            {errorlanguages[i].levelListening && (
                                                <div style={mystyle}>
                                                    {errorlanguages[i].levelListening}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                        <div className="form-group">
                                            <label>{t('language.Reading_level')}</label>
                                            <select
                                                className={`form-control ${errorlanguages[i].levelReading ? 'error' : ''}`}
                                                name="levelReading"
                                                id={"levelReading" + i}
                                                value={x.levelReading}
                                                ref={(el) => {
                                                    focusRefs.languages.current[i] = focusRefs.languages.current[i] || {};
                                                    focusRefs.languages.current[i].levelReading = el;
                                                }}
                                                onBlur={() => validateLevelReading(i)}
                                                onChange={(e) => handleInputLanguage(e, i)}
                                            >
                                                <option value="">{t('language.select')}</option>
                                                {languageLevelOptions.map(level => (
                                                    <option key={level.id} value={level.name}>{level.name}</option>
                                                ))}
                                            </select>
                                            {errorlanguages[i].levelReading && (
                                                <div style={mystyle}>
                                                    {errorlanguages[i].levelReading}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                        <div className="form-group">
                                            <label>{t('language.Writing_level')}</label>
                                            <select
                                                className={`form-control ${errorlanguages[i].levelWritten ? 'error' : ''}`}
                                                name="levelWritten"
                                                id={"levelWritten" + i}
                                                value={x.levelWritten}
                                                ref={(el) => {
                                                    focusRefs.languages.current[i] = focusRefs.languages.current[i] || {};
                                                    focusRefs.languages.current[i].levelWritten = el;
                                                }}
                                                onBlur={() => validateLevelWritten(i)}
                                                onChange={(e) => handleInputLanguage(e, i)}
                                            >
                                                <option value="">{t('language.select')}</option>
                                                {languageLevelOptions.map(level => (
                                                    <option key={level.id} value={level.name}>{level.name}</option>
                                                ))}
                                            </select>
                                            {errorlanguages[i].levelWritten && (
                                                <div style={mystyle}>
                                                    {errorlanguages[i].levelWritten}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                        <div className="form-group">
                                            <label>{t('language.Speech_level')}</label>
                                            <select
                                                className={`form-control ${errorlanguages[i].levelSpoken ? 'error' : ''}`}
                                                name="levelSpoken"
                                                id={"levelSpoken" + i}
                                                value={x.levelSpoken}
                                                ref={(el) => {
                                                    focusRefs.languages.current[i] = focusRefs.languages.current[i] || {};
                                                    focusRefs.languages.current[i].levelSpoken = el;
                                                }}
                                                onBlur={() => validateLevelSpoken(i)}
                                                onChange={(e) => handleInputLanguage(e, i)}
                                            >
                                                <option value="">{t('language.select')}</option>
                                                {languageLevelOptions.map(level => (
                                                    <option key={level.id} value={level.name}>{level.name}</option>
                                                ))}
                                            </select>
                                            {errorlanguages[i].levelSpoken && (
                                                <div style={mystyle}>
                                                    {errorlanguages[i].levelSpoken}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}</>
                    <div className="add-box-btn">
                        <button className="add-box-button" type="button"
                            onClick={handleAddLanguages}
                        >
                            {t('language.button.save_language')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
export default LanguageForm;