import React from "react";

//================ Basic Register, Candidate Register, Profile ===================//

function CountrySelect(props) {
  const {
    data,
    countryRef,
    onChangeData,
    validateCountry,
    sortedCountryData,
    errorCountry,
    mystyle,
    t,
  } = props;

  return (
    <div className="registration_col form-group col-md-6 col-12">
      <label htmlFor="country" className="label">
        {t("location.country")}
      </label>
      <select
        className={`form-control set_max_width ${errorCountry ? "error" : ""}`}
        placeholder={t("location.country")}
        id="country"
        name="country"
        defaultValue={"Italia"}
        ref={countryRef}
        value={data.country || ""}
        onChange={onChangeData}
        onBlur={validateCountry}
      >
        <option value="">Nazione</option>
        {sortedCountryData.map((option, index) => (
          <option key={index} value={option.nativeCountryName}>
            {option.nativeCountryName}
          </option>
        ))}
      </select>
      {errorCountry && <div style={mystyle}>{errorCountry}</div>}
    </div>
  );
}

export default CountrySelect;
