import React from 'react';
import Select from "react-select";

function CreatTwoDriverlicense(props) {
    const {
        driverlicenseData,
        selectedOptionsDrivelicense,
        handleCreatTwoDriver,
        validateCreateTwoDriverlicense,
        driverLicenseRef,
        errorCreatAd_Two,
        isClearable,
        mystyle,
        t,
    } = props;

    return (
        <>
            <div className="col-12">
                <label htmlFor="driver_license" className="form-label">
                    Patenti richieste
                </label>
                <Select
                    className={`form-control  select_dropdown ${errorCreatAd_Two.driverlicense ? "error" : ""
                        }`}
                    options={driverlicenseData}
                    value={selectedOptionsDrivelicense || ""}
                    onChange={handleCreatTwoDriver}
                    onBlur={validateCreateTwoDriverlicense}
                    ref={driverLicenseRef}
                    placeholder="Patenti richieste"
                    isMulti
                    isClearable={isClearable}
                />
                {errorCreatAd_Two?.driverlicense && (
                    <div style={mystyle}>
                        {errorCreatAd_Two.driverlicense}
                    </div>
                )}
            </div>
        </>
    )
}

export default CreatTwoDriverlicense