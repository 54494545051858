import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { requestGetCandidate, userLogout } from "../../Redux/actions";
import Facebook from "../../images/facebook.png";
import Google from "../../images/google.png";
import LinkedIn from "../../images/LinkedIn.png";
import { auth } from "../../firebase-config";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function SocialLogin(props) {
  const { setShowModal, handleCloseModal } = props;
  const navigate = useNavigate();

  const handleGoogleLoginOld = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);

      if (result && result.user) {
        const email = result.user.email;
        if (email) {
          try {
            // localStorage.setItem("token", result.user.accessToken);
            await props.requestGetCandidate({ data: { username: email } });
            setTimeout(() => {
              if (Object.prototype.hasOwnProperty.call(props, "candidate")) {
                if (Object.keys(props.candidate).length > 0) {
                  console.log("props", props);
                  let candidateDetails = props.candidate.GetCandidateData;

                  if (typeof candidateDetails !== "undefined") {
                    console.log("candidateDetails", candidateDetails);
                    if (candidateDetails.data.Status === "Success") {
                      const updateData = candidateDetails.data.Message[0] || {};
                      console.log("updateData", updateData);
                      if (updateData) {
                        console.log(updateData);
                        // localStorage.setItem("token", result.user.accessToken);
                        localStorage.setItem("username", btoa(email));
                        handleCloseModal();
                        toast.success("Logged in successfully", {
                          theme: "colored",
                          autoClose: 1000,
                        });
                        navigate("/", { replace: true });
                        window.location.reload();
                      } else {
                        toast.error("User details are incomplete", {
                          theme: "colored",
                          autoClose: 1000,
                        });
                      }
                    } else {
                      toast.error("User not found or invalid status", {
                        theme: "colored",
                        autoClose: 1000,
                      });
                    }
                  }
                }
              }
            }, 2000);
          } catch (err2) {
            console.log(err2);
          }
        } else {
          toast.error("Email not found in response", {
            theme: "colored",
            autoClose: 1000,
          });
        }
      } else {
        toast.error("Login response is not valid", {
          theme: "colored",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error("Failed to login with Google", {
        theme: "colored",
        autoClose: 1000,
      });
      console.error("Error during Google login:", error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);

      if (result && result.user) {
        const email = result.user.email;
        if (email) {
          try {
            await props.requestGetCandidate({ data: { username: email } });
            setTimeout(() => {
              const candidateDetails = props.candidate.GetCandidateData;
              if (candidateDetails !== undefined) {
                if (
                  candidateDetails &&
                  candidateDetails.data.Status === "Success"
                ) {
                  const updateData = candidateDetails.data.Message[0] || {};

                  if (updateData) {
                    console.log(updateData, "updateData");
                    handleCloseModal();
                    toast.success("Logged in successfully", {
                      theme: "colored",
                      autoClose: 1000,
                    });
                    localStorage.setItem("username", btoa(email));
                    localStorage.setItem(
                      "token",
                      JSON.stringify(result?.user?.accessToken)
                    );
                    navigate("/", { replace: true });
                    window.location.reload();
                  } else {
                    toast.error("User details are incomplete", {
                      theme: "colored",
                      autoClose: 1000,
                    });
                  }
                } else {
                  toast.error("User not found or invalid status", {
                    theme: "colored",
                    autoClose: 1000,
                  });
                }
              }
            }, 1000);
          } catch (err2) {
            console.error("Error during candidate details fetch:", err2);
            toast.error("Failed to get candidate details", {
              theme: "colored",
              autoClose: 1000,
            });
          }
        } else {
          toast.error("Email not found in response", {
            theme: "colored",
            autoClose: 1000,
          });
        }
      } else {
        toast.error("Login response is not valid", {
          theme: "colored",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error("Failed to login with Google", {
        theme: "colored",
        autoClose: 1000,
      });
      console.error("Error during Google login:", error);
    }
  };

  const handleFaceBookLogin = async () => {
    try {
      const facebookProvider = new FacebookAuthProvider();
      const res = await signInWithPopup(auth, facebookProvider);
      if (res) {
        // const email = res?.user?.email;
        toast.success("Logged in successfully", {
          theme: "colored",
          autoClose: 1000,
        });
        // navigate("/register", { state: { email } });
        // navigate("/register");
      } else {
        navigate("/");
      }
    } catch (error) {
      toast.error("Failed to login with Facebook", {
        theme: "colored",
        autoClose: 1000,
      });
      console.log("Error during Facebook login:", error);
      navigate("/");
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "77pfd64ztjectx",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      console.log("LinkedIn login success code:", code);
      navigate("/register");
    },
    onError: (error) => {
      console.log("LinkedIn login error:", error);
      navigate("/");
    },
  });

  const redirectToRegister = () => {
    setShowModal("register");
  };

  return (
    <div className="login_social">
      <div className="or_line">
        <p>OPPURE</p>
      </div>
      <div className="login_social_btn">
        <button
          type="submit"
          className="btn social_login_btn"
          onClick={handleFaceBookLogin}
        >
          <img src={Facebook} alt="Main Logo" />
          Continua con Facebook
        </button>
        <button
          type="submit"
          className="btn social_login_btn"
          onClick={handleGoogleLogin}
        >
          <img src={Google} alt="Main Logo" />
          Continua con Google
        </button>
        <button
          type="submit"
          className="btn social_login_btn"
          onClick={linkedInLogin}
        >
          <img src={LinkedIn} alt="Main Logo" />
          Continua con LinkedIn
        </button>
      </div>
      <div className="terms_privacy">
        <p>
          Continuando, accetti i
          <a
            href="https://www.iubenda.com/privacy-policy/51986058"
            target="_blank"
            rel="noopener noreferrer"
          >
            Termini d’uso
          </a>
          <br />e l’
          <a
            href="https://www.iubenda.com/privacy-policy/51986058/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Informativa Privacy
          </a>
        </p>
      </div>

      <div className="sign_in_account">
        <p>
          Non hai ancora un account? &nbsp;
          <a onClick={redirectToRegister}>Registrati</a>
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestGetCandidate,
      userLogout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
